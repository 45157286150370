import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

interface CustomTabsProps {
  data: {
    id: string;
    label: string;
    content: string;
    addToTask?: (arg: any) => void;
    markAsComplete?: (arg: any) => void;
  }[];
}

export default function CustomTabs(props: CustomTabsProps) {
  const { data } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {data.map((single: any, index: number) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onClick={() => setValue(index)}
            sx={{
              height: '110px',
              width: '200px',
              background:
                'linear-gradient(90deg, rgba(95,188,204,1) 0%, rgba(106,221,201,1) 100%)',
              margin: '0px 10px',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
              fontSize: '16px',
              fontWeight: '600',
              borderRadius: '15px',
              cursor: 'pointer',
              ':hover': { opacity: '0.8' },
            }}
          >
            <Box sx={{ textAlign: 'center' }}>{single.label}</Box>
          </Box>
        ))}
      </Tabs>

      <TabPanel value={value} index={value}>
        <Box
          sx={{
            marginTop: '50px',
            minHeight: '350px',
            backgroundColor: '#fbfbfb',
            borderRadius: '15px',
            boxShadow: '0px 0px 15px 0px grey',
            padding: '50px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Box
              sx={{
                padding: '15px 0',
                textAlign: 'center',
                fontSize: '32px',
                color: '#61b9c3',
              }}
            >
              {data[value].label}
            </Box>
            <Box>{data[value].content}</Box>
            {data[value].addToTask && (
              <Button
                variant="contained"
                onClick={() => data[value].addToTask?.(data[value].id)}
              >
                Add to Task
              </Button>
            )}
            {data[value].markAsComplete && (
              <Button
                variant="contained"
                onClick={() => data[value].markAsComplete?.(data[value].id)}
              >
                Mark as complete
              </Button>
            )}
          </Box>
        </Box>
      </TabPanel>
    </Box>
  );
}
