import { useNavigate } from 'react-router-dom';
import './NotFound.scss';

function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="not-found">
      <div className="text">
        <div className="splash-text">404</div>
        <div className="message">
          Oops! We aren't able to find the page you have requested. Let us know
          if you think this is an error, or return to the home page and try
          again.
        </div>
        <div className="button-group">
          <button
            type="button"
            className="button"
            onClick={() => {
              window.open('https://evalue8.net/support/');
            }}
          >
            Report an Issue
          </button>
          <button
            type="button"
            className="button"
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            Return Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
