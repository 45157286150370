/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card } from '@mui/material';
import axios from 'axios';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonFilled } from '../../components/Button/Button';
import FormField from '../../components/FormField/FormField';
import { getServices } from '../../config/config';
import { DataContext } from '../../context/DataContext';
import LogoColor from '../../images/Evalue8-300px.png';
import { emailIsValid } from '../../utils/utils';
import './ForgotPassword.scss';

const defaultErrors = {
  emailError: '',
  codeError: '',
  passwordError: '',
  rePasswordError: '',
};

function ForgotPassword() {
  const { setLoading, setMessagebar } = useContext(DataContext);

  const apiPrefix = getServices().serviceURL;
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState('1');
  const [forgotEmail, setForgotEmail] = useState('');
  const [code, setCode] = useState('');
  const [forgotPassword, setForgotPassword] = useState('');
  const [forgotPasswordAgain, setForgotPasswordAgain] = useState('');

  const [errors, setErrors] = useState(defaultErrors);

  const validation = () => {
    setErrors(defaultErrors);
    if (currentStep === '1' && forgotEmail.length === 0) {
      setErrors((prev) => ({
        ...prev,
        emailError: 'Email field can not be empty',
      }));
      return false;
    }
    if (currentStep === '1' && !emailIsValid(forgotEmail)) {
      setErrors((prev) => ({ ...prev, emailError: 'Invalid email format' }));
      return false;
    }
    if (currentStep === '2' && code.length === 0) {
      setErrors((prev) => ({
        ...prev,
        codeError: 'Code field can not be empty',
      }));
      return false;
    }
    if (currentStep === '2' && forgotPassword.length === 0) {
      setErrors((prev) => ({
        ...prev,
        passwordError: 'Password field can not be empty',
      }));
      return false;
    }
    if (currentStep === '2' && forgotPassword.length < 6) {
      setErrors((prev) => ({
        ...prev,
        passwordError: 'Password must be at least 6 characters long',
      }));
      return false;
    }
    if (currentStep === '2' && forgotPasswordAgain.length === 0) {
      setErrors((prev) => ({
        ...prev,
        rePasswordError: 'Confirm Password field can not be empty',
      }));
      return false;
    }
    if (currentStep === '2' && forgotPasswordAgain.length < 6) {
      setErrors((prev) => ({
        ...prev,
        rePasswordError: 'Confirm Password must be at least 6 characters long',
      }));
      return false;
    }
    if (currentStep === '2' && forgotPassword !== forgotPasswordAgain) {
      setErrors((prev) => ({
        ...prev,
        passwordError: 'Passwords do not match',
      }));
      setErrors((prev) => ({
        ...prev,
        rePasswordError: 'Passwords do not match',
      }));
      return false;
    }
    return true;
  };

  const requestVerificationCode = () => {
    const fetchData = async () => {
      if (validation()) {
        setLoading(true);
        try {
          const body = {
            email: forgotEmail,
          };
          const res = await axios.post(
            `${apiPrefix}/accounts_reset_password/reset_password_request`,
            body,
          );
          setLoading(false);
          setMessagebar((prev: any) => ({
            ...prev,
            show: true,
            text: res.data,
            severity: 'success',
          }));
          // setForgotEmail(forgotEmail)
          setCurrentStep('2');
        } catch (error: any) {
          setLoading(false);
          setMessagebar((prev: any) => ({
            ...prev,
            show: true,
            text: error.response.data,
            severity: 'warning',
          }));
        }
      }
    };
    fetchData();
  };

  const requestChangePassword = () => {
    if (validation()) {
      setLoading(true);
      if (forgotPassword !== forgotPasswordAgain) {
        setLoading(false);
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: 'Password do not match. Please try again !',
          severity: 'error',
        }));
      } else {
        const fetchData = async () => {
          try {
            const body = {
              email: forgotEmail,
              code,
              new_password: forgotPassword,
            };
            const resetPasswordResponse = await axios.post(
              `${apiPrefix}/accounts_reset_password/reset_password`,
              body,
            );
            setLoading(false);
            setMessagebar((prev: any) => ({
              ...prev,
              show: true,
              text: resetPasswordResponse.data,
              severity: 'success',
            }));
            setCurrentStep('3');
          } catch (error: any) {
            setLoading(false);
            setMessagebar((prev: any) => ({
              ...prev,
              show: true,
              text: error.response.data,
              severity: 'warning',
            }));
          }
        };
        fetchData();
      }
    }
  };

  return (
    <div className="forgot-wrapper">
      <div className="forgot-card">
        <Card
          sx={{
            width: '600px',
            padding: '50px',
            borderRadius: '10px',
            marginTop: '100px',
          }}
        >
          <div className="image">
            <Link to="/">
              <img src={LogoColor} alt="" />
            </Link>
          </div>
          <br />
          <br />
          {currentStep === '1' && (
            <>
              <FormField
                error={errors.emailError}
                label="Email"
                type="text"
                value={forgotEmail}
                handleField={(e) => setForgotEmail(e.target.value)}
              />
              <br />
              <div style={{ display: 'flex', gap: 10 }}>
                <ButtonFilled handleSubmit={() => navigate('/')}>
                  Back
                </ButtonFilled>
                <ButtonFilled handleSubmit={() => requestVerificationCode()}>
                  Request verification code
                </ButtonFilled>
              </div>
            </>
          )}

          {currentStep === '2' && (
            <>
              <FormField
                error={errors.codeError}
                label="Verification Code received via Email"
                value={code}
                type="text"
                handleField={(e) => setCode(e.target.value)}
              />
              <FormField
                error={errors.passwordError}
                label="New Password"
                type="password"
                value={forgotPassword}
                handleField={(e) => setForgotPassword(e.target.value)}
              />
              <FormField
                error={errors.rePasswordError}
                label="Re-enter New Password"
                type="password"
                value={forgotPasswordAgain}
                handleField={(e) => setForgotPasswordAgain(e.target.value)}
              />
              <br />
              <div style={{ display: 'flex', gap: 10 }}>
                <ButtonFilled handleSubmit={() => setCurrentStep('1')}>
                  Back
                </ButtonFilled>
                <ButtonFilled handleSubmit={() => requestChangePassword()}>
                  Change password
                </ButtonFilled>
              </div>
            </>
          )}

          {currentStep === '3' && (
            <>
              <div>Password has been changed successfully.</div>
              <br />
              <div style={{ display: 'flex', gap: 10 }}>
                <ButtonFilled handleSubmit={() => navigate('/')}>
                  Go to login page
                </ButtonFilled>
              </div>
            </>
          )}
        </Card>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default ForgotPassword;
