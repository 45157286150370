/* eslint-disable @typescript-eslint/no-explicit-any */
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import DoneIcon from '@mui/icons-material/Done';
import {
  Button,
  Card,
  Chip,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import {
  addDays,
  addMonths,
  addYears,
  format,
  formatISO,
  fromUnixTime,
  getUnixTime,
  isFuture,
  subDays,
} from 'date-fns';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Bar, Line } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { ButtonFilled } from '../../../components/Button/Button';
import DialogBox from '../../../components/DialogBox/DialogBox';
import FormField from '../../../components/FormField/FormField';
import HamburgerIcon from '../../../components/HamburgerIcon/HamburgerIcon';
import { DataContext } from '../../../context/DataContext';
import backgroundImage from '../../../images/FaintBackground.jpg';
import {
  DateToISO,
  deleteData,
  downloadURI,
  getAccountId,
  getAuthTokensFromBrowser,
  getCurrentOrg,
  getData,
  patchData,
  postData,
  randomRGB,
  returnBackToLogin,
  StyledTableCell,
  StyledTableRow,
} from '../../../utils/utils';
import './Dashboard.scss';
import {
  barChartOptions, firstTab, lineChartOptions, secondTab,
} from './data';
import { defaultChart, defaultOrg, defaultReport } from './defaults';
import FirstLine from './FirstLine';
import SecondLine from './SecondLine';
import { DashboardDataContext } from '../../../context/DashboardDataContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const defaultNewReportErrors = {
  nameError: '',
  descriptionError: '',
  expiryError: '',
};

function Dashboard() {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const lineChartRef = useRef<any>(null);
  const barChartRef = useRef<any>(null);

  const { setLoading, setMessagebar } = useContext(DataContext);

  const [currentOrg, setCurrentOrg] = useState(defaultOrg);
  const [chartData, setChartData] = useState(defaultChart);
  const [totalEmissionActivityChart, setTotalEmissionActivityChart] = useState('');
  const [totalEmissionTimeSeries, setTotalEmissionTimeSeries] = useState('');
  const [orgActivityStartDate, setOrgActivityStartDate] = useState(new Date());

  const [firstTabLinks, setFirstTabLinks] = useState(firstTab);
  const [secondTabLinks, setSecondTabLinks] = useState(secondTab);
  const [reportTypes, setReportTypes] = useState(['emissions']);
  const [chartType, setChartType] = useState('activityChart');

  const {
    calenderStartDate,
    setCalanderStartDate,
    calenderEndDate,
    setCalanderEndDate,
    calanderStartDateEditMode,
    setCalanderStartDateEditMode,
    calanderEndDateEditMode,
    setCalanderEndDateEditMode,
  } = useContext(DashboardDataContext);

  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState({ id: '' });

  const [reportEditMode, setReportEditMode] = useState(defaultReport);
  const [reportDownloadMode, setReportDownloadMode] = useState(defaultReport);

  const [firstTabLinksEditMode, setFirstTabLinksEditMode] = useState(firstTab);

  const [reportTypesEditMode, setReportTypesEditMode] = useState(['emissions']);
  const [chartTypeEditMode, setChartTypeEditMode] = useState('activityChart');

  const [selectedOrganisation, setSelectedOrganisation] = useState('');

  const [sliderValue, setSliderValue] = useState<number[]>([
    getUnixTime(orgActivityStartDate),
    getUnixTime(new Date(format(new Date(), 'yyyy-MM-dd'))),
  ]);

  const [showWelcome, setShowWelcome] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [showCreateNewReport, setShowCreateNewReport] = useState(false);
  const [showEditReport, setShowEditReport] = useState(false);
  const [showDownloadReport, setShowDownloadReport] = useState(false);
  const [showGenerateCode, setShowGenerateCode] = useState(false);
  const [showShareCode, setShowShareCode] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [newReportValues, setNewReportValues] = useState({
    name: '',
    description: '',
    expiry: new Date(),
  });
  const [shareCodeExpiry, setShareCodeExpiry] = useState(new Date());
  const [newShareCode, setNewShareCode] = useState('');
  const ifAggregate = false;

  const handleSliderLabel = (value?: any) => {
    const dateToDisplayOnSlider = format(fromUnixTime(value), 'dd/MM/yyyy');
    return `${dateToDisplayOnSlider}`;
  };

  const handleSliderChange = async (event: Event, newValue: any) => {
    setCalanderStartDate(fromUnixTime(newValue[0]));
    setCalanderEndDate(fromUnixTime(newValue[1]));
    setSliderValue(newValue);
    setSecondTabLinks(secondTab);
  };

  const handleDownloadbutton = async () => {
    setLoading(true);
    try {
      const getReport = await getData(
        `/reports/organisation/${selectedOrganisation}`,
      );
      setLoading(false);
      setReports(getReport.data);
    } catch (error: any) {
      // console.log(error.response);
    }
    setShowDownload(true);
  };

  const processDataForLineChart = (feedData: any) => {
    const limitData = (params: any, outputLength: number) => {
      const firstElement = params[0];
      let midElements = params.slice(1, -1);
      midElements = midElements.filter(
        (single: any, index: number) => index % Math.floor(params.length / outputLength) === 0,
      );
      const lastElement = params[params.length - 1];
      return [firstElement, ...midElements, lastElement];
    };

    const bottomLabels = feedData.chart_data.chart_data_time_series.labels;
    const dataToDisplayBeforeFormatting = feedData.chart_data.chart_data_time_series;
    const LIMIT_DATAPOINTS_HORIZONTALLY = 20;

    let labelToDisplayAfterFormatting = bottomLabels;
    if (bottomLabels.length > LIMIT_DATAPOINTS_HORIZONTALLY) {
      labelToDisplayAfterFormatting = limitData(
        bottomLabels,
        LIMIT_DATAPOINTS_HORIZONTALLY,
      );
    }

    const dataToDisplayAfterFormatting = dataToDisplayBeforeFormatting.datasets.map(
      (single: any, index: number) => {
        const { label, data } = single;
        let limitedDataPoints = data;

        if (bottomLabels.length > LIMIT_DATAPOINTS_HORIZONTALLY) {
          limitedDataPoints = limitData(data, LIMIT_DATAPOINTS_HORIZONTALLY);
        }

        let randomColor;
        if (index === 0) {
          // randomColor = randomRGB();
          randomColor = '97,185,195';
          return {
            label,
            data: limitedDataPoints,
            borderColor: `rgb(${randomColor})`,
            backgroundColor: `rgba(${randomColor},0.6)`,
            tension: 0,
            fill: true,
          };
        }
        // randomColor = randomRGB();
        randomColor = '200,200,200';
        return {
          label,
          data: limitedDataPoints,
          borderColor: `rgb(${randomColor})`,
          backgroundColor: `rgba(${randomColor},0.4)`,
          tension: 0,
          fill: true,
        };
      },
    );

    const data = {
      labels: labelToDisplayAfterFormatting,
      datasets: dataToDisplayAfterFormatting,
    };
    return data;
  };

  const updateReport = async () => {
    const body = {
      name: reportEditMode.name,
      description: reportEditMode.description,
      organisation: selectedOrganisation,
      report_types: reportTypesEditMode,
      expiry: reportEditMode.expiry,
      start_date: formatISO(calanderStartDateEditMode),
      end_date: formatISO(calanderEndDateEditMode),
    };
    // Trigger pull new data and setReportEditMode
    await patchData(`/reports/${reportEditMode.id}`, body);
  };

  const processDataForBarChart = (feedData: any) => {
    const { labels } = feedData.chart_data.chart_data_by_activity;
    const dataToDisplayBeforeFormatting = feedData.chart_data.chart_data_by_activity;
    // Can be an option as well
    // const colorMap: {[key: string]: string} = {
    //   'Building Rent': '130,209,218',
    //   'Professional Services': '54,190,179',
    //   Flights: '24,185,161',
    //   'Food & Catering': '26,169,162',
    //   Accomodation: '32,154,160',
    //   'ICT Costs': '34,127,155',
    //   'Ground Transport': '48,105,152',
    //   Electricity: '65,84,149',
    //   'IT Equipment': '81,59,146',
    //   'Advertising and Marketing': '84,44,108',
    //   'Printing & Stationery': '84,44,108',
    //   'Building Repairs': '190,243,243',
    //   'Postage, couriers & freight': '202,245,245',
    //   'Cleaning Services': '213,247,247',
    //   Water: '130,209,218',
    //   'Office Space Hire': '54,190,179',
    //   'Electricity - Lights power heating': '24,185,161',
    //   'Flight - travel - national': '26,169,162',
    //   'Flights Long Haul': '32,154,160',
    //   Clothing: '34,127,155',
    //   Waste: '48,105,152',
    // };
    // const colors = labels.map((label: string) => `rgba(${colorMap[label] ?? randomColor},0.9)`);

    const colorsValues = [
      '130,209,218',
      '54,190,179',
      '24,185,161',
      '26,169,162',
      '32,154,160',
      '34,127,155',
      '48,105,152',
      '65,84,149',
      '81,59,146',
      '84,44,108',
      '84,44,108',
      '190,243,243',
      '202,245,245',
      '213,247,247',
      '130,209,218',
      '54,190,179',
      '24,185,161',
      '26,169,162',
      '32,154,160',
      '34,127,155',
      '48,105,152',
    ];

    const dataToDisplayAfterFormatting = dataToDisplayBeforeFormatting.datasets.map(
      (single: any, index: number) => {
        let randomColor = randomRGB();
        if (index === 0) {
          const colors: string[] = [];
          for (let labelIndex = 0; labelIndex < labels.length; labelIndex += 1) {
            if (labelIndex < colorsValues.length) {
              colors.push(`rgba(${colorsValues[labelIndex]},0.9)`);
            } else {
              colors.push(`rgba(${randomRGB()},0.9)`);
            }
          }
          return {
            ...single,
            borderColor: `rgb(${randomColor})`,
            backgroundColor: colors,
            tension: 0.4,
            fill: true,
          };
        }
        randomColor = '200,200,200';
        return {
          ...single,
          borderColor: `rgb(${randomColor})`,
          backgroundColor: `rgba(${randomColor},0.6)`,
          tension: 0.4,
          fill: true,
        };
      },
    );
    const data = { labels, datasets: dataToDisplayAfterFormatting };
    return data;
  };

  const handleSetStartDate = (params: any) => {
    if (params < orgActivityStartDate) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: `Please choose a date later than ${orgActivityStartDate}`,
        severity: 'error',
      }));
      return;
    }
    if (params > new Date()) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Start date can not be today or in the future',
        severity: 'error',
      }));
      return;
    }
    if (params > calenderEndDate) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Start date can not be after end date',
        severity: 'error',
      }));
      return;
    }
    setSliderValue([getUnixTime(params), getUnixTime(calenderEndDate)]);
    setCalanderStartDate(params);
  };

  const handleSetStartDateEditMode = (params: any) => {
    if (params < orgActivityStartDate) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: `Please choose a date later than ${orgActivityStartDate}`,
        severity: 'error',
      }));
      return;
    }
    if (params > new Date()) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Start date can not be today or in the future',
        severity: 'error',
      }));
      return;
    }
    if (params > calanderEndDateEditMode) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Start date can not be after end date',
        severity: 'error',
      }));
      return;
    }
    setCalanderStartDateEditMode(params);

    updateReport();

    // now setReportEditMode !!
  };

  const handleSetEndDate = (params: any) => {
    if (params > new Date()) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'End Date can not be in the future',
        severity: 'error',
      }));
      return;
    }
    if (params < calenderStartDate) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'End date can not be before start date',
        severity: 'error',
      }));
      return;
    }

    setCalanderEndDate(params);
  };

  const handleSetEndDateEditMode = (params: any) => {
    if (params > new Date()) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'End Date can not be in the future',
        severity: 'error',
      }));
      return;
    }
    if (params < calanderStartDateEditMode) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'End date can not be before start date',
        severity: 'error',
      }));
      return;
    }

    setCalanderEndDateEditMode(params);
    updateReport();
    // now setReportEditMode
  };

  const handleFirstTabLinks = (params?: any) => {
    if (params === 'emissions') {
      setReportTypes(['emissions']);
    } else if (params === 'benchmarks') {
      setReportTypes((prev) => [...prev, 'benchmarks']);
    }

    const alteredData = firstTabLinks.map((single) => (single.value !== params
      ? { ...single, active: false }
      : { ...single, active: true }));
    setFirstTabLinks(alteredData);
  };

  const handleFirstTabLinksEditMode = (params?: any) => {
    if (params === 'emissions') {
      setReportTypesEditMode(['emissions']);
    } else if (params === 'benchmarks') {
      setReportTypesEditMode((prev) => [...prev, 'benchmarks']);
    }

    const alteredData = firstTabLinksEditMode.map((single) => (single.value !== params
      ? { ...single, active: false }
      : { ...single, active: true }));

    setFirstTabLinksEditMode(alteredData);
    updateReport();
    // now setReportEditMode
  };

  const handleSecondTabLinks = (params?: any) => {
    setCalanderStartDate(orgActivityStartDate);

    if (params === 'threeYears') {
      const dateAfterThreeYears = addYears(orgActivityStartDate, 3);
      if (isFuture(dateAfterThreeYears)) {
        // setMessagebar((prev: any) => ({
        //   ...prev,
        //   show: true,
        //   text: "Not enough activities for 3 years",
        //   severity: "success",
        // }));
        setSliderValue([
          getUnixTime(orgActivityStartDate),
          getUnixTime(dateAfterThreeYears),
        ]);
        setCalanderEndDate(new Date());
        const alteredData = secondTabLinks.map((single) => ({
          ...single,
          active: false,
        }));
        setSecondTabLinks(alteredData);
        return;
      }
      setSliderValue([
        getUnixTime(orgActivityStartDate),
        getUnixTime(dateAfterThreeYears),
      ]);
      setCalanderEndDate(dateAfterThreeYears);
    } else if (params === 'yearly') {
      const dateAfterOneYear = addYears(orgActivityStartDate, 1);
      if (isFuture(dateAfterOneYear)) {
        // setMessagebar((prev: any) => ({
        //   ...prev,
        //   show: true,
        //   text: "Not enough activities for 1 year",
        //   severity: "success",
        // }));
        setSliderValue([
          getUnixTime(orgActivityStartDate),
          getUnixTime(dateAfterOneYear),
        ]);
        setCalanderEndDate(new Date());
        const alteredData = secondTabLinks.map((single) => ({
          ...single,
          active: false,
        }));
        setSecondTabLinks(alteredData);
        return;
      }
      setSliderValue([
        getUnixTime(orgActivityStartDate),
        getUnixTime(dateAfterOneYear),
      ]);
      setCalanderEndDate(dateAfterOneYear);
    } else if (params === 'monthly') {
      const dateAfterOneMonth = addMonths(orgActivityStartDate, 1);
      if (isFuture(dateAfterOneMonth)) {
        // setMessagebar((prev: any) => ({
        //   ...prev,
        //   show: true,
        //   text: "Not enough activities for 1 month",
        //   severity: "success",
        // }));
        setSliderValue([
          getUnixTime(orgActivityStartDate),
          getUnixTime(dateAfterOneMonth),
        ]);
        setCalanderEndDate(new Date());
        const alteredData = secondTabLinks.map((single) => ({
          ...single,
          active: false,
        }));
        setSecondTabLinks(alteredData);
        return;
      }
      setSliderValue([
        getUnixTime(orgActivityStartDate),
        getUnixTime(dateAfterOneMonth),
      ]);
      setCalanderEndDate(dateAfterOneMonth);
    }

    const alteredData = secondTabLinks.map((single) => (single.value !== params
      ? { ...single, active: false }
      : { ...single, active: true }));
    setSecondTabLinks(alteredData);
  };

  const handleChartType = (params: string) => {
    setChartType(params);
  };

  const handleChartTypeEditMode = (params: string) => {
    setChartTypeEditMode(params);
  };

  const handleCreateNewReport = () => {
    setShowShare(false);
    setShowDownload(false);
    setShowCreateNewReport(true);
  };

  const handleSubmitCreateNewReport = async (e: any) => {
    e.preventDefault();

    if (newReportValues.name === '' || newReportValues.description === '') {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Name and/or Description can not be empty',
        severity: 'error',
      }));
      return;
    }

    setShowCreateNewReport(false);

    const body = {
      name: newReportValues.name,
      description: newReportValues.description,
      organisation: selectedOrganisation,
      report_types: reportTypes,
      expiry: '2099-12-31T00:00:00+00:00',
      start_date: formatISO(calenderStartDate),
      end_date: formatISO(calenderEndDate),
      aggregate: ifAggregate,
    };
    // console.log(body);
    setLoading(true);
    try {
      await postData('/reports', body);
      // console.log(createReport);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Report successfully created',
        severity: 'success',
      }));
      // handleShareButton();
      const getReports = await getData(
        `/reports/organisation/${selectedOrganisation}`,
      );
      // console.log(getReports.data);
      setReports(getReports.data);
      setShowDownload(true);

      setLoading(false);
      setNewReportValues({
        name: '',
        description: '',
        expiry: new Date(),
      });
    } catch (error: any) {
      setLoading(false);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Some error occurred. Please try again.',
        severity: 'error',
      }));
    }
  };

  const shareAReport = async (params: any) => {
    setReportEditMode(params);
    setShowShare(false);
    setShowGenerateCode(true);
  };

  const handleCreateShareCode = async (e: any) => {
    e.preventDefault();
    const body = {
      report_id: reportEditMode.id,
      code_expiry: shareCodeExpiry.toISOString(),
    };
    setLoading(true);
    try {
      const getShareCode = await postData('/reports/code/generate', body);
      setNewShareCode(getShareCode.data);
      setLoading(false);
      setShowGenerateCode(false);
      setShowShareCode(true);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const confirmDeleteAReport = (report: any) => {
    setSelectedReport(report);
    setShowConfirmDialog(true);
  };

  const deleteAReport = async () => {
    setLoading(true);
    try {
      const response = await deleteData(`/reports/${selectedReport.id}`);
      if (response.status === 204) {
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: 'Report successfully deleted',
          severity: 'success',
        }));

        const getReport = await getData(
          `/reports/organisation/${selectedOrganisation}`,
        );
        setReports(getReport.data);
        setShowConfirmDialog(false);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  const editAReport = (params: any) => {
    setShowShare(false);
    setShowDownload(false);
    setShowEditReport(true);
    setReportEditMode(params);
  };

  const cancelEditReport = () => {
    setShowEditReport(false);
    setShowDownload(false);
  };

  const handleSaveChanges = async () => {
    const body = {
      name: reportEditMode.name,
      description: reportEditMode.description,
      organisation: selectedOrganisation,
      report_types: reportTypesEditMode,
      expiry: reportEditMode.expiry,
      start_date: formatISO(calanderStartDateEditMode),
      end_date: formatISO(calanderEndDateEditMode),
    };
    // Trigger udpate the reportEditMode with new changes
    setLoading(true);
    await patchData(`/reports/${reportEditMode.id}`, body);
    setLoading(false);
    setShowEditReport(false);
  };

  const downloadAReport = (params: any) => {
    // console.log("downloadAReport::::", params);
    setShowShare(false);
    setShowDownload(false);
    setShowDownloadReport(true);
    setReportDownloadMode(params);
  };

  const cancelDownloadReport = () => {
    setShowDownloadReport(false);
    setShowDownload(true);
  };

  const createReportPDF = async () => {
    // console.log("createReportPDF::::");
    const currentUserResponse = await getData(
      `/accounts/${reportDownloadMode.created_by}`,
    );
    if (currentUserResponse.status !== 200) {
      return;
    }

    const currentUser = currentUserResponse.data;

    const { organisation }: { organisation: string } = currentUser;
    // const startDateISO = DateToISO(calenderStartDate);
    // const endDateISO = DateToISO(calenderEndDate);
    const startDateISO = DateToISO(reportDownloadMode.start_date);
    const endDateISO = DateToISO(reportDownloadMode.end_date);

    const payload = {
      organisation,
      aggregate: true,
      report_types: ['emissions'],
      start_date: startDateISO,
      end_date: endDateISO,
    };
    // console.log("Payload::::", payload);

    let reportDataResponse = null;
    try {
      setLoading(true);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Report is being generated. Please wait...',
        severity: 'warning',
      }));
      reportDataResponse = await postData('/reports/download-instant', payload);
      setLoading(false);
    } catch (e) {
      // console.error("Unable to retrieve report!", e);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Unable to download report',
        severity: 'error',
      }));
      setLoading(false);
      return;
    }

    if (!reportDataResponse || reportDataResponse?.status !== 200) {
      // console.error("Unable to retrieve report!", reportDataResponse);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Unable to download report',
        severity: 'error',
      }));
    }

    const { data } = reportDataResponse;
    const fileName = data.split('filename=')[1].split(';')[0];
    downloadURI(data, fileName);
  };

  const downloadNow = () => {
    // console.log("downloadNow::::");

    setShowDownloadReport(false);
    // const lineChartBase64Image = lineChartRef.current.toBase64Image();
    // const barChartBase64Image = barChartRef.current.toBase64Image();
    createReportPDF();
  };

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      const currentOrgResponse = await getCurrentOrg(navigate);
      setCurrentOrg(currentOrgResponse.data);
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      const accountId = getAccountId();
      const currentUser = await getData(`/accounts/${accountId.accountId}`);
      setSelectedOrganisation(currentUser.data.organisation ?? '');

      // Get Organisation Start date
      const getOrgActivityStartDate = await getData(
        `/reports/startdate/${currentUser.data.organisation}`,
      );
      if (getOrgActivityStartDate.status === 400) {
        setOrgActivityStartDate(new Date());
        setShowWelcome(true);
        setLoading(false);
        return;
      }
      setOrgActivityStartDate(new Date(getOrgActivityStartDate.data));
      //

      // Set Chart data
      const body = {
        organisation: currentUser.data.organisation,
        report_types: reportTypes,
        start_date: formatISO(calenderStartDate),
        end_date: formatISO(calenderEndDate),
        aggregate: ifAggregate,
      };
      const response = await postData('/reports/instant', body);
      // console.log("::::CHARTDATA::::", response.data);
      if (response.status !== 201) {
        return;
      }
      setChartData(response.data);
      //

      setSliderValue([
        getUnixTime(
          new Date(format(new Date(calenderStartDate), 'yyyy-MM-dd')),
        ),
        getUnixTime(new Date(format(new Date(), 'yyyy-MM-dd'))),
      ]);
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { organisation } = getAuthTokensFromBrowser();
      const body = {
        organisation,
        report_types: reportTypes,
        start_date: formatISO(calenderStartDate),
        end_date: formatISO(calenderEndDate),
        aggregate: ifAggregate,
      };
      const response = await postData('/reports/instant', body);

      // In case of timeout or any other error
      if (response.status !== 201) {
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: `${response.data}`,
          severity: 'error',
        }));
        return;
      }

      if (Object.keys(response.data.chart_data).length === 0) {
        // setMessagebar((prev: any) => ({
        //   ...prev,
        //   show: true,
        //   text: `This organisation has no data`,
        //   severity: "error",
        // }));
        return;
      }
      setChartData(response.data);
      const chartDataByActivity = response.data.chart_data.chart_data_by_activity;
      const valuesArrayActivityChart = chartDataByActivity.datasets[0].data;
      const valuesArrayTimeSeries = chartDataByActivity.datasets[0].data;

      // Calculate total emission
      const calcEmissionActivityChart = (
        valuesArrayActivityChart.reduce(
          (partialSum: number, a: number) => partialSum + a,
          0,
        )
      ).toFixed(2);
      const calcEmissionTimeSeries = (
        valuesArrayTimeSeries.reduce(
          (partialSum: number, a: number) => partialSum + a,
          0,
        )
      ).toFixed(2);

      setTotalEmissionActivityChart(calcEmissionActivityChart);
      setTotalEmissionTimeSeries(calcEmissionTimeSeries);
    };

    const { organisation } = getAuthTokensFromBrowser();
    if (organisation !== '') {
      fetchData();
    } else {
      returnBackToLogin(navigate);
    }
  }, [
    setMessagebar,
    selectedOrganisation,
    reportTypes,
    calenderStartDate,
    calenderEndDate,
    ifAggregate,
  ]);

  useEffect(() => {
    const updateReportSecond = async () => {
      if (reportEditMode.id !== '') {
        const body = {
          name: reportEditMode.name,
          description: reportEditMode.description,
          organisation: selectedOrganisation,
          report_types: reportTypesEditMode,
          expiry: reportEditMode.expiry,
          start_date: formatISO(calanderStartDateEditMode),
          end_date: formatISO(calanderEndDateEditMode),
        };
        // Trigger udpate the reportEditMode with new changes
        let getUpdatedReport;
        try {
          getUpdatedReport = await patchData(
            `/reports/${reportEditMode.id}`,
            body,
          );
        } catch (error: any) {
          // eslint-disable-next-line no-console
          console.error(error);
        }

        if (getUpdatedReport.status !== 404) {
          try {
            const getAllReports = await getData(
              `/reports/organisation/${selectedOrganisation}`,
            );
            setReports(getAllReports.data);
            const allReports = getAllReports.data;
            const reportEditModeSecond = getUpdatedReport.data;
            const singleReport = allReports.filter(
              (single: any) => single.id === reportEditModeSecond.id,
            );
            setReportEditMode(singleReport[0]);
          } catch (error: any) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }
      }
    };
    updateReportSecond();
  }, [calanderStartDateEditMode, calanderEndDateEditMode, reportTypesEditMode]);

  // const title = <div className="card-title">{t("dashboard.Overview")}</div>;

  return (
    <Card
      sx={{
        margin: '20px',
        borderRadius: '20px',
        padding: '40px',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        opacity: 1,
        filter: 'brightness(1.05)',
        minHeight: 'calc(100% - 50px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HamburgerIcon />
      {/* <Hidden mdUp>
        <Box sx={{ textAlign: "center" }}>{title}</Box>
      </Hidden>
      <Hidden mdDown>{title}</Hidden> */}

      {orgActivityStartDate === new Date() && <div />}
      {orgActivityStartDate !== new Date() && (
        <>
          <FirstLine
            tabValues={firstTabLinks}
            handleTab={handleFirstTabLinks}
            handleDropdown={handleChartType}
            subOrganisations={currentOrg.child_organisations}
          />
          <SecondLine
            handleDownload={handleDownloadbutton}
            orgActivityStartDate={orgActivityStartDate}
            calanderStartDate={calenderStartDate}
            handleStartDate={handleSetStartDate}
            calanderEndDate={calenderEndDate}
            handleEndDate={handleSetEndDate}
            sliderValue={sliderValue}
            handleSliderChange={handleSliderChange}
            handleSliderLabel={handleSliderLabel}
            tabValues={secondTabLinks}
            handleTab={handleSecondTabLinks}
          />
          {/* Render diagrams */}
          <div className="thirdline">
            <Paper
              sx={{
                backgroundColor: '#fff',
                borderRadius: '20px',
                minHeight: '400px',
                padding: '20px 0',
                display: chartType === 'timeSeriesChart' ? 'block' : 'none',
              }}
            >
              <Box
                sx={{ padding: '5%', borderRadius: '20px', height: '400px' }}
              >
                <Line
                  options={lineChartOptions}
                  data={processDataForLineChart(chartData)}
                />
              </Box>
              <Box sx={{ textAlign: 'center', color: 'rgb(100,100,100)' }}>
                <b>
                  Total CO
                  <sub>2</sub>
                  {' '}
                  emission:
                  {' '}
                  {totalEmissionTimeSeries}
                  {' '}
                  Tonnes
                </b>
              </Box>
            </Paper>

            <Paper
              sx={{
                backgroundColor: '#fff',
                borderRadius: '20px',
                minHeight: '400px',
                padding: '20px 0',
                display: chartType === 'activityChart' ? 'block' : 'none',
              }}
            >
              <Box
                sx={{ padding: '5%', borderRadius: '20px', height: '400px' }}
              >
                <Bar
                  options={barChartOptions}
                  data={processDataForBarChart(chartData)}
                />
              </Box>
              <Box sx={{ textAlign: 'center', color: 'rgb(100,100,100)' }}>
                <b>
                  Total CO
                  <sub>2</sub>
                  {' '}
                  emission:
                  {' '}
                  {totalEmissionActivityChart}
                  {' '}
                  Tonnes
                </b>
              </Box>
            </Paper>
          </div>
        </>
      )}

      {/* Welcome Dialog */}
      <DialogBox show={showWelcome} setShow={setShowWelcome}>
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <div className="card-title" style={{ fontSize: '30px' }}>
            Welcome to your dashboard!
          </div>
          <br />
          <p>
            This is where you view your carbon emissions, how they compare to
            industry benchmarks, and share or download reports.
          </p>
          <p>
            {' '}
            You do not have any activities set up yet. Start by creating a new
            activity set at the inventory page by clicking on 'Your Inventory'
            below!
          </p>

          <Button
            sx={{ color: 'rgb(100, 100, 100)' }}
            size="large"
            startIcon={<i className="fas fa-file-alt" />}
            onClick={() => navigate('/inventory')}
          >
            Your inventory
          </Button>
        </div>
      </DialogBox>

      {/* Share Dialog */}
      <DialogBox show={showShare} setShow={setShowShare}>
        <div style={{ padding: '50px' }}>
          <div className="card-title" style={{ fontSize: '30px' }}>
            Share a report
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button
              sx={{ color: 'rgb(100, 100, 100)' }}
              size="large"
              startIcon={<DashboardCustomizeIcon className="start-icons" />}
              onClick={() => handleCreateNewReport()}
            >
              Create new report
            </Button>
          </div>

          <TableContainer component={Paper} sx={{ margin: '20px 0' }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Report Name</StyledTableCell>
                  <StyledTableCell align="right">Expires On</StyledTableCell>
                  <StyledTableCell align="right">Created On</StyledTableCell>
                  <StyledTableCell align="right">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.length > 0
                  && reports.map((report: any) => (
                    <StyledTableRow key={report.id}>
                      <StyledTableCell
                        align="right"
                        sx={{
                          color: '#61b9c3',
                          cursor: 'pointer',
                          '&:hover': { textDecoration: 'underline' },
                        }}
                        onClick={() => shareAReport(report)}
                      >
                        <Box>{report.name}</Box>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {format(new Date(report.expiry), 'dd/MM/yyyy')}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {format(new Date(report.created_at), 'dd/MM/yyyy')}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <i
                          style={{ cursor: 'pointer', margin: '0 5px' }}
                          className="fas fa-share-alt"
                          onClick={() => shareAReport(report)}
                        />
                        <i
                          style={{ cursor: 'pointer', margin: '0 5px' }}
                          className="fas fa-trash"
                          onClick={() => confirmDeleteAReport(report)}
                        />
                        <i
                          style={{ cursor: 'pointer', margin: '0 5px' }}
                          className="fas fa-pencil-alt"
                          onClick={() => editAReport(report)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogBox>

      {/* Create new report Dialog */}
      <Dialog
        open={showCreateNewReport}
        onClose={() => setShowCreateNewReport(false)}
        PaperProps={{
          style: {
            borderRadius: '10px',
          },
        }}
      >
        <div style={{ padding: '50px' }}>
          <div
            className="card-title font-normal text-customFontColor"
            style={{ fontSize: '30px', textAlign: 'center' }}
          >
            Create a new report
          </div>
          <br />
          <form onSubmit={handleSubmitCreateNewReport}>
            <FormField
              error={defaultNewReportErrors.nameError}
              label="Report name"
              type="text"
              handleField={(e) => setNewReportValues((prevState) => ({
                ...prevState,
                name: e.target.value,
              }))}
            />
            <FormField
              error={defaultNewReportErrors.descriptionError}
              label="Report description"
              type="text"
              handleField={(e) => setNewReportValues((prevState) => ({
                ...prevState,
                description: e.target.value,
              }))}
            />
            <br />
            <ButtonFilled handleSubmit={() => handleSubmitCreateNewReport}>
              Submit
            </ButtonFilled>
            {/* <button>Submit</button> */}
          </form>
        </div>
      </Dialog>

      {/* Edit report dialog */}
      <DialogBox show={showEditReport} setShow={setShowEditReport}>
        <div style={{ padding: '50px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '50px' }}>
            <div className="card-title" style={{ fontSize: '30px' }}>
              Edit a report
            </div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input
                style={{
                  outline: 'none',
                  border: '1px solid transparent',
                  borderRight: 'none',
                  borderRadius: '5px 0 0 5px',
                  padding: '3px',
                  paddingLeft: '9px',
                  width: '200px',
                  backgroundColor: '#e3ecef',
                  fontWeight: '300',
                }}
                type="text"
                value={reportEditMode.name ? reportEditMode.name : ''}
                placeholder={reportEditMode.name}
                name="reportName"
                onChange={(e: any) => setReportEditMode((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }))}
              />
              <i
                style={{
                  border: '1px solid transparent',
                  borderLeft: 'none',
                  borderRadius: '0 5px 5px 0',
                  padding: '7px',
                  backgroundColor: '#e3ecef',
                }}
                className="fa fa-pencil"
              />
            </Box>
          </Box>

          <FirstLine
            tabValues={firstTabLinksEditMode}
            handleTab={handleFirstTabLinksEditMode}
            handleDropdown={handleChartTypeEditMode}
            reportExpiry={reportEditMode.expiry}
          />

          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                sx={{
                  color: 'rgb(100, 100, 100)',
                  cursor: 'default !important',
                }}
                size="large"
                startIcon={<CalendarTodayIcon className="start-icons" />}
              >
                Start date
              </Button>
              <span>
                <DatePicker
                  minDate={orgActivityStartDate}
                  maxDate={subDays(new Date(), 1)}
                  selected={calanderStartDateEditMode}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: any) => handleSetStartDateEditMode(date)}
                  className="w-100 outline-0 cursor-pointer"
                />
              </span>
            </Box>
            <Box sx={{ display: { xs: 'flex' }, alignItems: 'center' }}>
              <Button
                sx={{
                  color: 'rgb(100, 100, 100)',
                  cursor: 'default !important',
                }}
                size="large"
                startIcon={<CalendarTodayIcon className="start-icons" />}
              >
                End date
              </Button>
              <span>
                <DatePicker
                  minDate={addDays(calanderStartDateEditMode, 1)}
                  maxDate={new Date()}
                  todayButton="Today"
                  selected={calanderEndDateEditMode}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: any) => handleSetEndDateEditMode(date)}
                  className="w-100 outline-0 cursor-pointer"
                />
              </span>
            </Box>
          </Box>
          <br />
          {chartTypeEditMode === 'timeSeriesChart' && (
            <Paper sx={{ padding: '5%', borderRadius: '20px' }}>
              <Line
                options={lineChartOptions}
                data={processDataForLineChart(reportEditMode)}
                height={400}
              />
            </Paper>
          )}
          {chartTypeEditMode === 'activityChart' && (
            <Paper sx={{ padding: '5%', borderRadius: '20px' }}>
              <Bar
                options={barChartOptions}
                data={processDataForBarChart(reportEditMode)}
                height={400}
              />
            </Paper>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                marginTop: '20px',
                width: '450px',
                display: 'flex',
                gap: '20px',
              }}
            >
              <ButtonFilled handleSubmit={() => cancelEditReport()}>
                Cancel
              </ButtonFilled>
              <ButtonFilled handleSubmit={() => handleSaveChanges()}>
                Save Changes
              </ButtonFilled>
            </div>
          </Box>
        </div>
      </DialogBox>

      {/* Download Dialog */}
      <DialogBox show={showDownload} setShow={setShowDownload}>
        <div style={{ padding: '50px' }}>
          <div
            className="card-title font-normal text-customFontColor"
            style={{ fontSize: '30px' }}
          >
            Download a report
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button
              sx={{ color: 'rgb(100, 100, 100)' }}
              size="large"
              startIcon={<DashboardCustomizeIcon className="start-icons" />}
              onClick={() => handleCreateNewReport()}
            >
              Create new report
            </Button>
          </div>

          <TableContainer component={Paper} sx={{ margin: '20px 0' }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Report Name</StyledTableCell>
                  {/* <StyledTableCell align="right">Expires On</StyledTableCell> */}
                  <StyledTableCell align="right">Created On</StyledTableCell>
                  <StyledTableCell align="right">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.length > 0
                  && reports.map((report: any) => (
                    <StyledTableRow key={report.id}>
                      <StyledTableCell
                        align="right"
                        sx={{
                          color: '#61b9c3',
                          cursor: 'pointer',
                          '&:hover': { textDecoration: 'underline' },
                        }}
                        onClick={() => downloadAReport(report)}
                      >
                        {report.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {format(new Date(report.created_at), 'dd/MM/yyyy')}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <i
                          style={{ cursor: 'pointer', margin: '0 5px' }}
                          className="fas fa-download"
                          onClick={() => downloadAReport(report)}
                        />
                        <i
                          style={{ cursor: 'pointer', margin: '0 5px' }}
                          className="fas fa-trash"
                          onClick={() => confirmDeleteAReport(report)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogBox>

      {/* Confirm YES/NO Dialog */}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        PaperProps={{
          style: {
            borderRadius: '10px',
          },
        }}
      >
        <div style={{ padding: '50px' }}>
          <div
            className="card-title"
            style={{ fontSize: '30px', textAlign: 'center' }}
          >
            Are you sure you want to delete?
          </div>
          <br />
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Chip
              icon={<CloseIcon style={{ fill: '#fff' }} />}
              label="Cancel"
              size="small"
              sx={{ cursor: 'pointer', backgroundColor: '#f55', color: '#fff' }}
              onClick={() => setShowConfirmDialog(false)}
            />
            <Chip
              icon={<DoneIcon style={{ fill: '#fff' }} />}
              label="Confirm"
              size="small"
              sx={{
                cursor: 'pointer',
                backgroundColor: '#61b9c3',
                color: '#fff',
              }}
              onClick={() => deleteAReport()}
            />
          </Box>
        </div>
      </Dialog>

      {/* Download single Report Dialog */}
      <DialogBox show={showDownloadReport} setShow={setShowDownloadReport}>
        <div style={{ padding: '50px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              Report generation process might take around 30 seconds to
              complete.
              {' '}
              <br />
              {' '}
              <br />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  marginTop: '20px',
                  width: '450px',
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <ButtonFilled handleSubmit={() => cancelDownloadReport()}>
                  Cancel
                </ButtonFilled>
                <ButtonFilled handleSubmit={() => downloadNow()}>
                  Download
                </ButtonFilled>
              </div>
            </Box>
          </Box>
        </div>
      </DialogBox>

      {/* Generate Share code  */}
      <Dialog
        open={showGenerateCode}
        onClose={() => setShowGenerateCode(false)}
        PaperProps={{
          style: {
            borderRadius: '10px',
          },
        }}
      >
        <div style={{ padding: '50px' }}>
          <div
            className="card-title"
            style={{ fontSize: '30px', textAlign: 'center' }}
          >
            Create a share code
          </div>
          <br />
          <form onSubmit={handleCreateShareCode}>
            <div style={{ fontSize: '13px', color: '#61b9c3' }}>
              This Share code should expire on
            </div>
            <div style={{ textAlign: 'center' }}>
              <DatePicker
                inline
                selected={newReportValues.expiry}
                dateFormat="dd/MM/yyyy"
                minDate={addDays(new Date(), 1)}
                onChange={(date: any) => setShareCodeExpiry(date)}
                className="w-100 outline-0 cursor-pointer"
              />
            </div>
            <br />
            <ButtonFilled handleSubmit={() => handleCreateShareCode}>
              Submit
            </ButtonFilled>
            {/* <button>Submit</button> */}
          </form>
        </div>
      </Dialog>

      {/* Display Share code  */}
      <Dialog
        open={showShareCode}
        onClose={() => setShowShareCode(false)}
        PaperProps={{
          style: {
            borderRadius: '10px',
          },
        }}
      >
        <div style={{ padding: '50px' }}>
          <div
            className="card-title"
            style={{ fontSize: '30px', textAlign: 'center' }}
          >
            Share this link
          </div>

          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.evalue8.net/${newShareCode}`}
          >
            https://www.evalue8.net/
            {newShareCode}
          </a>
        </div>
      </Dialog>

      {/* This dialogbox is for loading the Line and Bar component for ref purpose */}
      <DialogBox show={false} setShow={setShowDownload}>
        <Line
          options={lineChartOptions}
          data={processDataForLineChart(reportDownloadMode)}
          height={400}
          ref={lineChartRef}
        />
        <Bar
          options={barChartOptions}
          data={processDataForBarChart(reportDownloadMode)}
          height={400}
          ref={barChartRef}
        />
      </DialogBox>
    </Card>
  );
}

export default Dashboard;
