import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true, // No more ripple!
      },
    },
  },
  palette: {
    primary: {
      main: '#61b9c3',
    },
  },
});

export default theme;
