import AusFlag from '../../images/flags/AustraliaFlag.jpg';

const languages = [
  {
    langShort: 'en',
    langLong: 'English',
    altText: 'Australian Flag',
    src: AusFlag,
  },
  // {
  //   langShort: "de",
  //   langLong: "German",
  //   altText: "German Flag",
  //   src: GermanyFlag,
  // },
];

export default languages;
