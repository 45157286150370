import { useState, createContext, useMemo } from 'react';

export const DataContext = createContext<any>({});

interface Props {
  children: any;
}

export function DataProvider({ children }: Props) {
  const [loading, setLoading] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [messagebar, setMessagebar] = useState({
    show: false,
    text: 'Thank you',
    severity: 'success',
  });
  const dataContextValue = useMemo(
    () => ({
      loading,
      setLoading,
      messagebar,
      setMessagebar,
      openSidebar,
      setOpenSidebar,
    }),
    [
      loading,
      setLoading,
      messagebar,
      setMessagebar,
      openSidebar,
      setOpenSidebar,
    ],
  );

  return (
    <DataContext.Provider value={dataContextValue}>
      {children}
    </DataContext.Provider>
  );
}
