import React from 'react';
import './Checkbox.scss';

interface Props {
  agree: boolean;
  link?: string;
  text: string;
  handleField: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function Checkbox({
  agree, link, text, handleField,
}: Props) {
  return (
    <label className="check-box-wrapper">
      <input type="checkbox" checked={agree} onChange={handleField} />
      {!link && <div>{text}</div>}
    </label>
  );
}

export default Checkbox;
