import React from 'react';
import './FormField.scss';

interface Props {
  error: string;
  label: string;
  type: string;
  disabled?: boolean;
  value?: string;
  handleField: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function FormField({
  error,
  label,
  type,
  disabled,
  value,
  handleField,
}: Props) {
  return (
    <div className="formField">
      <label className="formField-label">{label}</label>
      <input
        type={type}
        disabled={disabled}
        value={value}
        onChange={(e) => handleField(e)}
        autoComplete="off"
      />
      <div style={{ textAlign: 'right' }}>
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
}

export default FormField;
