/* eslint-disable no-shadow */
import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { ButtonFilled } from '../../components/Button/Button';
import FormField from '../../components/FormField/FormField';
import LogoWhite from '../../images/Logo white.png';
import LogoColor from '../../images/Evalue8-300px.png';
import './Login.scss';
import { emailIsValid, storeAuthTokensToBrowser } from '../../utils/utils';
import { DataContext } from '../../context/DataContext';
import { AuthContext, Authentication } from '../../context/AuthContext';
import { RESTMethod, useService } from '../../hooks/useService';

const defaultErrors = {
  emailError: '',
  passwordError: '',
  twoFACodeError: '',
  emailVerificationError: '',
};
interface Props {
  login: () => void;
}

function Login({ login }: Props) {
  const { setMessagebar } = useContext(DataContext);
  const { authenticationObject, setAuthenticationObject } = useContext(AuthContext);
  const [buttonToggled, setButtonToggled] = useState(false);
  const [verificationRequired, setVerificationRequired] = useState(false);
  const [sendVerificationSubmitted, setSendVerificationSubmitted] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (
      authenticationObject.access_token !== ''
      && authenticationObject.organisation !== ''
      && authenticationObject.license_types
      && authenticationObject.license_types.length > 0
    ) {
      login();
      if (searchParams.get('redirect')) {
        navigate(searchParams.get('redirect'));
      } else {
        navigate('/dashboard');
      }
    }
  }, [authenticationObject, navigate, login]);

  const [currentStep, setCurrentStep] = useState('1');
  const [userDetails, setDetails] = useState({
    email: '',
    password: '',
    twoFACode: '',
  });
  const [errors, setErrors] = useState(defaultErrors);
  const [code, setCode] = useState('');
  let loginBody: any = {
    username: userDetails.email,
    password: userDetails.password,
  };

  if (userDetails.twoFACode !== '') {
    loginBody = {
      ...loginBody,
      email_verification_code: userDetails.twoFACode,
    };
  }

  const loginResponse = useService<Authentication>({
    endpoint: '/accounts/login',
    method: RESTMethod.POST,
    skipFirstRun: true,
    dependencyArray: [buttonToggled],
    body: loginBody,
  });

  const requestVerificationEmailResponse = useService({
    endpoint: '/accounts_verify/verify_email',
    method: RESTMethod.GET,
    skipFirstRun: true,
    dependencyArray: [verificationRequired],
    urlParameters: {
      account_id:
        loginResponse?.account_id ?? authenticationObject.account_id ?? '',
    },
  });

  const confirmVerificationEmailResponse = useService({
    endpoint: '/accounts_verify/verify_email',
    method: RESTMethod.POST,
    skipFirstRun: true,
    dependencyArray: [sendVerificationSubmitted],
    urlParameters: { account_id: loginResponse?.account_id ?? '' },
    body: { code },
  });

  const fieldsValidations = () => {
    setErrors(defaultErrors);
    if (currentStep === '1' && userDetails.email.length === 0) {
      setErrors((prev) => ({
        ...prev,
        emailError: 'Email field must not be empty',
      }));
      return false;
    }
    if (currentStep === '1' && !emailIsValid(userDetails.email)) {
      setErrors((prev) => ({ ...prev, emailError: 'Invalid email format' }));
      return false;
    }
    if (currentStep === '1' && userDetails.password.length === 0) {
      setErrors((prev) => ({ ...prev, passwordError: 'Invalid password' }));
      return false;
    }
    if (currentStep === '1' && userDetails.password.length < 6) {
      setErrors((prev) => ({
        ...prev,
        passwordError: 'Password must be minimum 6 characters long',
      }));
      return false;
    }
    if (currentStep === '2' && userDetails.twoFACode.length === 0) {
      setErrors((prev) => ({ ...prev, twoFACodeError: 'Invalid code' }));
      return false;
    }
    if (currentStep === '3' && code.length === 0) {
      setErrors((prev) => ({
        ...prev,
        emailVerificationError: 'Invalid code',
      }));
      return false;
    }
    return true;
  };

  // handle login
  useEffect(() => {
    if (loginResponse) {
      const {
        license_types: licenseTypes,
        two_factor_code_sent: twoFactorCodeSent,
        error,
        organisation,
        account_id: accountId,
      } = loginResponse;

      if (twoFactorCodeSent) {
        setCurrentStep('2');
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: '2FA code has been sent.',
          severity: 'success',
        }));
        return;
      }

      if (error) {
        if (Object.keys(error).includes('type')) {
          if (error.type === 'verify_email') {
            setCurrentStep('3');
            setVerificationRequired(!verificationRequired);
            // setMessagebar((prev: any) => ({
            //   ...prev,
            //   show: true,
            //   text: `Error: ${error.message}`,
            //   severity: 'error',
            // }));
            return;
          }
          if (error.type === 'no_license') {
            const { identifiers } = loginResponse;
            if (identifiers) {
              setAuthenticationObject(identifiers);
              storeAuthTokensToBrowser(identifiers);
              // no license with organisation
              if (identifiers.organisation !== '') {
                const { is_organisation_admin: isOrganisationalAdmin } = identifiers;
                // if admin take to admin panel to buy licenses
                if (isOrganisationalAdmin) {
                  navigate('/no-license');
                  navigate(0);
                  return;
                }
                // if not admin, show error so they can contact admin

                setMessagebar((prev: any) => ({
                  ...prev,
                  show: true,
                  text: `Error: ${error.message}`,
                  severity: 'error',
                }));
                return;
              }
            }
          } else {
            setMessagebar((prev: any) => ({
              ...prev,
              show: true,
              text: `Error: ${error.message}`,
              severity: 'error',
            }));
            return;
          }
        }
      }

      try {
        // if we have already populated authobject
        if (accountId !== '') {
          setAuthenticationObject(loginResponse);
          storeAuthTokensToBrowser(loginResponse);
        }
      } catch (error: any) {
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: 'Some error occured. Please try again.',
          severity: 'error',
        }));
        return;
      }

      if (!organisation || organisation === '') {
        navigate('/register');
        navigate(0);
      } else if (!accountId) {
        setCurrentStep('2');
      } else if (licenseTypes.length === 0) {
        navigate('/no-license');
        navigate(0);
      }
    }
  }, [loginResponse]);

  // send verification code
  useEffect(() => {
    if (requestVerificationEmailResponse) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: requestVerificationEmailResponse,
        severity: 'success',
      }));
    }
  }, [requestVerificationEmailResponse]);

  // verify verification code
  useEffect(() => {
    if (confirmVerificationEmailResponse) {
      const { status, message, error } = confirmVerificationEmailResponse as any;
      if (error) {
        const { message } = error;
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: message,
          severity: 'error',
        }));
        return;
      }
      if (status === 'success') {
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: message,
          severity: 'success',
        }));
        setButtonToggled(!buttonToggled);
      }
    }
  }, [confirmVerificationEmailResponse]);

  const handleLoginButton = async (e: any) => {
    e.preventDefault();
    if (!fieldsValidations()) {
      return;
    }
    setButtonToggled(!buttonToggled);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: '1',
          background:
            'linear-gradient(360deg, #61b9c3 0%, rgba(67, 236, 208, 1) 100%)',
          minHeight: '90%',
          padding: '40px 0',
        }}
      >
        <div className="login-card">
          <div className="card-left">
            <div className="image">
              <img src={LogoColor} alt="" className="w-2/5" />
            </div>

            {currentStep === '1' && (
              <>
                <div id="title" className="text-3xl flex justify-start">
                  <span>Let's get started!</span>
                </div>
                <form onSubmit={(e) => handleLoginButton(e)} autoComplete="off">
                  <div className="email">
                    <FormField
                      error={errors.emailError}
                      data-testid="emailField"
                      label="Email"
                      type="email"
                      handleField={(e) => setDetails((prevState) => ({
                        ...prevState,
                        email: e.target.value.toLowerCase(),
                      }))}
                    />
                  </div>
                  <div className="password">
                    <FormField
                      error={errors.passwordError}
                      label="Password"
                      type="password"
                      handleField={(e) => setDetails((prevState) => ({
                        ...prevState,
                        password: e.target.value,
                      }))}
                    />
                  </div>
                  <div className="loginBtn">
                    <ButtonFilled handleSubmit={(e) => handleLoginButton(e)}>
                      Login
                    </ButtonFilled>
                  </div>
                </form>
                <div className="forgotPwd">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
              </>
            )}
            {currentStep === '2' && (
              <>
                <div className="TwoFA">
                  <FormField
                    error={errors.twoFACodeError}
                    label="Two-Factor authentication code"
                    type="text"
                    handleField={(e) => setDetails((prevState) => ({
                      ...prevState,
                      twoFACode: e.target.value,
                    }))}
                  />
                </div>
                <div className="loginBtn">
                  <div style={{ display: 'flex', gap: 10 }}>
                    <ButtonFilled handleSubmit={() => setCurrentStep('1')}>
                      Back
                    </ButtonFilled>
                    <ButtonFilled handleSubmit={(e) => handleLoginButton(e)}>
                      Submit
                    </ButtonFilled>
                  </div>
                </div>
              </>
            )}
            {currentStep === '3' && (
              <>
                <div
                  id="title"
                  className="text-2xl flex justify-start text-left"
                >
                  <span>Check your email for a verification code</span>
                </div>
                <div className="email-verification">
                  <FormField
                    error={errors.emailVerificationError}
                    label="Email verification code"
                    type="text"
                    handleField={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="loginBtn">
                  <div style={{ display: 'flex', gap: 10 }}>
                    <ButtonFilled handleSubmit={() => setCurrentStep('1')}>
                      Back
                    </ButtonFilled>
                    <ButtonFilled
                      handleSubmit={() => setVerificationRequired(!verificationRequired)}
                    >
                      Resend
                    </ButtonFilled>
                    <ButtonFilled
                      handleSubmit={() => setSendVerificationSubmitted(!sendVerificationSubmitted)}
                    >
                      Submit
                    </ButtonFilled>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            id="card-right"
            className="grid grid-cols-1 grid-rows-[200px_1fr_200px] gap-0"
          >
            <div className="flex justify-center items-center">
              <img src={LogoWhite} alt="" className="w-65 h-50" />
            </div>

            <div id="texts" className="flex flex-col justify-between text-xl">
              <div id="title" className="text-lg">
                Save money and improve efficiency
              </div>
              <div className="text-base" id="description">
                Reducing energy use and carbon pollution not only has
                environmental benefits, but can also benefit the business bottom
                line.
              </div>
            </div>
            <div
              id="buttons"
              className="flex justify-center items-center flex-col text-slate-900"
            >
              <Link to="/register">
                <button
                  type="button"
                  className="call_btn"
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  Sign Up
                </button>
              </Link>

              <a
                href={`https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_CLIENT_ID}&redirect_uri=${
                  // eslint-disable-next-line no-restricted-globals
                  location.href.split('/register')[0]
                }register&scope=openid%20profile%20email`}
              >
                <button className="call_btn" type="button">
                  <img src="xero_logo.png" alt="" width="25px" />
                  Sign up with Xero
                </button>
              </a>
            </div>
          </div>
        </div>
      </Box>
      <Box>{/* <Footer /> */}</Box>
    </Box>
  );
}

export default Login;
