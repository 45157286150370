import Cookies from 'js-cookie';
import useAxios from '../../../hooks/useAxios';
import { CustomTable } from '../../../components/CustomTable/CustomTable';

function Orders() {
  const organisationId = Cookies.get('orgId');
  const { data } = useAxios(
    `/products/getPaymentsForOrganisation?organisation_id=${organisationId}`,
  );
  return (
    <div className="p-8">
      <h2 className="text-[#61b9c3]">Orders</h2>
      <div className="mt-3">
        <CustomTable data={data} />
      </div>
    </div>
  );
}

export default Orders;
