import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useMemo, useState } from 'react';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import BugPanel from './components/BugPanel/BugPanel';
import Footer from './components/Footer/Footer';
import theme from './config/theme';
import NotFound from './pages/General/NotFound';
import { DataProvider } from './context/DataContext';
import { DashboardDataProvider } from './context/DashboardDataContext';
import Loading from './components/Loading/Loading';
import Messagebar from './components/Messagebar/Messagebar';
import Inventory from './pages/Dashboard/Inventory/Inventory';
import Home from './pages/Dashboard/Home/Home';
import Dashboard from './pages/Dashboard/Dashboard/Dashboard';
import Integrations from './pages/Dashboard/Integrations/Integrations';
import SmartMetering from './pages/Dashboard/SmartMetering/SmartMetering';
import Team from './pages/Dashboard/Team/Team';

import Administration from './pages/Dashboard/Administration/Administration';
import Recommendation from './pages/Dashboard/Recommendation/Recommendation';
import Guide from './pages/Dashboard/Guide';
import { AuthContext } from './context/AuthContext';
import { getAuthTokensFromBrowser } from './utils/utils';
import Sidebar from './components/Sidebar/Sidebar';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import NoLicense from './pages/NoLicense/NoLicense';

function App() {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const [authenticationObject, setAuthenticationObject] = useState(
    getAuthTokensFromBrowser(),
  );
  const shouldShowSidebar = authenticationObject.access_token !== ''
    && authenticationObject.organisation !== ''
    && authenticationObject.license_types.length !== 0;
  const [showSidebar, setShowSidebar] = useState(false);
  const authContextProviderValue = useMemo(
    () => ({
      authenticationObject,
      setAuthenticationObject,
    }),
    [authenticationObject, setAuthenticationObject],
  );
  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={authContextProviderValue}>
        <DataProvider>
          <DashboardDataProvider>
            <div className="App">
              <BrowserRouter>
                <Box sx={{ height: 'calc(100vh - 40px)', display: 'flex' }}>
                  <Box sx={{ height: 'calc(100vh - 50px)' }}>
                    {(showSidebar || shouldShowSidebar) && (
                      <Sidebar logout={() => setShowSidebar(false)} />
                    )}
                  </Box>
                  <Box
                    sx={{
                      height: 'calc(100vh - 40px)',
                      width: '100%',
                      backgroundColor: 'rgb(230,230,230)',
                      overflow: 'auto',
                    }}
                  >
                    <Routes>
                      <Route
                        exact
                        path="/"
                        element={<Login login={() => setShowSidebar(true)} />}
                      />
                      <Route path="/register" element={<Register />} />
                      <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                      />
                      <Route path="/home" element={<Home />} />
                      <Route path="/inventory" element={<Inventory />} />
                      <Route exact path="/dashboard" element={<Dashboard />} />
                      <Route path="/no-license" element={<NoLicense sidebar={() => setShowSidebar(true)} />} />
                      <Route path="/integrations" element={<Integrations />} />
                      <Route path="/smart-metering" element={<SmartMetering />} />
                      <Route path="/team" element={<Team />} />
                      <Route
                        path="/administration"
                        element={<Administration />}
                      />
                      <Route
                        path="/recommendation"
                        element={<Recommendation />}
                      />
                      <Route path="/guide" element={<Guide />} />
                      <Route component={NotFound} />
                    </Routes>
                  </Box>
                </Box>

                <Footer />
              </BrowserRouter>
              {/* <div className="footer-wrapper">
              <Footer />
            </div> */}
            </div>
            {environment === 'test' && <BugPanel />}
            <Loading />
            <Messagebar />
          </DashboardDataProvider>
        </DataProvider>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
