import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

require('./index.scss');

interface MarkdownViewProps {
  data: string;
}

function MarkdownView({ data }: MarkdownViewProps) {
  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} className="rmd">
      {data}
    </ReactMarkdown>
  );
}

export default MarkdownView;
