import React from 'react';
import * as ReactDOMClient from 'react-dom/client';

import './index.scss';
import './index.css';
import App from './App';

ReactDOMClient.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
);
