/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
import { useState } from 'react';
import './SmartMetering.scss';
import {
  Box, Button, Card, Paper, Slider,
} from '@mui/material';
import {
  format, subDays, getUnixTime, fromUnixTime,
} from 'date-fns';

import DatePicker from 'react-datepicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Bar, Line } from 'react-chartjs-2';
import DialogBox from '../../../components/DialogBox/DialogBox';
import { ButtonFilled } from '../../../components/Button/Button';
import { barChartOptions, lineChartOptions } from '../Dashboard/data';
import backgroundImage from '../../../images/FaintBackground.jpg';
import chartDatas from './data';
import HamburgerIcon from '../../../components/HamburgerIcon/HamburgerIcon';

function SmartMetering() {
  // const { setOpenSidebar } = useContext(DataContext);
  const [showEditReport, setShowEditReport] = useState(false);
  const [chartData] = useState(chartDatas);
  const [chartType] = useState('timeSeriesChart');
  const [orgActivityStartDate] = useState(new Date());
  const [sliderValue] = useState<number[]>([
    getUnixTime(orgActivityStartDate),
    getUnixTime(new Date(format(new Date(), 'yyyy-MM-dd'))),
  ]);

  const handleSliderLabel = (value?: any) => {
    const dateToDisplayOnSlider = format(fromUnixTime(value), 'dd/MM/yyyy');
    return `${dateToDisplayOnSlider}`;
  };

  const handleSliderChange = (event: Event, newValue: any) => {
    console.log(`Slider change ${event}, ${newValue}`);
  };

  const handleClick = () => {
    console.log('Tile clicked');
    setShowEditReport(true);
  };

  const processDataForLineChart = (feedData: any) => {
    const limitData = (params: any, outputLength: number) => {
      const firstElement = params[0];
      let midElements = params.slice(1, -1);
      midElements = midElements.filter(
        (single: any, index: number) => index % Math.floor(params.length / outputLength) === 0,
      );
      const lastElement = params[params.length - 1];
      return [firstElement, ...midElements, lastElement];
    };

    const bottomLabels = feedData.chart_data.chart_data_time_series.labels;
    const dataToDisplayBeforeFormatting = feedData.chart_data.chart_data_time_series;
    const LIMIT_DATAPOINTS_HORIZONTALLY = 20;

    let labelToDisplayAfterFormatting = bottomLabels;
    if (bottomLabels.length > LIMIT_DATAPOINTS_HORIZONTALLY) {
      labelToDisplayAfterFormatting = limitData(
        bottomLabels,
        LIMIT_DATAPOINTS_HORIZONTALLY,
      );
    }

    const dataToDisplayAfterFormatting = dataToDisplayBeforeFormatting.datasets.map(
      (single: any, index: number) => {
        const { label, data } = single;
        let limitedDataPoints = data;

        if (bottomLabels.length > LIMIT_DATAPOINTS_HORIZONTALLY) {
          limitedDataPoints = limitData(data, LIMIT_DATAPOINTS_HORIZONTALLY);
        }

        let randomColor;
        if (index === 0) {
          // randomColor = randomRGB();
          randomColor = '97,185,195';
          return {
            label,
            data: limitedDataPoints,
            borderColor: `rgb(${randomColor})`,
            backgroundColor: `rgba(${randomColor},0.6)`,
            tension: 0.4,
            fill: true,
          };
        }
        // randomColor = randomRGB();
        randomColor = '200,200,200';
        return {
          label,
          data: limitedDataPoints,
          borderColor: `rgb(${randomColor})`,
          backgroundColor: `rgba(${randomColor},0.4)`,
          tension: 0,
          fill: true,
        };
      },
    );

    const data = {
      labels: labelToDisplayAfterFormatting,
      datasets: dataToDisplayAfterFormatting,
    };
    return data;
  };

  const processDataForBarChart = (feedData: any) => {
    const { labels } = feedData.chart_data.chart_data_by_activity;
    const dataToDisplayBeforeFormatting = feedData.chart_data.chart_data_by_activity;

    const dataToDisplayAfterFormatting = dataToDisplayBeforeFormatting.datasets.map(
      (single: any, index: number) => {
        let randomColor;
        if (index === 0) {
          // randomColor = randomRGB();
          randomColor = '97,185,195';
          return {
            ...single,
            borderColor: `rgb(${randomColor})`,
            backgroundColor: `rgba(${randomColor},0.6)`,
            tension: 0.4,
            fill: true,
          };
        }
        // randomColor = randomRGB();
        randomColor = '200,200,200';
        return {
          ...single,
          borderColor: `rgb(${randomColor})`,
          backgroundColor: `rgba(${randomColor},0.6)`,
          tension: 0.4,
          fill: true,
        };
      },
    );
    const data = { labels, datasets: dataToDisplayAfterFormatting };
    return data;
  };

  // const title = <div className="card-title">Smart Metering</div>;

  return (
    <Card
      sx={{
        margin: '20px',
        borderRadius: '20px',
        padding: '40px',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        opacity: 1,
        filter: 'brightness(1.05)',
        minHeight: 'calc(100% - 50px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className="smart-metering">
        <HamburgerIcon />
        {/* <Hidden mdUp>
          <Box sx={{ textAlign: "center" }}>{title}</Box>
        </Hidden>
        <Hidden mdDown>{title}</Hidden> */}

        <Box
          sx={{
            marginTop: '180px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            gap: '40px',
          }}
        >
          <SmartTiles
            name="Solar"
            icon="fa-solid fa-cloud-sun fa-2x"
            handleClick={handleClick}
          />
          <SmartTiles
            name="Electricity"
            icon="fa-solid fa-plug fa-2x"
            handleClick={handleClick}
          />
          <SmartTiles
            name="Water"
            icon="fa-solid fa-faucet fa-2x"
            handleClick={handleClick}
          />
        </Box>
      </div>

      {/* Edit report dialog */}
      <DialogBox show={showEditReport} setShow={setShowEditReport}>
        <div style={{ padding: '50px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '50px' }}>
            {/* <div className="card-title" style={{ fontSize: "30px" }}>
              Smart metering
            </div> */}
          </Box>

          {/* <FirstLine
              tabValues={firstTabLinksEditMode}
              handleTab={handleFirstTabLinksEditMode}
              handleDropdown={handleChartTypeEditMode}
              reportExpiry={reportEditMode.expiry}
            /> */}

          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                sx={{ color: 'rgb(100, 100, 100)' }}
                size="large"
                startIcon={<CalendarTodayIcon className="start-icons" />}
              >
                Start date
              </Button>
              <span>
                <DatePicker
                  minDate={subDays(new Date(), 365)}
                  maxDate={subDays(new Date(), 1)}
                  selected={new Date()}
                  dateFormat="dd/MM/yyyy"
                  onChange={() => console.log('')}
                  className="w-100 outline-0 cursor-pointer"
                />
              </span>
            </Box>
            <Box sx={{ display: { xs: 'flex' }, alignItems: 'center' }}>
              <Button
                sx={{ color: 'rgb(100, 100, 100)' }}
                size="large"
                startIcon={<CalendarTodayIcon className="start-icons" />}
              >
                End date
              </Button>
              <span>
                <DatePicker
                  minDate={subDays(new Date(), 365)}
                  maxDate={subDays(new Date(), 1)}
                  selected={new Date()}
                  dateFormat="dd/MM/yyyy"
                  onChange={() => console.log('')}
                  className="w-100 outline-0 cursor-pointer"
                />
              </span>
            </Box>

            <Box
              sx={{ display: { xs: 'none', xl: 'flex' }, alignItems: 'center' }}
            >
              <span className="year">2019</span>
              <Slider
                size="small"
                getAriaLabel={() => 'Date range'}
                value={sliderValue as number[]}
                onChange={handleSliderChange}
                valueLabelFormat={handleSliderLabel}
                valueLabelDisplay="auto"
                step={86400}
                min={getUnixTime(orgActivityStartDate)}
                max={getUnixTime(new Date(format(new Date(), 'yyyy-MM-dd')))}
                sx={{ width: '200px', margin: '0 20px' }}
              />
            </Box>
          </Box>
          <br />
          <Paper
            sx={{
              padding: '5%',
              borderRadius: '20px',
              display: chartType === 'timeSeriesChart' ? 'block' : 'none',
            }}
          >
            <Line
              options={lineChartOptions}
              data={processDataForLineChart(chartData)}
              height={400}
            />
          </Paper>
          <Paper
            sx={{
              padding: '5%',
              borderRadius: '20px',
              display: chartType === 'activityChart' ? 'block' : 'none',
            }}
          >
            <Bar
              options={barChartOptions}
              data={processDataForBarChart(chartData)}
              height={400}
            />
          </Paper>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                marginTop: '20px',
                width: '450px',
                display: 'flex',
                gap: '20px',
              }}
            >
              <ButtonFilled handleSubmit={() => setShowEditReport(false)}>
                Cancel
              </ButtonFilled>
              <ButtonFilled handleSubmit={() => setShowEditReport(false)}>
                Ok
              </ButtonFilled>
            </div>
          </Box>
        </div>
      </DialogBox>
    </Card>
  );
}

export default SmartMetering;

function SmartTiles(props: any) {
  const { name, icon, handleClick } = props;
  return (
    <Box
      sx={{
        width: '250px',
        height: '160px',
        padding: '20px 20px',
        borderRadius: '15px',
        boxShadow: '3',
        backgroundColor: '#fff',
        cursor: 'pointer',
        transition: 'transform .2s',
        '&:hover': { transform: 'scale(1.03)' },
      }}
      onClick={() => handleClick()}
    >
      <Box component="span" sx={{ color: '#666', fontSize: '20px' }}>
        <i className={icon} />
        {' '}
        {name}
      </Box>
    </Box>
  );
}
