export const defaultErrors = {
  emailError: '',
};

export const defaultUsers = {
  created_at: '',
  email: '',
  first_name: '',
  id: '',
  last_name: '',
  role: '',
  license: '',
  organisation: '',
  is_evalue8_team: false,
};

export const defaultOrg = {
  active_activity_set: '',
  activity_sets: [''],
  address: {},
  admin_members: [''],
  child_ids: [''],
  child_organisations: [{ id: '', name: '', parent_id: '' }],
  created_at: '',
  created_by: '',
  current_invite_key: '',
  floor_space: 0,
  id: '',
  identifier: '',
  identifier_type: '',
  invite_key_exp: '',
  is_deleted: false,
  member_list: [
    {
      created_at: '',
      email: '',
      first_name: '',
      id: '',
      last_name: '',
      license: '',
      role: '',
    },
  ],
  merged_from: [''],
  name: '',
  number_of_staff: 0,
  parent_id: '',
  region: '',
  turnover: 0,
  updated_at: '',
};

export const defaultSelectedUser = {
  id: '',
  name: '',
  email: '',
  organisation: '',
  license: '',
  created_at: '',
  is_evalue8_team: false,
};
