import React from 'react';

export interface Authentication {
  access_token: string;
  refresh_token: string;
  account_id: string;
  is_organisation_admin: boolean;
  license_types: string[];
  organisation: string;
  organisation_has_licenses: boolean;
  email_is_verified: boolean;
  two_factor_code_sent?: boolean;
  error?: any;
  identifiers?: Authentication;
}

interface IAuthContext {
  authenticationObject: Authentication;
  setAuthenticationObject: (authenticationObject: Authentication) => void;
}

export const AuthContext = React.createContext<IAuthContext>({
  authenticationObject: {
    access_token: '',
    refresh_token: '',
    account_id: '',
    is_organisation_admin: false,
    license_types: [],
    organisation: '',
    organisation_has_licenses: false,
    email_is_verified: false,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAuthenticationObject: () => {},
});
