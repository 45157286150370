// Sidebar items
const menuItems = [
  // {
  //   icon: "fas fa-home fa-2x",
  //   to: "/home",
  //   name: "Home",
  //   selected: false,
  // },

  {
    icon: 'fas fa-chart-bar fa-2x',
    to: '/dashboard',
    name: 'Dashboard',
    selected: false,
  },
  {
    icon: 'fas fa-file-alt fa-2x',
    to: '/inventory',
    name: 'Inventory',
    selected: false,
  },

  {
    icon: 'fas fa-link fa-2x',
    to: '/integrations',
    name: 'Integrations',
    selected: false,
  },
  // {
  //   icon: "fas fa-tachometer-alt fa-2x",
  //   to: "/smart-metering",
  //   name: "Smart Metering",
  //   selected: false,
  // },
  {
    icon: 'fas fa-users fa-2x',
    to: '/team',
    name: 'Team',
    selected: false,
  },
  {
    icon: 'fas fa-lightbulb fa-2x',
    to: '/recommendation',
    name: 'Recommendations',
    selected: false,
  },

  // {
  //   icon: "fas fa-book fa-2x",
  //   to: "/guide",
  //   name: "Guides",
  //   selected: false,
  // },
  // {
  //   icon: "fas fa-user-shield fa-2x",
  //   to: "/administration",
  //   name: "Administration",
  //   selected: false,
  // },

  // {
  //   icon: "fa-solid fa-list-check fa-2x",
  //   to: "/tasks",
  //   name: "Tasks",
  //   selected: false,
  // },
  // {
  //   icon: "fa-solid fa-question fa-2x",
  //   to: "/help",
  //   name: "Help / FAQ",
  //   selected: false,
  // },
];

export default menuItems;
