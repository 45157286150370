import { Box, Hidden } from '@mui/material';
import { useContext } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { DataContext } from '../../context/DataContext';

export default function HamburgerIcon() {
  const { setOpenSidebar } = useContext(DataContext);
  return (
    <Hidden mdUp>
      <Box
        sx={{ margin: '10px' }}
        className="show-drawer"
        onClick={() => setOpenSidebar(true)}
      >
        <MenuIcon className="show-drawer-icon" />
      </Box>
    </Hidden>
  );
}
