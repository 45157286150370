export const defaultChart = {
  organisation: '',
  report_types: [],
  start_date: '',
  end_date: '',
  chart_data: {
    chart_data_time_series: {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
        },
        {
          label: '',
          data: [],
        },
      ],
    },
    chart_data_by_activity: {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
        },
        {
          label: '',
          data: [],
        },
      ],
    },
  },
};

export const defaultOrg = {
  active_activity_set: '',
  activity_sets: [''],
  address: { description: '' },
  admin_members: [''],
  child_ids: [''],
  child_organisations: [{}],
  created_at: '',
  created_by: '',
  current_invite_key: '',
  floor_space: 0,
  id: '',
  identifier: '',
  identifier_type: '',
  invite_key_exp: '',
  is_deleted: false,
  member_list: [
    {
      created_at: '',
      email: '',
      first_name: '',
      id: '',
      last_name: '',
      license: '',
      role: '',
    },
  ],
  merged_from: [''],
  name: '',
  number_of_staff: 0,
  parent_id: '',
  region: '',
  turnover: 0,
  updated_at: '',
};

export const defaultReport = {
  chart_data: {
    chart_data_time_series: {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
        },
        {
          label: '',
          data: [],
        },
      ],
    },
    chart_data_by_activity: {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
        },
        {
          label: '',
          data: [],
        },
      ],
    },
  },
  created_at: '',
  created_by: '',
  description: '',
  end_date: '',
  expiry: new Date(),
  id: '',
  is_deleted: false,
  name: '',
  organisation: '',
  report_types: [],
  share_code: '',
  share_code_expiry: '',
  start_date: '',
};
