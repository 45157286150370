import { subMonths } from 'date-fns';
import { useState, createContext, useMemo } from 'react';

export const DashboardDataContext = createContext<any>({});

interface Props {
  children: any;
}

export function DashboardDataProvider({ children }: Props) {
  const [calenderStartDate, setCalanderStartDate] = useState(
    subMonths(new Date(), 12),
  );
  const [calenderEndDate, setCalanderEndDate] = useState(
    new Date(),
  );

  const [calanderStartDateEditMode, setCalanderStartDateEditMode] = useState(
    subMonths(new Date(), 12),
  );
  const [calanderEndDateEditMode, setCalanderEndDateEditMode] = useState(
    new Date(),
  );

  const DashboarddataContextValue = useMemo(
    () => ({
      calenderStartDate,
      setCalanderStartDate,
      calenderEndDate,
      setCalanderEndDate,
      calanderStartDateEditMode,
      setCalanderStartDateEditMode,
      calanderEndDateEditMode,
      setCalanderEndDateEditMode,
    }),
    [
      calenderStartDate,
      setCalanderStartDate,
      calenderEndDate,
      setCalanderEndDate,
      calanderStartDateEditMode,
      setCalanderStartDateEditMode,
      calanderEndDateEditMode,
      setCalanderEndDateEditMode,
    ],
  );

  return (
    <DashboardDataContext.Provider value={DashboarddataContextValue}>
      {children}
    </DashboardDataContext.Provider>
  );
}
