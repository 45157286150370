import React from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import { Slide } from '@mui/material';
import { GrClose } from 'react-icons/gr';

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) => (
    // eslint-disable-next-line react/jsx-props-no-spreading

    // eslint-disable-next-line implicit-arrow-linebreak
    <Slide direction="up" ref={ref} {...props} />
  ),
);

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  minWidth?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}

export default function DialogBox({
  children, show, setShow, minWidth = '450px',
}: Props) {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setShow(false)}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          borderRadius: '10px',
          minWidth,
        },
      }}
    >
      <div className="flex justify-end p-3 " onClick={() => setShow(false)}>
        <div className="cursor-pointer hover:scale-125">
          <GrClose />
        </div>
      </div>
      {children}
    </Dialog>
  );
}
