import { useState, useEffect, useContext } from 'react';
import './NoLicense.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { AiOutlineCheck, AiOutlineCheckCircle } from 'react-icons/ai';
import { BsCircle } from 'react-icons/bs';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import io from 'socket.io-client';
import {
  getData,
  getAuthTokensFromBrowser,
  returnBackToLogin,
  postData,
  storeAuthTokensToBrowser,
} from '../../utils/utils';
import CheckoutForm from '../../components/CheckoutForm/CheckoutForm';
import { LicenseType } from '../../interfaces';
import { emptyLicense } from '../../utils/initData';
import evalue8Logo from '../../images/Evalue8-300px.png';
import { DataContext } from '../../context/DataContext';
import { getServices } from '../../config/config';
import { AuthContext } from '../../context/AuthContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '');

interface Props {
  sidebar: () => void;
}

function NoLicense({ sidebar }: Props) {
  const { setLoading, setMessagebar } = useContext(DataContext);
  const accountId = Cookies.get('account_id');
  const orgId = Cookies.get('organisation');
  const [selectedLicense, setSelectedLicense] = useState<LicenseType>(emptyLicense);
  const [stage, setStage] = useState(1);
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([
    emptyLicense,
  ]);
  const { setAuthenticationObject } = useContext(AuthContext);
  const [clientSecret, setClientSecret] = useState('');
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const redirectStatus = searchParams.get('redirect_status');
  const navigate = useNavigate();
  const socket = io(getServices().socketURL, { transports: ['websocket'] });

  const stripeOptions = {
    clientSecret,
    appearance: {},
  };

  const handleProceedToStage = (stageNumber: number) => {
    setStage(stageNumber);
  };

  const handleProceedButton = async () => {
    handleProceedToStage(3);
    const secretRequest = await getData(
      `/products/generatePaymentIntent?organisation_id=${orgId}&product_id=${selectedLicense.id}&account_id=${accountId}`,
    );

    if (secretRequest.status !== 200) {
      return;
    }
    setClientSecret(secretRequest.data);
  };

  const handleEnterpriseContact = () => {
    window.location.replace('https://evalue8.net/contact/');
  };

  const navigateToDashboard = () => {
    sidebar();
    navigate('/dashboard');
  };

  useEffect(() => {
    const fetchData = async () => {
      const {
        organisation_has_licenses: organisationHasLicenses,
        is_organisation_admin: isAdmin,
      } = getAuthTokensFromBrowser();

      if (!isAdmin) {
        navigate('/');
        return;
      }

      if (organisationHasLicenses) {
        sidebar();
        navigate('/dashboard');
      } else {
        const getProducts = await getData(`/products?organisation_id=${orgId}`);
        if (getProducts.status !== 200) {
          return;
        }
        setLicenseTypes(getProducts.data);
      }
    };
    fetchData();
  }, [navigate]);

  useEffect(() => {
    socket.on('INVOICE_PAID_SUCCESS', (msg) => {
      console.log(`INVOICE_PAID_SUCCESS : ${JSON.stringify(msg)}`);
      setLoading(false);
      if (msg.error !== null) {
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: 'Payment Successful!',
          severity: 'success',
        }));
        postData(
          '/accounts/login',
          {},
        ).then((data) => {
          setAuthenticationObject(data.data);
          storeAuthTokensToBrowser(data.data);
        });

        navigateToDashboard();
      } else {
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: 'Payment failed, please try again',
          severity: 'error',
        }));
      }
    });

    socket.on('PAYMENT_INTENT_FAILED', (msg) => {
      console.log(`PAYMENT_INTENT_FAILED : ${JSON.stringify(msg)}`);
      setLoading(false);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Payment failed, please try again after sometime or contact admin!',
        severity: 'error',
      }));
      handleProceedToStage(1);
    });

    socket.on('INVOICE_PAID_FAIL', (msg) => {
      console.log(`INVOICE_PAID_FAIL : ${JSON.stringify(msg)}`);
      setLoading(false);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Payment failed, please try again',
        severity: 'error',
      }));
    });

    const fetchData = async () => {
      if (redirectStatus === 'succeeded') {
        handleProceedToStage(4);
      } else if (redirectStatus === 'canceled') {
        handleProceedToStage(5);
      } else if (redirectStatus !== null) {
        handleProceedToStage(6);
      }
    };
    fetchData();
  }, [redirectStatus]);

  return (
    <div className="min-h-screen bg-fractal-image bg-cover flex flex-col justify-center items-center overflow-auto">
      {/* Step1 - Select a subscription type */}
      {stage === 1 && licenseTypes.length > 1 && (
        <div className="m-5 px-10 py-4 space-y-2 bg-white rounded-3xl flex flex-col ">
          <div className="text-slate-500 text-center my-4">
            <img className="mb-3 w-48 text-center" src={evalue8Logo} alt="" />
            <div className="text-4xl font-medium">Yearly Subscription</div>
            <div className="">Pricing plans to suit your business needs</div>
          </div>

          <div className="flex flex-wrap justify-center items-center">
            {/* Except for Enterprise */}
            {licenseTypes
              .filter((type) => type.name !== 'Enterprise')
              .map((type) => (
                <div key={type.name} className="flex flex-col text-center">
                  <div
                    onClick={() => setSelectedLicense(() => ({
                      ...type,
                    }))}
                    className={`w-52 h-72 m-2 bg-fractal-image bg-cover bg-right-top rounded-xl flex justify-center items-center  ${
                      type.id === selectedLicense.id
                        ? 'border-5 border-zinc-600 '
                        : 'cursor-pointer hover:opacity-80'
                    }`}
                  >
                    <div className="w-48 h-60 flex flex-col justify-between items-center">
                      <div className="text-center text-white border-b-2 pb-3">
                        <div className=" text-4xl">{type.name}</div>
                        <div className="text-medium font-light">
                          {type.description}
                        </div>
                      </div>

                      <div className="text-white text-center">
                        <div className="text-3xl font-bold">
                          {type.currency.toUpperCase()}
                          {' '}
                          {type.price / 100}
                        </div>
                        <div className="text-sm">
                          {' '}
                          {type.name === 'Enterprise' ? '' : '+ GST/Year'}
                        </div>
                      </div>
                      {selectedLicense.id !== type.id && (
                        <BsCircle color="white" size="25px" />
                      )}
                      {selectedLicense.id === type.id && (
                        <div>
                          <AiOutlineCheckCircle color="white" size="30px" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}

            {/* Only for Enterprise */}
            {licenseTypes
              .filter((type) => type.name === 'Enterprise')
              .map((type) => (
                <div
                  key={type.name}
                  className="w-52 h-72 m-2 bg-fractal-image bg-cover bg-right-top rounded-xl flex justify-center items-center "
                >
                  <div className="w-48 h-60 flex flex-col justify-between items-center">
                    <div className="text-center text-white border-b-2 pb-3">
                      <div className=" text-4xl">{type.name}</div>
                      <div className="text-medium font-light">
                        {type.description}
                      </div>
                    </div>
                    <div className="text-white text-center">
                      <div className="text-3xl font-bold">{type.price}</div>
                    </div>
                    <div
                      className="border-1 rounded-md px-3 py-1 bg-slate-200 hover:bg-slate-100 cursor-pointer"
                      onClick={() => handleEnterpriseContact()}
                    >
                      Contact us
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {selectedLicense.id.length > 0 && (
            <div className="h-10 flex justify-between mt-5">
              <div
                onClick={() => returnBackToLogin(navigate)}
                className="bg-customPrimary rounded-md text-white text-xl py-2 px-4 text-center hover:bg-secondary-color cursor-pointer flex items-center gap-2"
              >
                <MdNavigateBefore color="white" size="20px" />
                <div>Cancel</div>
              </div>
              <div
                onClick={() => handleProceedToStage(2)}
                className="bg-customPrimary rounded-md text-white text-xl py-2 px-4 text-center hover:bg-secondary-color cursor-pointer flex items-center gap-2"
              >
                <div>Proceed</div>
                <MdNavigateNext color="white" size="20px" />
              </div>
            </div>
          )}
        </div>
      )}

      {/* Step2 - Checkout page */}
      {stage === 2 && (
        <div className="m-2 min-w-[400px] md:min-w-[768px] p-4 space-y-2 bg-white rounded-3xl flex flex-col">
          <div className="flex flex-col items-center gap-3 md:flex-row md:items-start">
            <div
              className={`w-52 h-72 m-2 bg-fractal-image bg-cover bg-right-top rounded-xl flex justify-center items-center border-5 border-zinc-600 cursor-pointer hover:opacity-80
                    }`}
            >
              <div className="w-48 h-60 flex flex-col justify-between items-center">
                <div className="text-center text-white border-b-2 pb-3">
                  <div className=" text-4xl">{selectedLicense.name}</div>
                  <div className="text-medium font-light">
                    For
                    {' '}
                    {selectedLicense.minStaff}
                    {' '}
                    to
                    {' '}
                    {selectedLicense.maxStaff}
                    {' '}
                    staff
                  </div>
                </div>

                <div className="text-white text-center">
                  <div className="text-3xl font-bold">
                    {selectedLicense.currency.toUpperCase()}
                    {' '}
                    {selectedLicense.price / 100}
                  </div>
                  <div className="text-sm"> inc. GST per year</div>
                </div>
                <div>
                  <AiOutlineCheckCircle color="white" size="30px" />
                </div>
              </div>
            </div>
            <div className="max-w-xl md:pt-3 h-72 flex flex-col justify-around">
              {selectedLicense.salesPoints.map((point) => (
                <div className="flex items-start gap-2" key={point}>
                  <div className="flex:none">
                    <AiOutlineCheck size="20px" />
                  </div>
                  <div>{point}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="h-10 flex justify-between mt-5">
            <div
              onClick={() => handleProceedToStage(1)}
              className="bg-customPrimary rounded-md text-white text-xl py-2 px-4 text-center hover:bg-secondary-color cursor-pointer flex items-center gap-2"
            >
              <MdNavigateBefore color="white" size="20px" />
              <div>Back</div>
            </div>
            <div
              onClick={() => handleProceedButton()}
              className="bg-customPrimary rounded-md text-white text-xl py-2 px-4 text-center hover:bg-secondary-color cursor-pointer flex items-center gap-2"
            >
              <div>Proceed </div>
              <MdNavigateNext color="white" size="20px" />
            </div>
          </div>
        </div>
      )}

      {/* Step3 - Enter payment details */}
      {stage === 3 && (
        <div className="m-2 min-w-[400px] md:min-w-[768px] p-4 space-y-2 bg-white rounded-3xl flex flex-col">
          <div className="min-w-96 p-7">
            {clientSecret && (
              <Elements options={stripeOptions} stripe={stripePromise}>
                <CheckoutForm
                  handleProceedToStage={handleProceedToStage}
                  price={selectedLicense.price / 100}
                  currency={selectedLicense.currency.toUpperCase()}
                />
              </Elements>
            )}
          </div>
        </div>
      )}

      {/* Step4 - Thank you */}
      {stage === 4 && (
        <div className="m-2 min-w-[400px] md:min-w-[768px] p-4 space-y-2 bg-white rounded-3xl flex flex-col">
          <div className="flex items-center justify-center">
            <div>
              <div className="flex flex-col items-center space-y-2">
                <svg
                  className="text-customPrimary w-28 h-28"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <h1 className="text-4xl font-bold">Thank You !</h1>
                <div className="my-4 flex flex-col  items-center">
                  <div>Thank you for the purchase of license.</div>
                  <div>
                    We are assigning the license shortly which might take
                    some time. Please be patient.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            Will redirect you on dashboard, in case you can't see any update please login again
          </div>
          <div className="flex justify-center">
            <div
              onClick={() => returnBackToLogin(navigate)}
              className="bg-customPrimary rounded-md text-white text-xl py-2 px-4 text-center hover:bg-secondary-color cursor-pointer flex items-center gap-2"
            >
              <div>Logout</div>
            </div>
          </div>
        </div>
      )}

      {/* Step5 - Error */}
      {stage === 5 && (
        <div className="m-2 px-10 py-4 space-y-2 bg-white rounded-3xl flex flex-col">
          <div className="min-w-96 h-48">Transaction Cancelled</div>
          <div>
            Looks like you have cancelled the transaction,
            you can reinitiate the transaction or for any query
            please connect to support@evalue8.net
          </div>
          <div className="h-10 flex justify-between mt-5">
            <div
              className="bg-customPrimary rounded-md text-white text-xl py-2 px-4 text-center hover:bg-secondary-color cursor-pointer flex items-center gap-2"
              onClick={() => returnBackToLogin(navigate)}
            >
              Back to login page
            </div>
            <div
              onClick={() => handleProceedToStage(1)}
              className="bg-customPrimary rounded-md text-white text-xl py-2 px-4 text-center hover:bg-secondary-color cursor-pointer flex items-center gap-2"
            >
              <div>Try Again</div>
              <MdNavigateNext color="white" size="20px" />
            </div>
          </div>
        </div>
      )}

      {/*
      Step6 - Org has license but this particular
       user isnt assigned one - Therefore do not let user purchase !
      */}
      {stage === 6 && (
        <div className="m-2 min-w-[400px] md:min-w-[768px] p-4 space-y-2 bg-white rounded-3xl flex flex-col">
          <div className="flex items-center justify-center">
            <div>
              <div className="flex flex-col items-center space-y-2">
                <svg
                  className="text-orange-500 w-28 h-28"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <h1 className="text-4xl font-bold">License not found</h1>
                <div className="my-4 flex flex-col  items-center">
                  <div>
                    Please contact support@evalue8.net or another organisational
                    administrator to assign a license.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div
              onClick={() => returnBackToLogin(navigate)}
              className="bg-primary-color rounded-md text-white text-xl py-2 px-4 text-center hover:bg-secondary-color cursor-pointer flex items-center gap-2"
            >
              <div>Ok</div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-blue-200">{/* <Footer /> */}</div>
    </div>
  );
}

export default NoLicense;
