/* eslint-disable implicit-arrow-linebreak */
import {
  useState, useRef, useEffect, useContext,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as ReactDOMClient from 'react-dom/client';

import './Sidebar.scss';
import { Box } from '@mui/system';
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Drawer,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AxiosResponse } from 'axios';
import {
  dateConverter,
  getAccountId,
  getData,
  patchData,
  postData,
  returnBackToLogin,
  StyledTableCell,
  StyledTableRow,
} from '../../utils/utils';
import { defaultOrg, defaultUsers } from './defaults';
import DialogBox from '../DialogBox/DialogBox';
import menuItems from './data';
import Logo from '../Logo/Logo';
import { DataContext } from '../../context/DataContext';
import { ButtonFilled } from '../Button/Button';
import { AppNotification } from '../../interfaces';

interface MenuItems {
  icon: string;
  to: string;
  name: string;
  selected: boolean;
}

interface SidebarProps {
  logout: () => void;
}

function Sidebar({ logout }: SidebarProps) {
  const { setMessagebar } = useContext(DataContext);
  const { openSidebar, setOpenSidebar } = useContext(DataContext);
  const navigate = useNavigate();
  const [menu, setMenu] = useState<MenuItems[]>(menuItems);
  const [currentUser, setCurrentUser] = useState(defaultUsers);
  const [currentOrg, setCurrentOrg] = useState(defaultOrg);
  const [showPopup, setShowPopup] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showEditName, setShowEditName] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [oldNewPasswords, setOldNewPasswords] = useState({
    old_password: '',
    new_password: '',
    re_new_password: '',
  });
  const [click] = useState(false);
  const drawerWidth = 300;

  const notificationsWrapperRef = useRef(null);

  // Returns a string describing time elapsed from given time to now
  const timeElapsed = (time: string) => {
    const startTime = new Date(time).getTime();
    const endTime = new Date().getTime();
    let timeDiff = endTime - startTime;
    timeDiff /= 1000;
    const seconds = Math.round(timeDiff);
    if (seconds < 60) {
      return `${seconds} seconds`;
    }
    if (seconds < 3600) {
      return `${Math.floor(seconds / 60)} minute(s)`;
    }
    if (seconds < 86400) {
      return `${Math.floor(seconds / 3600)} hour(s)`;
    }
    return `${Math.floor(seconds / 86400)} day(s)`;
  };

  // Run this when user clicks outside of notifications box
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowPopup(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const updateNotificationCount = async () => {
    const accountId = getAccountId();
    const notificationsResponse = await getData(`/notifications/${accountId.accountId}`);
    if (notificationsResponse.status === 404) {
      setNotificationCount(0);
    } else {
      const unreadNotifications = notificationsResponse.data.filter(
        (entry: { [key: string]: any }) => entry.severity !== 'info' && !entry.is_seen,
      );
      setNotificationCount(unreadNotifications.length);
    }
  };

  const handleSelectNotification = (notificationObject: { [key: string]: any }) => {
    // Mark notification as seen
    postData(`/notifications/${notificationObject.id}`, {});

    // Redirect to relevant page
    if ('path' in notificationObject.payload) {
      // Internal path
      navigate(notificationObject.payload.path);
    } else if ('href' in notificationObject.payload) {
      // External link
      window.open(notificationObject.payload.href);
    }

    setShowPopup(false);
    // Don't know why but have to run it twice to update in dom
    updateNotificationCount();
    updateNotificationCount();
  };

  useOutsideAlerter(notificationsWrapperRef);

  const handleSelectMenu = (item: any) => {
    navigate(item.to);
    const tempMenu = menu.map((menuItem) => {
      if (navigate.location) {
        if (navigate.location.pathname === menuItem.to) {
          return { ...menuItem, selected: true };
        }
      }
      return menuItem;
    });
    setMenu(tempMenu);
  };

  const onLogOut = () => {
    logout();
    returnBackToLogin(navigate);
  };

  // Called when notifications is opened
  const renderNotifications = async () => {
    const accountId = getAccountId();
    const notificationsResponse: AxiosResponse<AppNotification[]> = await getData(
      `/notifications/${accountId.accountId}`,
    );
    const renderOutput: any[] = [];
    if (notificationsResponse.status === 404) {
      renderOutput.push(
        <li style={{ padding: '10px 10px', color: 'darkgray' }}>No notifications!</li>,
      );
    } else {
      // Notifications found
      let unreadNotifications = notificationsResponse.data.filter(
        (entry: { [key: string]: any }) =>
          // return !entry.is_seen;
          entry.severity !== 'info', // Show all notifications - even seen onces
      );

      // Get first N numbers of notifications
      const maxNumberOfNotificationsToDisplay = 25;
      unreadNotifications = unreadNotifications.slice(0, maxNumberOfNotificationsToDisplay);

      // No unread notifications found
      if (unreadNotifications.length === 0) {
        renderOutput.push(
          <li style={{ padding: '10px 10px', color: 'darkgray' }}>No notifications!</li>,
        );
      }

      // Unread notifications found
      unreadNotifications.sort((b: { [key: string]: any }, a: { [key: string]: any }) =>
        // eslint-disable-next-line no-nested-ternary
        (a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0)); // sort by date
      for (let i = 0; i < unreadNotifications.length; i += 1) {
        const currentItem = unreadNotifications[i];
        if (currentItem.severity === 'error') {
          // High severity notifications
          renderOutput.push(
            <li
              key={i + 1}
              style={{ margin: '0px 10px', cursor: 'pointer' }}
              onClick={() => handleSelectNotification(currentItem)}
            >
              <div
                className="flex justify-between"
                style={{ fontSize: '13px', fontWeight: 'bold', color: 'red' }}
              >
                <div className="flex items-center gap-2">
                  {currentItem.is_seen && <div className="text-sm">{currentItem.title}</div>}
                  {!currentItem.is_seen && <div className="text-base">{currentItem.title}</div>}
                  {!currentItem.is_seen && <div className="w-3 h-3 bg-red-400 rounded-full" />}
                </div>
                <div className="text-xs text-slate-400">
                  {timeElapsed(currentItem.created_at)}
                  {' '}
                  ago
                </div>
              </div>
              <div style={{ fontSize: '13px', color: 'maroon' }}>{currentItem.payload.message}</div>
            </li>,
          );
        } else {
          // low severity notifications - Disabled
          renderOutput.push(
            <li
              key={i}
              style={{ margin: '0px 10px', cursor: 'pointer' }}
              onClick={() => handleSelectNotification(currentItem)}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: 'rgb(73, 169, 182)',
                  }}
                >
                  {currentItem.title}
                </div>
                <div
                  style={{
                    fontSize: '10px',
                    color: 'darkgray',
                    paddingTop: '5px',
                  }}
                >
                  {timeElapsed(currentItem.created_at)}
                  {' '}
                  ago
                </div>
              </div>
              <div style={{ fontSize: '13px', color: 'rgb(73, 169, 182)' }}>
                {currentItem.payload.message}
              </div>
            </li>,
          );
        }

        if (i !== unreadNotifications.length - 1) {
          renderOutput.push(
            <div
              key={i}
              style={{
                backgroundColor: 'lightgray',
                width: '95%',
                height: '1px',
                margin: 'auto',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            />,
          );
        }
      }
    }
    ReactDOMClient.createRoot(document.getElementById('notifications-list')).render(renderOutput);

    // Set notification as seen - TEMPORARILY DISABLED
    notificationsResponse.data.forEach(async (notification) => {
      await postData(`/notifications/${notification.id}`, { ...notification, is_seen: true });
    });
    setNotificationCount(0);
  };

  // Update name
  const updateUserDetails = async () => {
    if (currentUser.first_name === '' || currentUser.last_name === '' || currentUser.email === '') {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'First name, Lastname and Email fields can not be empty',
        severity: 'error',
      }));
      return;
    }
    const updateUserReq = await patchData(`/accounts/${currentUser.id}`, currentUser);

    if (updateUserReq.status !== 204) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: updateUserReq.data,
        severity: 'error',
      }));
    } else {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Data successfully updated',
        severity: 'success',
      }));
      setShowEditName(false);
      setShowEditEmail(false);
    }
  };

  // Toogle Two-factor Authentication
  const toogle2FA = async () => {
    const update2FA = await patchData(`/accounts/${currentUser.id}`, {
      two_factor_enabled: !currentUser.two_factor_enabled,
    });
    if (update2FA.status !== 204) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Some error occured. Please try again.',
        severity: 'error',
      }));
      return;
    }
    setMessagebar((prev: any) => ({
      ...prev,
      show: true,
      text: '2FA setting successfully updated',
      severity: 'success',
    }));
    const getCurrentUser = await getData(`/accounts/${currentUser.id}`);
    if (getCurrentUser.status !== 200) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Some error occured. Please try again.',
        severity: 'error',
      }));
      return;
    }
    setCurrentUser(getCurrentUser.data);
  };

  // Change password
  const changePassword = async () => {
    if (oldNewPasswords.new_password !== oldNewPasswords.re_new_password) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'New passwords do not match',
        severity: 'error',
      }));
      return;
    }

    const body = {
      old_password: oldNewPasswords.old_password,
      new_password: oldNewPasswords.new_password,
    };
    const changePasswordReq = await patchData(`/accounts/password/${currentUser.id}`, body);

    if (changePasswordReq.status !== 204) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: changePasswordReq.data,
        severity: 'error',
      }));
    } else {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Password successfully changed',
        severity: 'success',
      }));
      setShowChangePassword(false);
    }
  };

  // Handle upload image
  const handleUploadImage = async (e: any) => {
    if (e.target.files[0]) {
      const body = new FormData();
      body.append('user_id', currentUser.id);
      body.append('uploadfile', e.target.files[0]);

      const uploadImage = await postData('/files/profilepicture', body);
      if (uploadImage.status !== 200) {
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: uploadImage.data,
          severity: 'error',
        }));
        return;
      }
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Profile picture successfully changed',
        severity: 'success',
      }));

      const getImage = await getData(`/files/profilepicture/${currentUser.id}`);
      setCurrentUser((prev) => ({ ...prev, pictureBase64: getImage.data }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const accountId = getAccountId();
      const currentUserResponse = await getData(`/accounts/${accountId.accountId}`);
      setCurrentUser(currentUserResponse.data);

      if (currentUserResponse.data.picture.length > 0) {
        const getImage = await getData(`/files/profilepicture/${currentUserResponse.data.id}`);
        setCurrentUser((prev) => ({ ...prev, pictureBase64: getImage.data }));
      }

      const currentOrgResponse = await getData(
        `/organisations/${currentUserResponse.data.organisation}`,
      );
      setCurrentOrg(currentOrgResponse.data);
      updateNotificationCount();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (showPopup) renderNotifications();
  }, [showPopup]);

  const drawer = (
    <div className="sidebar-wrapper">
      <div className="logo">
        <Link to="/dashboard">
          <Logo width="150px" />
        </Link>
      </div>
      <div className="avatar-area">
        {currentUser.picture.length === 0 && (
          <Box>
            <AccountCircleIcon
              sx={{
                height: '50px',
                width: '50px',
                color: 'rgb(200,200,200)',
                cursor: 'pointer',
              }}
              onClick={() => setShowUserDetails(true)}
            />
          </Box>
        )}
        {currentUser.picture.length > 0 && (
          <Box>
            <Avatar
              sx={{ height: '50px', width: '50px', cursor: 'pointer' }}
              alt={currentUser.first_name}
              src={`data:image/jpeg;base64, ${currentUser.pictureBase64}`}
              onClick={() => setShowUserDetails(true)}
            />
          </Box>
        )}
        <div className="details">
          <div className="name">
            <Box sx={{ cursor: 'pointer' }} onClick={() => setShowUserDetails(true)}>
              {/* {currentUser.first_name.length}  19 is max */}
              {(currentUser.first_name + currentUser.last_name).length < 20
                ? `${currentUser.first_name} ${currentUser.last_name}`
                : `${`${currentUser.first_name} ${currentUser.last_name}`.substring(0, 17)}...`}
            </Box>
            {/* </Link> */}
          </div>
          <div className="company">
            {currentOrg.name ? `${currentOrg.name.substring(0, 24)}...` : ''}
          </div>
        </div>
        {notificationCount > 0 && (
          <div
            className="flex bg-customPrimaryMain text-white items-center px-3 h-10 rounded-3xl cursor-pointer"
            onClick={() => setShowPopup(!showPopup)}
          >
            <i className="fas fa-bell text-[#284d51]"> &nbsp;</i>
            <span>{notificationCount}</span>
          </div>
        )}
        {notificationCount === 0 && (
          <div
            className="flex bg-customPrimaryMain text-white items-center px-3 h-10 rounded-3xl"
            onClick={() => setShowPopup(!showPopup)}
          >
            <i className="fas fa-bell"> &nbsp;</i>
            <span>{notificationCount}</span>
          </div>
        )}
      </div>

      {
        // Notifications floating popup
        showPopup && (
          <Card
            ref={notificationsWrapperRef}
            className="cardAction"
            style={{
              width: '400px',
              margin: '50',
              marginTop: '11.2vh',
              position: 'absolute',
              left: 310,
            }}
          >
            <div className="notifications-header">
              Notifications
              <div onClick={() => setShowPopup(false)}>
                <i className="fas fa-close" style={{ color: 'white', cursor: 'pointer' }} />
              </div>
            </div>
            <CardContent
              style={{
                marginBottom: '-20px',
                maxHeight: '500px ',
                overflowY: 'auto',
              }}
            >
              <ul id="notifications-list" className={click ? 'notify' : 'notify-menu'} />
            </CardContent>
          </Card>
        )
      }

      <br />
      {menu.map((item) => (
        <div
          key={item.name}
          className={
            item.to === window.location.pathname
              ? 'allLinks menu-item active'
              : 'allLinks menu-item inactive'
          }
          onClick={() => handleSelectMenu(item)}
        >
          <i className={`menu-icon ${item.icon}`} />
          <span className="text">{item.name}</span>
        </div>
      ))}
      <hr style={{ width: '70%', margin: '20px auto' }} />
      <div className="menu-items">
        <div className="menu-item">
          <div className="allLinks" onClick={() => onLogOut()}>
            <i className="logout-icon fas fa-sign-out-alt fa-2x" />
            <span className="logout-text">Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
  const sidebarMobile = (
    <Box component="nav">
      <Drawer
        variant="temporary"
        open={openSidebar}
        onClose={() => setOpenSidebar(!openSidebar)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            minWidth: drawerWidth,
            // marginTop: '57px',
            marginTop: '0px',
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );

  return (
    <div>
      {sidebarMobile}
      <Hidden mdDown>
        <Grid item>{drawer}</Grid>
      </Hidden>
      {/* Show User details Dialog */}
      <DialogBox show={showUserDetails} setShow={setShowUserDetails}>
        <Box style={{ padding: '50px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {currentUser.picture.length === 0 && (
              <Box>
                <label htmlFor="file-input">
                  <AccountCircleIcon
                    sx={{
                      height: '150px',
                      width: '150px',
                      color: 'rgb(200,200,200)',
                      cursor: 'pointer',
                    }}
                  />
                </label>
                <input
                  style={{ display: 'none' }}
                  id="file-input"
                  type="file"
                  onChange={(e: any) => handleUploadImage(e)}
                />
              </Box>
            )}
            {currentUser.picture.length > 0 && (
              <Box>
                <label htmlFor="file-input">
                  <Avatar
                    sx={{
                      height: '150px',
                      width: '150px',
                      cursor: 'pointer',
                      '&:hover': { opacity: '0.5' },
                    }}
                    alt={currentUser.first_name}
                    src={`data:image/jpeg;base64, ${currentUser.pictureBase64}`}
                  />
                </label>
                <input
                  style={{ display: 'none' }}
                  id="file-input"
                  type="file"
                  onChange={(e: any) => handleUploadImage(e)}
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '12px',
            }}
          >
            <Typography
              sx={{
                fontSize: '25px',
                fontWeight: '500',
                textAlign: 'center',
                color: 'teal',
              }}
            >
              {/* {console.log(currentUser)} */}
              {currentUser.first_name}
              {' '}
              {currentUser.last_name}
            </Typography>
            <Box>
              <i
                style={{
                  cursor: 'pointer',
                  color: '#999',
                  fontSize: '12px',
                  paddingLeft: '15px',
                }}
                className="fas fa-pencil-alt"
                onClick={() => setShowEditName(true)}
              />
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell sx={{ textAlign: 'left !important', paddingLeft: '50px' }}>
                    Email
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'left !important', paddingLeft: '50px' }}>
                    {currentUser.email}
                  </StyledTableCell>
                  <StyledTableCell>
                    <i
                      style={{ cursor: 'pointer', color: '#999' }}
                      className="fas fa-pencil-alt"
                      onClick={() => setShowEditEmail(true)}
                    />
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell sx={{ textAlign: 'left !important', paddingLeft: '50px' }}>
                    Organisation
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'left !important', paddingLeft: '50px' }}>
                    {currentOrg.name}
                  </StyledTableCell>
                  <StyledTableCell />
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell sx={{ textAlign: 'left !important', paddingLeft: '50px' }}>
                    Registered since
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'left !important', paddingLeft: '50px' }}>
                    {dateConverter(currentUser.created_at)}
                  </StyledTableCell>
                  <StyledTableCell />
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell sx={{ textAlign: 'left !important', paddingLeft: '50px' }}>
                    Two-factor Authentication (2FA)
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'left !important', paddingLeft: '50px' }}>
                    {currentUser.two_factor_enabled ? (
                      <>
                        <span>Activated</span>
                        <Chip
                          icon={<CloseIcon />}
                          label="Disable now"
                          size="small"
                          sx={{
                            marginLeft: '10px',
                            cursor: 'pointer',
                            color: '#495057',
                          }}
                          onClick={() => toogle2FA()}
                        />
                      </>
                    ) : (
                      <>
                        <span>Deactivated</span>
                        <Chip
                          icon={<DoneIcon style={{ fill: '#fff' }} />}
                          label="Enable now"
                          size="small"
                          sx={{
                            marginLeft: '10px',
                            cursor: 'pointer',
                            backgroundColor: '#61b9c3',
                            color: '#fff',
                          }}
                          onClick={() => toogle2FA()}
                        />
                      </>
                    )}
                    {' '}
                  </StyledTableCell>
                  <StyledTableCell />
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={{ textAlign: 'left !important', paddingLeft: '50px' }}>
                    Password
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'left !important', paddingLeft: '50px' }}>
                    ****
                    {' '}
                  </StyledTableCell>
                  <StyledTableCell>
                    <i
                      style={{ cursor: 'pointer', color: '#999' }}
                      className="fas fa-pencil-alt"
                      onClick={() => setShowChangePassword(true)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Box sx={{ display: 'flex', gap: '30px' }}>
            <ButtonFilled handleSubmit={() => setShowUserDetails(false)}>Cancel</ButtonFilled>
            <ButtonFilled handleSubmit={() => setShowUserDetails(false)}>Ok</ButtonFilled>
          </Box>
        </Box>
      </DialogBox>

      {/* Edit name Dialog */}
      <DialogBox show={showEditName} setShow={setShowEditName}>
        <Box style={{ padding: '50px' }}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              {/* <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell> </StyledTableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="right">
                    <input
                      style={{ width: '100%', padding: '5px', outline: 'none' }}
                      type="text"
                      placeholder="First name"
                      // value={`${currentUser.first_name}`}
                      onChange={(e) =>
                        setCurrentUser((prev: any) => ({
                          ...prev,
                          first_name: e.target.value,
                        }))}
                    />
                    <br />
                    <br />
                    <input
                      style={{ width: '100%', padding: '5px', outline: 'none' }}
                      type="text"
                      placeholder="Last name"
                      // value={`${currentUser.last_name}`}
                      onChange={(e) =>
                        setCurrentUser((prev: any) => ({
                          ...prev,
                          last_name: e.target.value,
                        }))}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <Chip
                      icon={<DoneIcon style={{ fill: '#fff' }} />}
                      label="Confirm"
                      size="small"
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: '#61b9c3',
                        color: '#fff',
                      }}
                      onClick={() => updateUserDetails()}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogBox>

      {/* Edit email Dialog */}
      <DialogBox show={showEditEmail} setShow={setShowEditEmail}>
        <Box style={{ padding: '50px' }}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              {/* <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell> </StyledTableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="right">
                    <input
                      style={{ width: '100%', padding: '5px', outline: 'none' }}
                      type="text"
                      placeholder="New Email"
                      onChange={(e) =>
                        setCurrentUser((prev: any) => ({
                          ...prev,
                          email: e.target.value,
                        }))}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <Chip
                      icon={<DoneIcon style={{ fill: '#fff' }} />}
                      label="Confirm"
                      size="small"
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: '#61b9c3',
                        color: '#fff',
                      }}
                      onClick={() => updateUserDetails()}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogBox>

      {/* Change password Dialog */}
      <DialogBox show={showChangePassword} setShow={setShowChangePassword}>
        <Box style={{ padding: '50px' }}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="right">
                    <input
                      style={{ width: '100%', padding: '5px', outline: 'none' }}
                      type="password"
                      placeholder="Old Password"
                      onChange={(e) =>
                        setOldNewPasswords((prev: any) => ({
                          ...prev,
                          old_password: e.target.value,
                        }))}
                    />
                    <br />
                    <br />
                    <input
                      style={{ width: '100%', padding: '5px', outline: 'none' }}
                      type="password"
                      placeholder="New Password"
                      onChange={(e) =>
                        setOldNewPasswords((prev: any) => ({
                          ...prev,
                          new_password: e.target.value,
                        }))}
                    />
                    <br />
                    <br />
                    <input
                      style={{ width: '100%', padding: '5px', outline: 'none' }}
                      type="password"
                      placeholder="Re enter New Password"
                      onChange={(e) =>
                        setOldNewPasswords((prev: any) => ({
                          ...prev,
                          re_new_password: e.target.value,
                        }))}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <Chip
                      icon={<DoneIcon style={{ fill: '#fff' }} />}
                      label="Confirm"
                      size="small"
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: '#61b9c3',
                        color: '#fff',
                      }}
                      onClick={() => changePassword()}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogBox>
    </div>
  );
}

export default Sidebar;
