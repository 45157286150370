import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { StyledTableCell, StyledTableRow } from './defaults';

interface Action {
  name: string;
  behaviour: (rowData: any) => void;
  icon?: string;
  iconOnly: boolean;
}

interface ColumnType {
  name?: string;
  iconName?: string;
  columnName?: string;
  columnLabel: string;
  action?: Action;
  popup?: Action;
}

interface Props {
  columns?: ColumnType[];
  data: any;
  capitalize?: boolean;
}

function CustomTable({ columns, capitalize = false, data }: Props) {
  if (!data || data.length === 0) {
    return (
      <div className="no-data">
        <span>
          Our apologies, we are unable to retrieve this data at this time.
          Please try again later.
        </span>
      </div>
    );
  }

  const columnsToRender = columns
    || Object.keys(data[0]).map((columnName) => {
      const columnNameCorrected = capitalize
        ? columnName[0].toUpperCase() + columnName.substring(1)
        : columnName;
      return {
        columnLabel: columnNameCorrected,
      };
    });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columnsToRender.map((column: ColumnType, index: number) => (
              <StyledTableCell key={column.columnLabel + index.toString()}>
                {column.iconName && (
                  <i
                    className={column.iconName}
                    style={{ marginRight: '8px' }}
                  />
                )}
                {column.columnLabel ? column.columnLabel : column.columnName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledTableRow key={index}>
              {columnsToRender.map((column: ColumnType) => {
                const { action, iconName, columnLabel } = column;
                if (action) {
                  if (action.iconOnly) {
                    return (
                      <i
                        className={iconName}
                        style={{ marginRight: '8px' }}
                        onClick={() => action.behaviour(row)}
                      />
                    );
                  }
                  return (
                    <StyledTableCell align="right">
                      {row[columnLabel]}
                    </StyledTableCell>
                  );
                }
                return (
                  <StyledTableCell key={row[columnLabel]} align="right">
                    {row[columnLabel]}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export { CustomTable };
export type { ColumnType, Action };
