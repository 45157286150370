import i18n from 'i18next';
import Cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next';
import translationsEN from '../locales/en/translationEN.json';
import translationsFR from '../locales/fr/translationFR.json';
import translationsES from '../locales/es/translationES.json';
import translationsDE from '../locales/de/translationDE.json';
import translationsCN from '../locales/cn/translationCN.json';

interface Configuration {
  socketURL: string;
  serviceURL: string;
  xeroRedirectURI: string;
  stripeRedirectURI: string;
}

const getServices = (): Configuration => ({
  socketURL: `${process.env.REACT_APP_BACKEND_BASE_URL}`,
  serviceURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1`,
  xeroRedirectURI: `${process.env.REACT_APP_FRONTEND_BASE_URL}/integrations`,
  stripeRedirectURI: `${process.env.REACT_APP_FRONTEND_BASE_URL}/no-license/`,
});

export { getServices };

// Start of - Internationalisation
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationsEN },
    fr: { translation: translationsFR },
    es: { translation: translationsES },
    de: { translation: translationsDE },
    cn: { translation: translationsCN },
  },
  lng: Cookies.get('language') || 'en',
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
});

export { i18n };
// End of - Internationalisation
