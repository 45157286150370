import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { getServices } from '../../config/config';

interface CheckoutFormInterface {
  handleProceedToStage: (arg: number) => void;
  price: number;
  currency: string;
}

function CheckoutForm({ handleProceedToStage, price, currency }: CheckoutFormInterface) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }: PaymentIntentResult) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: getServices().stripeRedirectURI,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message || '');
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div className="h-10 flex justify-between mt-5">
        <div
          onClick={() => handleProceedToStage(2)}
          className="bg-customPrimary rounded-md text-white text-xl py-2 px-4 text-center hover:bg-secondary-color cursor-pointer flex items-center gap-2"
        >
          <MdNavigateBefore color="white" size="20px" />
          <div>Back</div>
        </div>
        <button disabled={isLoading || !stripe || !elements} id="submit" type="button">
          {isLoading ? (
            // <div className="spinner" id="spinner"></div>
            <div className="bg-gray-300 rounded-md text-white text-xl py-2 px-4 text-center flex items-center gap-2">
              <div className="flex justify-center items-center">
                <div
                  className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            <span
              id="button-text"
              className="bg-customPrimary rounded-md text-white text-xl py-2 px-4 text-center hover:bg-secondary-color cursor-pointer flex items-center gap-2"
            >
              <div onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSubmit(e)}>
                Pay
                {' '}
                {currency}
                {' '}
                {price}
              </div>
              <MdNavigateNext color="white" size="20px" />
            </span>
          )}
        </button>
      </div>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
export default CheckoutForm;
