import usePlacesAutocomplete from 'use-places-autocomplete';
import './AddressField.scss';
import React from 'react';

interface Props {
  error: string;
  handleField: (param: { description: string }) => void;
}

function PlacesAutocomplete({ error, handleField }: Props) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      types: ['address'],
    },
    debounce: 300,
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = (suggestion: { description: string }) => {
    setValue(suggestion.description, false);
    handleField(suggestion);
    clearSuggestions();
  };

  const renderSuggestions = () => data.map((suggestion) => {
    // console.log(value.length);

    const {
      place_id: placeId,
      structured_formatting: {
        main_text: mainText,
        secondary_text: secondaryText,
      },
    } = suggestion;

    return (
      <li key={placeId} onClick={() => handleSelect(suggestion)}>
        <small>
          {mainText}
          ,
        </small>
          &nbsp;
        <small>{secondaryText}</small>
      </li>
    );
  });

  return (
    <div className="addressField">
      <label className="addressField-label" htmlFor="address">
        Address
      </label>
      <input
        type="text"
        disabled={!ready}
        value={value}
        onChange={handleInput}
        id="address"
      />
      <div style={{ textAlign: 'right' }}>
        {error && <div className="error">{error}</div>}
      </div>
      {status === 'OK' && <ul>{value.length > 10 && renderSuggestions()}</ul>}
    </div>
  );
}
export default PlacesAutocomplete;
