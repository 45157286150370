import React from 'react';
import Logo1 from '../../images/Evalue8-300px.png';

interface Props {
  width?: string;
  height?: string;
}

function Logo({ width, height }: Props) {
  return <img src={Logo1} alt="Evalue8 Logo" width={width} height={height} />;
}

export default Logo;
