import { useContext } from 'react';
import './Home.scss';
import { Hidden } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Card from '../../../components/Card/Card';
import { DataContext } from '../../../context/DataContext';

function Home() {
  const { setOpenSidebar } = useContext(DataContext);
  return (
    <Card height="100%">
      <div className="home">
        <Hidden mdUp>
          <div className="show-drawer" onClick={() => setOpenSidebar(true)}>
            <MenuIcon className="show-drawer-icon" />
          </div>
        </Hidden>
        {/* <div className="card-title">Home</div> */}
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, ut
          itaque! Rem nobis, itaque aliquid nam repellat id fugiat atque
          assumenda totam eum sapiente quasi. Voluptas minima quas ullam ex,
          veniam sed, necessitatibus voluptatem rerum blanditiis nisi minus,
          expedita quae. Eaque illum nobis ea deserunt velit rem distinctio
          accusamus similique. Lorem, ipsum dolor sit amet consectetur
          adipisicing elit. Labore, non sunt provident culpa, eius nostrum
          exercitationem quisquam atque quos consequatur reprehenderit suscipit
          corrupti odit officia perspiciatis aspernatur nobis? Enim porro iste
          adipisci odio. Architecto cum est exercitationem a
        </p>
      </div>
    </Card>
  );
}

export default Home;
