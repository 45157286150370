/* eslint-disable no-restricted-globals */
import axios from 'axios';
import Cookies from 'js-cookie';
import { TableCell, tableCellClasses, TableRow } from '@mui/material';
import { styled } from '@mui/system';
import { parseISO, format } from 'date-fns';
import { NavigateFunction } from 'react-router';
import { Authentication } from '../context/AuthContext';
import { getServices } from '../config/config';

const LOGIN_COOKIES: string[] = [
  'access_token',
  'refresh_token',
  'account_id',
  'is_organisation_admin',
  'license_types',
  'organisation',
  'organisation_has_licenses',
  'email_is_verified',
  'error',
];

export function emailIsValid(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

export function isNumeric(num: any) {
  return !isNaN(num);
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function dateConverter(ISODate: any) {
  const date = new Date(ISODate);
  const year = date.getFullYear();
  const monthNumber = date.getMonth() + 1;
  let monthString;
  const dt = date.getDate();

  switch (monthNumber) {
    case 1:
      monthString = 'January';
      break;
    case 2:
      monthString = 'February';
      break;
    case 3:
      monthString = 'March';
      break;
    case 4:
      monthString = 'April';
      break;
    case 5:
      monthString = 'May';
      break;
    case 6:
      monthString = 'June';
      break;
    case 7:
      monthString = 'July';
      break;
    case 8:
      monthString = 'August';
      break;
    case 9:
      monthString = 'September';
      break;
    case 10:
      monthString = 'October';
      break;
    case 11:
      monthString = 'November';
      break;
    case 12:
      monthString = 'December';
      break;
    default:
      monthString = 'Invalid month';
  }

  return `${dt} ${monthString} ${year}`;
}

export function ISOToAusDate(ISODate: any) {
  const ISOToLongFormat = parseISO(ISODate.substring(0, 18));
  const response = format(new Date(ISOToLongFormat), 'MM/dd/yyyy');
  return response;
}

export function ISOToJSDate(ISODate: any) {
  const ISOToLongFormat = ISODate;
  const response = new Date(ISOToLongFormat);
  return response;
}

export function DateToISO(date: any) {
  const response = new Date(date).toISOString();
  return response;
}

// ***** AXIOS REQUEST TO GET/POST DATA ***** //
const apiPrefix = getServices().serviceURL;

export const getAccountId = () => {
  const accountId = Cookies.get('account_id');
  return { accountId };
};
export const getOrgId = () => {
  const orgId = Cookies.get('organisation');
  return { orgId };
};
const getAuthToken = () => {
  const accessToken = Cookies.get('access_token') ?? '';
  return { accessToken };
};

const getRefreshToken = () => {
  const refreshToken = Cookies.get('refresh_token');
  return { refreshToken };
};

export const generateBearerHeader = () => {
  const { accessToken } = getAuthToken();
  return {
    ...(
      accessToken ? {
        headers: { Authorization: `Bearer ${accessToken}` },
      } : {}
    ),
  };
};

export const getData = async (
  endpoint: string,
  authorizationHeader?: { headers: { Authorization: string } },
) => {
  try {
    const response = await axios.get(
      `${apiPrefix}${endpoint}`,
      authorizationHeader || generateBearerHeader(),
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const postData = async (
  endpoint: string,
  body: object,
  noAuth?: boolean,
) => {
  try {
    let response = {};
    if (noAuth) {
      response = await axios.post(`${apiPrefix}${endpoint}`, body);
    } else {
      response = await axios.post(
        `${apiPrefix}${endpoint}`,
        body,
        generateBearerHeader(),
      );
    }
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const patchData = async (endpoint: string, body: object) => {
  try {
    const response = await axios.patch(
      `${apiPrefix}${endpoint}`,
      body,
      generateBearerHeader(),
    );

    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const deleteData = async (endpoint: string) => {
  try {
    const response = await axios.delete(
      `${apiPrefix}${endpoint}`,
      generateBearerHeader(),
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};
// ***** END OF AXIOS REQUEST TO GET/POST DATA ***** //

// GENERATE RANDOM COLOR
export const randomRGB = () => {
  const min = 0;
  const max = 255;
  const randomR = Math.floor(Math.random() * (max - min + 1) + min);
  const randomG = Math.floor(Math.random() * (max - min + 1) + min);
  const randomB = Math.floor(Math.random() * (max - min + 1) + min);
  return `${randomR}, ${randomG}, ${randomB}`;
};
// END OF GENERATE RANDOM COLOR

// START OF GET USER
export const getCurrentUser = async (navigate: NavigateFunction) => {
  const { accountId } = getAccountId();
  const { accessToken } = getAuthToken();
  const { refreshToken } = getRefreshToken();

  const getCurrentUserResponse = await getData(`/accounts/${accountId}`);

  if (getCurrentUserResponse.status === 200) {
    // If could get user
    return getCurrentUserResponse;
  }
  // IF could NOT get user
  const body = {
    id: accountId,
    access_token: accessToken,
    refresh_token: refreshToken,
  };
  const getNewTokens = await postData('/accounts/refreshtokens', body);
  if (getNewTokens.status === 200) {
    // If tokens refreshed
    Cookies.set('access_token', getNewTokens.data.access_token, {
      sameSite: 'strict',
    });
    Cookies.set('refresh_token', getNewTokens.data.refresh_token, {
      sameSite: 'strict',
    });
    // alert("Your session has expired. We will try to auto-login.");
    const getCurrentUserSecondResponse = await getData(
      `/accounts/${accountId}`,
    );
    return getCurrentUserSecondResponse;
  }
  Cookies.remove('access_token');
  Cookies.remove('refresh_token');
  Cookies.remove('account_id');
  navigate('/');
  navigate(0); // for refresh
  return '';
};
// END OF GET USER

// START OF GET ORG
export const getCurrentOrg = async (navigate: NavigateFunction) => {
  const currentUser = await getCurrentUser(navigate);
  if (currentUser.status === 404) {
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    Cookies.remove('account_id');
  }

  const getCurrentOrgResponse = await getData(
    `/organisations/${currentUser.data.organisation}`,
  );

  return getCurrentOrgResponse;
};
// END OF GET ORG

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#61b9c3',
    color: 'white',
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'center',
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgb(234, 234, 234)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const getBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = function () {
    resolve(reader.result);
  };
  reader.onerror = reject;
  reader.readAsDataURL(file);
});
// End of GET BASE64

// Download a file when source is base64
export const downloadfile = (
  fileTypeArg: string,
  linkSourceArg: string,
  fileNameArg: string,
) => {
  // Eg. fileTypeArg = 'data:application/pdf;base64'
  // Eg. linkSourceArg = BASE64_STRING
  // Eg. fileNameARg = example.pdf

  const linkSource = `${fileTypeArg},${linkSourceArg}`;
  const downloadLink = document.createElement('a');
  const fileName = `${fileNameArg}`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};
// End of Download a file when source is base64

export const prettyModelName = (modelName: string) => (
  modelName.charAt(0).toUpperCase() + modelName.slice(1).replaceAll('_', ' ')
);

export const downloadURI = (data: string, name: string) => {
  const link: any = document.createElement('a');
  link.download = name;
  link.href = data;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const returnBackToLogin = (navigate: (params: any) => any) => {
  LOGIN_COOKIES.forEach((cookie) => {
    Cookies.remove(cookie);
  });
  navigate('/');
  navigate(0);

  // window.location.reload();
};

export const getAuthTokensFromBrowser = (): Authentication => {
  const authObject: any = {};

  LOGIN_COOKIES.forEach((fieldName) => {
    let value = Cookies.get(fieldName) ?? '';
    // check if JSON
    if (
      ['{', '['].includes(value?.trim()[0])
      || ['true', 'false'].includes(value)
    ) {
      value = JSON.parse(value);
    }
    authObject[fieldName] = value;
  });
  return authObject as Authentication;
};

export const storeAuthTokensToBrowser = (tokens: Authentication) => {
  Object.keys(tokens).forEach((fieldName) => {
    let value: any = tokens[fieldName as keyof Authentication];
    if (typeof value === 'string' || value instanceof String) {
      Cookies.set(fieldName, value.trim());
    } else {
      value = JSON.stringify(value);
      Cookies.set(fieldName, value);
    }
  });
};

export const capitalizeFirstLetter = (value: string) => {
  const firstLetter = value.charAt(0).toUpperCase();
  const remainingLetters = value.slice(1);
  return firstLetter + remainingLetters;
};
