/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box, Button, Grid, Hidden,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import React from 'react';
import TabLinks from '../../../components/TabLinks/TabLinks';

interface PropsFirstLine {
  tabValues: { name: string; value: string; active: boolean }[];
  handleTab: (e: any) => void;
  handleDropdown: (e: any) => void;
  reportExpiry?: Date;
  subOrganisations?: any[];
}
export default function FirstLine({
  tabValues,
  handleTab,
  handleDropdown,
  reportExpiry,
  subOrganisations,
}: PropsFirstLine) {
  const row = (
    <>
      <TabLinks data={tabValues} func={handleTab} />
      <select
        name="options"
        id="options"
        className="px-3 py-1 dropdown-options bg-transparent rounded-lg border-2 border-customPrimaryMain text-customPrimaryMain"
        onChange={(e) => handleDropdown(e.target.value)}
      >
        <option value="activityChart">Activity chart</option>
        <option value="timeSeriesChart">Time series chart</option>
      </select>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#61b9c3',
          fontWeight: '400',
          fontSize: '18px',
          border: '2px solid #61b9c3',
          borderRadius: '7px',
          padding: '0px 40px',
        }}
      >
        {subOrganisations
          && subOrganisations.map((rowSub: any, index: number) => (
            <label key={`key-${rowSub.id}`}>{index === 0 && rowSub.name}</label>
          ))}
      </Box>
    </>
  );
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <Grid item>
        <Hidden mdUp>
          <Box
            sx={{
              margin: '20px 0',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'center',
            }}
          >
            {row}
          </Box>
        </Hidden>
        <Hidden mdDown>
          <Box
            sx={{
              margin: '30px 0',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
            }}
          >
            {row}
          </Box>
        </Hidden>
      </Grid>
      {reportExpiry && (
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            sx={{ color: 'rgb(100, 100, 100)' }}
            size="large"
            startIcon={<CalendarTodayIcon className="start-icons" />}
          >
            Report expiry
          </Button>
          <span>
            <DatePicker
              disabled
              selected={new Date(reportExpiry)}
              dateFormat="dd/MM/yyyy"
              // eslint-disable-next-line no-console
              onChange={() => console.log('Not allowed to change')}
              className="w-100 outline-0 cursor-pointer"
            />
          </span>
        </Grid>
      )}
    </Grid>
  );
}
