import { TableCell, tableCellClasses, TableRow } from '@mui/material';
import { styled } from '@mui/system';

export const defaultErrors = {
  emailError: '',
};

export const defaultUsers = {
  created_at: '',
  email: '',
  first_name: '',
  id: '',
  last_name: '',
  role: '',
  license: '',
};

export const defaultOrg = {
  active_activity_set: '',
  activity_sets: [''],
  address: {},
  admin_members: [''],
  child_ids: [''],
  created_at: '',
  created_by: '',
  current_invite_key: '',
  floor_space: 0,
  id: '',
  identifier: '',
  identifier_type: '',
  invite_key_exp: '',
  is_deleted: false,
  member_list: [
    {
      created_at: '',
      email: '',
      first_name: '',
      id: '',
      last_name: '',
      license: '',
      role: '',
    },
  ],
  merged_from: [''],
  name: '',
  number_of_staff: 0,
  parent_id: '',
  region: '',
  turnover: 0,
  updated_at: '',
};

export const defaultSelectedUser = {
  id: '',
  name: '',
  email: '',
  organisation: '',
  license: '',
  created_at: '',
};

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#61b9c3',
    color: 'white',
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'center',
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgb(234, 234, 234)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
