import axios from 'axios';
import { useEffect, useState } from 'react';
import { getServices } from '../config/config';
import { getAuthTokensFromBrowser } from '../utils/utils';

const useAxios = (url: string, body?: object) => {
  const apiPrefix = getServices().serviceURL;
  const { access_token: accessToken } = getAuthTokensFromBrowser();
  const [data, setData] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!body) {
          const getData = await axios.get(`${apiPrefix}${url}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          setData(getData.data);
        } else {
          const getData = await axios.post(`${apiPrefix}${url}`, body, {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          setData(getData.data);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setError(err.response);
      }
    };
    fetchData();
  }, [url, accessToken, apiPrefix, body]);

  return { data, error };
};

export default useAxios;
