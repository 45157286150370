/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Author: Manish Ghimire
// Organisation: Evalue8 Sustainability, Level 5 / 1 Moore Street, ACT

import {
  Avatar,
  Button,
  Card,
  Chip,
  Checkbox,
  Dialog,
  Pagination,
  Paper,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  differenceInDays, formatDistance, format, subDays,
} from 'date-fns';
import DatePicker from 'react-datepicker';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { AiFillPlusCircle } from 'react-icons/ai';
import BasicUpload from '../../../components/BasicUpload/BasicUpload';
import DialogBox from '../../../components/DialogBox/DialogBox';
import {
  downloadfile,
  getAccountId,
  getOrgId,
  getData,
  postData,
  StyledTableCell,
  StyledTableRow,
  ISOToJSDate,
  deleteData,
  DateToISO,
  capitalizeFirstLetter,
} from '../../../utils/utils';
import backgroundImage from '../../../images/FaintBackground.jpg';
import { DataContext } from '../../../context/DataContext';
import HamburgerIcon from '../../../components/HamburgerIcon/HamburgerIcon';
import ActivitySetTile from './ActivitySetTile';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface CustomTabsProps {
  handleExcelUpload: (templateToUpload: any) => void;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomUploadTabs({ handleExcelUpload }: CustomTabsProps) {
  const { orgId } = getOrgId();
  const [value, setValue] = React.useState(0);
  const { setMessagebar } = useContext(DataContext);
  const [activityTemplates, setActivityTemplates] = useState([{}]);
  const [dataNeededToDownload, setDataNeededToDownload] = useState({
    startDate: new Date(),
    endDate: new Date(),
    activityTemplates: [''],
  });
  const [templateToUpload, setTemplateToUpload] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      // Resetting Activity Templates
      setActivityTemplates([{}]);

      // Get all activity templates
      const getAllActivityTemplates = await getData(`/activitytemplates?organisation_id=${orgId}`);
      if (getAllActivityTemplates.status !== 200) {
        // console.log("No matching activity template found");
        return;
      }

      // Only unique ones
      const uniqueNames: any = [];
      const uniqueActivityTemplates: any = [];
      getAllActivityTemplates.data.forEach((single: any) => {
        if (!uniqueNames.includes(single.name)) {
          uniqueNames.push(single.name);
          uniqueActivityTemplates.push(single);
        }
      });

      const getAllActivityTemplatesWithcheckedFalse = uniqueActivityTemplates.map(
        (single: any) => ({
          ...single,
          checked: false,
        }),
      );
      setActivityTemplates(getAllActivityTemplatesWithcheckedFalse);
    };
    fetchData();
  }, [orgId]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const selectAllActivities = (e: any, allActivityTemplates: any) => {
    let res;
    if (e.currentTarget.checked === true) {
      res = allActivityTemplates.map((single: any) => ({
        ...single,
        checked: true,
      }));
      setActivityTemplates(res);
    } else {
      res = allActivityTemplates.map((single: any) => ({
        ...single,
        checked: false,
      }));
      setActivityTemplates(res);
    }

    // Find all activity templates where checked is true and also check for unique values
    const allTrueSetTemplates = res
      .filter((single: any) => single.checked === true)
      .map((single: any) => single.id);
    const unique = Array.from(new Set(allTrueSetTemplates));
    setDataNeededToDownload((prev: any) => ({
      ...prev,
      activityTemplates: unique,
    }));
  };

  const handleDownloadTemplateCheckbox = (val: any) => {
    const res = activityTemplates.map((single: any) => (single.id === val.id ? { ...single, checked: !single.checked } : single));
    setActivityTemplates(res);

    // Find all activity templates where checked is true and also check for unique values
    const allTrueSetTemplates = res
      .filter((single: any) => single.checked === true)
      .map((single: any) => single.id);
    const unique = Array.from(new Set(allTrueSetTemplates));
    setDataNeededToDownload((prev: any) => ({
      ...prev,
      activityTemplates: unique,
    }));
  };

  const handleCreateTemplateButton = async () => {
    if (
      dataNeededToDownload.activityTemplates[0] === ''
      || dataNeededToDownload.activityTemplates.length === 0
    ) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Please select the activity templates',
        severity: 'error',
      }));
      return;
    }
    const body = {
      dateStart: dataNeededToDownload.startDate.valueOf(),
      dateEnd: dataNeededToDownload.endDate.valueOf(),
      organisationId: orgId,
      fType: 'xlsx',
      activityTemplates: dataNeededToDownload.activityTemplates,
    };
    const downloadExcel = await postData('/files/generateexceltemplate', body);
    if (downloadExcel.status !== 200) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Some error occurred. Please try again.',
        severity: 'error',
      }));
      return;
    }

    const fileType = 'data:application/octet-stream;base64';
    const fileString = downloadExcel.data.data;
    const { fileName } = downloadExcel.data;

    downloadfile(fileType, fileString, fileName);
    setMessagebar((prev: any) => ({
      ...prev,
      show: true,
      text: 'Template file downloaded',
      severity: 'success',
    }));
    if (
      dataNeededToDownload.activityTemplates[0] === ''
      || dataNeededToDownload.activityTemplates[0] === undefined
    ) {
      return;
    }
    setValue(1);
  };

  const handleUpload = (data: any) => {
    // console.log("File ready to be uploaded. Waiting for confirmation");
    setTemplateToUpload(data[0]);
  };

  const handleUploadBtn = () => {
    // console.log("File uploaded");
    handleExcelUpload(templateToUpload);
    setValue(0);
  };

  return (
    <Box sx={{ width: '100%', minHeight: '680px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab
            icon={<LooksOneIcon />}
            iconPosition="start"
            label="Create a template"
            {...a11yProps(0)}
          />
          <Tab
            icon={<LooksTwoIcon />}
            iconPosition="start"
            label="Upload template"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '600px',
          }}
        >
          <Box>
            <Box sx={{ margin: '20px 0', display: 'flex', gap: '20px' }}>
              <Box sx={{ flex: '1' }}>
                <Box>Start Date</Box>
                <Box sx={{ border: '2px solid #61b9c3', padding: '5px 10px' }}>
                  <DatePicker
                    maxDate={subDays(new Date(), 1)}
                    selected={dataNeededToDownload.startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date: any) => setDataNeededToDownload((prev: any) => ({
                      ...prev,
                      startDate: date,
                    }))}
                    className="w-100 outline-0 cursor-pointer"
                  />
                </Box>
              </Box>

              <Box sx={{ flex: '1' }}>
                <Box>End Date</Box>
                <Box sx={{ border: '2px solid #61b9c3', padding: '5px 10px' }}>
                  <DatePicker
                    maxDate={subDays(new Date(), 1)}
                    selected={dataNeededToDownload.endDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date: any) => setDataNeededToDownload((prev: any) => ({
                      ...prev,
                      endDate: date,
                    }))}
                    className="w-100 outline-0 cursor-pointer"
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ maxHeight: '400px', overflow: 'scroll' }}>
              <TableContainer component={Paper} sx={{ margin: '20px 0' }}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Activity template</StyledTableCell>
                      <StyledTableCell align="right">
                        <input
                          style={{
                            transform: 'scale(1.6)',
                            accentColor: '#fff',
                          }}
                          type="checkbox"
                          onClick={(event: any) => selectAllActivities(event, activityTemplates)}
                        />
                        {/* </Box> */}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activityTemplates.length > 1
                      && activityTemplates.map((single: any) => (
                        <StyledTableRow key={single.name}>
                          <StyledTableCell align="right">{single.name}</StyledTableCell>
                          <StyledTableCell align="right">
                            <Checkbox
                              checked={single.checked}
                              onChange={() => handleDownloadTemplateCheckbox(single)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              sx={{ color: '#fff', width: '300px' }}
              variant="contained"
              onClick={() => {
                handleCreateTemplateButton();
              }}
            >
              Create template
            </Button>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '600px',
          }}
        >
          <BasicUpload handleUpload={handleUpload} />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Button
              sx={{ color: '#fff', width: '100%' }}
              variant="contained"
              onClick={() => setValue(0)}
            >
              Back
            </Button>
            <Button
              sx={{ color: '#fff', width: '100%' }}
              variant="contained"
              onClick={() => handleUploadBtn()}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </TabPanel>
    </Box>
  );
}

// Confirm Dialog Component
interface ConfirmProps {
  closeFunc: React.Dispatch<React.SetStateAction<boolean>>;
  confirmFunc: () => void;
  children?: any;
}

function Confirm({ children, closeFunc, confirmFunc }: ConfirmProps) {
  return (
    <div style={{ padding: '50px' }}>
      <div className="card-title" style={{ fontSize: '30px', textAlign: 'center' }}>
        {children}
      </div>
      <br />
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Chip
          icon={<CloseIcon style={{ fill: '#fff' }} />}
          label="Cancel"
          size="small"
          sx={{ cursor: 'pointer', backgroundColor: '#f55', color: '#fff' }}
          onClick={() => closeFunc(false)}
        />
        <Chip
          icon={<DoneIcon style={{ fill: '#fff' }} />}
          label="Confirm"
          size="small"
          sx={{ cursor: 'pointer', backgroundColor: '#61b9c3', color: '#fff' }}
          onClick={() => confirmFunc()}
        />
      </Box>
    </div>
  );
}

function Inventory() {
  const navigate = useNavigate();
  const { loading, setLoading, setMessagebar } = useContext(DataContext);
  const NO_OF_ACTIVITY_TYPES_IN_A_PAGE = 8; // TOTAL NUMBER OF ACTIVITIES IN A PAGE (PAGINATION)
  const [activityTypeStartNo, setActivityTypeStartNo] = useState(0);
  const [activityTypeEndNo, setActivityTypeEndNo] = useState(NO_OF_ACTIVITY_TYPES_IN_A_PAGE);
  const { accountId } = getAccountId();
  const { orgId } = getOrgId();
  const [organisations, setOrganisations] = useState([{ id: '' }]);
  const [activitySets, setActivitySets] = useState([
    { id: '', activity_types: [''], is_template: false },
  ]);
  const [activityTypes, setActivityTypes] = useState([{ id: '', name: '' }]);
  const [activities, setActivities] = useState([{}]);
  const [integrations, setIntegrations] = useState([
    { id: '', integration_type: '', source_aliases: [{}] },
  ]);

  const [tempCustomActivitySetName, setTempCustomActivitySetName] = useState('');
  const [tempNotUsedActivityTypes, setTempNotUsedActivityTypes] = useState([]);
  const [tempSelectedActivity, setTempSelectedActivity] = useState({
    id: '',
    start_date: new Date(),
    end_date: new Date(),
    name: '',
    attributes: { value: '', display_unit: '' },
    source: { action: '', integration_type: '' },
    evidences: [{ fileData: '', fileName: '' }],
  });
  const [manualEntry, setManualEntry] = useState({
    template_id: '',
    organisation_id: '',
    start_date: null,
    end_date: new Date(),
    input_values: ['', ''],
    source: {},
  });
  const [filteredActivityTemplates, setFilteredActivityTemplates] = useState([
    { id: '', structure: [] },
  ]);
  const [selectedActivityFormValues, setSelectedActivityFormValues] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showCustomFileUpload, setShowCustomFileUpload] = useState(false);
  const [showActivityDetails, setShowActivityDetails] = useState(false);
  const [showConfirmActivitySetDelete, setShowConfirmActivitySetDelete] = useState(false);
  const [showConfirmSingleActivityDelete, setShowConfirmSingleActivityDelete] = useState(false);
  const [showAddActivityTypes, setShowAddActivityTypes] = useState(false);
  const [showEditActivityDialog, setShowEditActivityDialog] = useState(false);
  const [showInfoActivityDialog, setShowInfoActivityDialog] = useState(false);
  const [showAddExistingIntegration, setShowAddExistingIntegration] = useState(false);
  const [showSelectSourceData, setShowSelectSourceData] = useState(false);
  const [showHandleNewIntegration, setShowHandleNewIntegration] = useState(false);
  const [showManualDataEntryForm, setShowManualDataEntryForm] = useState(false);
  const [searchActivityType, setSearchActivityType] = useState('');

  const handleSwitchActivitySet = () => {
    setShowWelcome((prev: boolean) => !prev);
  };

  const handleSelectActivitySet = async (activitySetId: string) => {
    if (activitySetId === 'select') {
      return;
    }
    setLoading(true);
    const selectedOrg = organisations.filter((single: any) => single.isSelectedOrg);

    const body = {
      activity_set_id: activitySetId,
      organisation_id: selectedOrg[0].id,
    };
    const changeActivitySet = await postData('/activitysets/change', body);
    if (changeActivitySet.status !== 200) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: `${changeActivitySet.data}`,
        severity: 'error',
      }));
      setLoading(false);
      setShowWelcome(false);
      return;
    }
    const newAlteredActivitySets = activitySets.map((single: any) => (single.id === activitySetId
      ? { ...single, isCurrent: true }
      : { ...single, isCurrent: false }));
    setActivitySets(newAlteredActivitySets);
    setShowWelcome(false);
  };

  const handleCustomFileButton = () => {
    setShowCustomFileUpload(true);
  };

  const handleActivityTypeClick = (activityType: any) => {
    const updateActivityTypes = activityTypes.map((single: any) => (single.id === activityType.id
      ? { ...single, isSelected: true }
      : { ...single, isSelected: false }));
    setActivityTypes(updateActivityTypes);

    const updatedActivities = activities.map((single: any) => (single.activity_type === activityType.id
      ? { ...single, toShow: true }
      : { ...single, toShow: false }));
    setActivities(updatedActivities);

    setShowActivityDetails(true);
  };

  const handleExcelUpload = async (templateToUpload: any) => {
    setShowCustomFileUpload(false);
    setLoading(true);
    const body = new FormData();
    body.append('uploadfile', templateToUpload);
    if (orgId) {
      body.append('organisation_id', orgId);
    }
    const uploadTemplate = await postData('/files/extractexceldata', body);
    if (uploadTemplate.status !== 200) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Files other than the template provided by us are not supported.',
        severity: 'error',
      }));
      setLoading(false);
      return;
    }
    if (uploadTemplate.status === 200) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'File successfully uploaded',
        severity: 'success',
      }));
      setLoading(false);
      setRefresh((prev: boolean) => !prev);
    }
  };

  const toEditMode = async () => {
    setLoading(true);

    const currentActivitySetName = activitySets
      .filter((single: any) => single.isCurrent)
      .map((single: any) => single.name)[0];
    setTempCustomActivitySetName(currentActivitySetName);

    setTimeout(() => {
      setEditMode(true);
      setLoading(false);
    }, 500);
  };

  const exitEditMode = async () => {
    setLoading(true);

    const getActiveActivitySet = await getData(
      `/activitysets/getactiveset?organisation_id=${orgId}`,
    );
    if (getActiveActivitySet.status !== 200) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: `${getActiveActivitySet.data}`,
        severity: 'error',
      }));
      return;
    }

    if (tempCustomActivitySetName !== getActiveActivitySet.data.name) {
      // Call API to edit ActivitySet name
      const body = {
        organisation_id: orgId,
        activity_set_id: activitySets
          .filter((single: any) => single.isCurrent)
          .map((single: any) => single.id)[0],
        new_name: tempCustomActivitySetName,
        new_description: 'Default Description',
      };

      const changeActivitySetName = await postData('/activitysets/changenamedescription', body);
      if (changeActivitySetName.status !== 200) {
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: 'Some error occured. Please try again.',
          severity: 'error',
        }));
        setLoading(false);
        setEditMode(false);
        return;
      }
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'ActivitySet name successfully renamed',
        severity: 'success',
      }));
      setRefresh((prev: boolean) => !prev);
    }

    setTimeout(() => {
      setEditMode(false);
      setLoading(false);
    }, 400);
  };

  const deleteCurrentActivitySet = async () => {
    setShowConfirmActivitySetDelete(false);
    setEditMode(false);

    const currentActivitySet = activitySets.filter((activitySet: any) => activitySet.isCurrent)[0];

    if (currentActivitySet.is_template) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Template Activity Sets can not be deleted',
        severity: 'error',
      }));
      return;
    }

    const deleteActivitySet = await deleteData(`/activitysets/${currentActivitySet.id}`);
    if (deleteActivitySet.status !== 204) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Error while deleting. Please try again.',
        severity: 'error',
      }));
      return;
    }
    setRefresh((prev: boolean) => !prev);
  };

  const handleAddActivityTypeButton = async () => {
    const getAllActivityTypes = await getData('/activitytypes');
    if (getAllActivityTypes.status !== 200) {
      // console.log("Error in getting all ActivityTypes");
      return;
    }
    const allActivityTypes = getAllActivityTypes.data;
    const idsOfUsedActivityTypes = activityTypes.map((single: any) => single.id);

    // List of unused ActivityTypes
    const difference = allActivityTypes.filter(
      (single: any) => !idsOfUsedActivityTypes.includes(single.id),
    );
    setTempNotUsedActivityTypes(difference);

    setShowAddActivityTypes(true);
  };

  const addActivityType = async (activityType: any) => {
    const currentActivitySet = activitySets.filter((activitySet: any) => activitySet.isCurrent)[0];

    const body = {
      organisation_id: orgId,
      activity_set_id: currentActivitySet.id,
      activity_type_id: activityType.id,
    };
    const addActivityTypeResponse = await postData('/activitysets/addactivity', body);
    if (addActivityTypeResponse.status !== 200) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Error while adding ActivityType. Please try again.',
        severity: 'error',
      }));
      return;
    }
    setMessagebar((prev: any) => ({
      ...prev,
      show: true,
      text: 'Activity Type successfully added',
      severity: 'success',
    }));

    setTempCustomActivitySetName(addActivityTypeResponse.data.name);

    if (addActivityTypeResponse.data.id !== currentActivitySet.id) {
      const allFormerActiveActivitySets = activitySets
        .filter((activitySet: any) => activitySet.is_template || !activitySet.isCurrent)
        .map((single: any) => ({ ...single, isCurrent: false }));
      const newActivitySet = {
        ...addActivityTypeResponse.data,
        isCurrent: true,
      };
      setActivitySets([...allFormerActiveActivitySets, newActivitySet]);
    }

    // Removing selected activity from the showing list locally
    const notUsedActivityTypes = tempNotUsedActivityTypes.filter(
      (single: any) => single.id !== activityType.id,
    );
    setTempNotUsedActivityTypes(notUsedActivityTypes);

    // Get Current ActivitySet
    const getCurrentActivitySet = await getData(
      `/activitysets/getactiveset?organisation_id=${orgId}`,
    );
    if (getCurrentActivitySet.status !== 200) {
      // console.log("No Active ActivitySet found");
      // setActivityTypes([]);
      setShowWelcome(true);
      setLoading(false);
      return;
    }

    /// /////// Set Activities
    const getAllActivities = await getData(`/organisationactivities/${orgId}`);

    /// /////// Set ActivityTypes
    const getIntegrations = await getData(`/integrations/organisation/${orgId}`);
    if (getIntegrations.status !== 200) {
      // console.log("No Integrations found");
      setLoading(false);
      return;
    }

    // New Array created to find the lastUpdated date of ActivityTypes
    const activityTypesWithLastUpdatedProperty = getCurrentActivitySet.data.activity_type_info.map(
      (single: any) => {
        if (getAllActivities.status !== 404) {
          const getOnlyCertainActivities = getAllActivities.data.map((oneActivity: any) => (oneActivity.activity_type === single.id ? oneActivity.updated_at : ''));
          const getOnlyUpdatedDatesSorted = getOnlyCertainActivities.sort((a: any, b: any) =>
            // eslint-disable-next-line no-nested-ternary, implicit-arrow-linebreak
            (a < b ? -1 : a > b ? 1 : 0));

          // Get name of integration used in this activity type
          const filtered = getIntegrations.data.map((integration: any) => {
            const integrationTypes = integration.linked_activity_types.map(
              (type: any) => type.activity_type,
            );
            const filteredIntegrationTypes = integrationTypes.filter(
              (v: any, i: any, a: any) => a.indexOf(v) === i,
            );
            if (filteredIntegrationTypes.includes(single.id)) {
              return integration.integration_type;
            }
            return '';
          });

          return {
            ...single,
            lastUpdated: getOnlyUpdatedDatesSorted[getOnlyUpdatedDatesSorted.length - 1],
            integrations: filtered,
          };
        }
        return { ...single, lastUpdated: '' };
      },
    );
    setActivityTypes(activityTypesWithLastUpdatedProperty);
  };

  const deleteActivityType = async (activityTypeToDelete: any) => {
    const currentActivitySet = activitySets.filter((activitySet: any) => activitySet.isCurrent)[0];

    // DO NOT DELETE IF THERE's ONLY ONE ACTIVITY_TYPE REMAINING
    if (currentActivitySet.activity_types.length <= 1) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'There must be at least one activity type in an activity set.',
        severity: 'error',
      }));
      setLoading(false);
      return;
    }

    // DELETE AN ACTIVITY_TYPE
    const body = {
      organisation_id: orgId,
      activity_set_id: currentActivitySet.id,
      activity_type_id: activityTypeToDelete.id,
    };

    const deleteActivityTypeResponse = await postData('/activitysets/removeactivity', body);
    if (deleteActivityTypeResponse.status !== 200) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: `${deleteActivityTypeResponse.data}`,
        severity: 'error',
      }));
      return;
    }
    setMessagebar((prev: any) => ({
      ...prev,
      show: true,
      text: 'Activity Type successfully removed',
      severity: 'success',
    }));

    setTempCustomActivitySetName(deleteActivityTypeResponse.data.name);

    if (deleteActivityTypeResponse.data.id !== currentActivitySet.id) {
      const allFormerActiveActivitySets = activitySets
        .filter((activitySet: any) => activitySet.is_template || !activitySet.isCurrent)
        .map((single: any) => ({ ...single, isCurrent: false }));
      const newActivitySet = {
        ...deleteActivityTypeResponse.data,
        isCurrent: true,
      };
      setActivitySets([...allFormerActiveActivitySets, newActivitySet]);
    }

    setActivityTypes(
      activityTypes.filter((activityType: any) => activityType.id !== activityTypeToDelete.id),
    );
  };

  const handleDoneButtonInAddActivityDialog = () => {
    exitEditMode();
    setShowAddActivityTypes(false);
    setEditMode(false);
  };

  const handleActivityDeleteIcon = (activity: any) => {
    setTempSelectedActivity({
      ...activity,
      attributes: activity.attributes[0],
      evidences: [],
    });
    setShowConfirmSingleActivityDelete(true);
  };

  const deleteSingleActivity = async () => {
    const deleteActivity = await deleteData(`/activities/${tempSelectedActivity.id}`);

    if (deleteActivity.status !== 204) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Error while deleting Activity. Please try again.',
        severity: 'error',
      }));
      return;
    }
    setMessagebar((prev: any) => ({
      ...prev,
      show: true,
      text: 'Activity successfully deleted',
      severity: 'success',
    }));

    const newActivitiesAfterDeletion = activities.filter(
      (single: any) => single.id !== tempSelectedActivity.id,
    );
    setActivities(newActivitiesAfterDeletion);
    setShowConfirmSingleActivityDelete(false);
  };

  const handleActivitytEditIcon = (activity: any) => {
    setTempSelectedActivity({
      ...activity,
      attributes: activity.attributes[0],
      evidences: [],
    });
    setShowEditActivityDialog(true);
  };

  const editSingleActivity = async () => {
    const body = {
      activity_id: tempSelectedActivity.id,
      input_values: [tempSelectedActivity.attributes.value],
    };
    const updateInputs = await postData('/activities/updateinputs', body);
    if (updateInputs.status !== 201) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Some error occured while editing activity',
        severity: 'error',
      }));
      return;
    }

    setMessagebar((prev: any) => ({
      ...prev,
      show: true,
      text: 'Editing activity successful',
      severity: 'success',
    }));

    const updatedActivities = activities.map((single: any) => (single.id === tempSelectedActivity.id
      ? {
        ...tempSelectedActivity,
        attributes: [tempSelectedActivity.attributes],
      }
      : single));
    setActivities(updatedActivities);
    setShowEditActivityDialog(false);
  };

  const handleActivitytInfoIcon = async (activity: any) => {
    const getEvidences = await getData(`/files/activityevidence/${activity.id}`);
    if (getEvidences.status !== 200) {
      setTempSelectedActivity({
        ...activity,
        attributes: activity.attributes[0],
        evidences: [],
      });
      setShowInfoActivityDialog(true);
      return;
    }
    setTempSelectedActivity({
      ...activity,
      attributes: activity.attributes[0],
      evidences: getEvidences.data,
    });
    setShowInfoActivityDialog(true);
  };

  const handleUploadEvidence = async (activity: any, e: any) => {
    setUploadLoading(true);
    if (e.target.files[0]) {
      const body = new FormData();
      body.append('activity_id', activity.id);
      body.append('uploadfile', e.target.files[0]);

      const uploadEvidence = await postData('/files/activityevidence', body);
      if (uploadEvidence.status !== 200) {
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: uploadEvidence.data,
          severity: 'error',
        }));
        setUploadLoading(false);
        return;
      }
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Evidence successfully uploaded',
        severity: 'success',
      }));

      const getEvidences = await getData(`/files/activityevidence/${activity.id}`);
      if (getEvidences.status !== 200) {
        setTempSelectedActivity({
          ...activity,
          attributes: activity.attributes[0],
          evidences: [],
        });
        setShowInfoActivityDialog(true);
        return;
      }
      setTempSelectedActivity({ ...activity, evidences: getEvidences.data });
      setUploadLoading(false);
    }
  };

  const handleAddExistingIntegrationButton = async () => {
    const getIntegrations = await getData(`/integrations/organisation/${orgId}`);
    if (getIntegrations.status !== 200) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Error while fetching Integrations. Please try again.',
        severity: 'error',
      }));
      return;
    }
    setIntegrations(getIntegrations.data);
    setShowAddExistingIntegration(true);
  };

  const handleSelectIntegrationType = async (integration: any) => {
    const getIntegrations = await getData(`/integrations/organisation/${orgId}`);
    if (getIntegrations.status !== 200) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Error while fetching Integrations. Please try again.',
        severity: 'error',
      }));
      return;
    }

    const setSelectIntegrations = getIntegrations.data.map((singleIntegration: any) => (singleIntegration.id === integration.id
      ? { ...singleIntegration, isSelected: true }
      : { ...singleIntegration, isSelected: false }));

    const trueIntegration = setSelectIntegrations.filter((single: any) => single.isSelected)[0];
    const linkedActivityTypesNames = trueIntegration.linked_activity_types.map(
      (type: any) => type.source_alias,
    );

    const sourceAliases = trueIntegration.source_aliases.sort().map((single: any) => {
      if (linkedActivityTypesNames.includes(single)) {
        return {
          name: single,
          isSelectedAlias: false,
          isLinkedActivityType: true,
        };
      }
      return {
        name: single,
        isSelectedAlias: false,
        isLinkedActivityType: false,
      };
    });

    const alteredTrueIntegration = {
      ...trueIntegration,
      source_aliases: sourceAliases,
    };
    const alteredIntegrations = setSelectIntegrations.map((single: any) => (single.isSelected ? { ...alteredTrueIntegration } : { ...single }));
    setIntegrations(alteredIntegrations);
    setShowSelectSourceData(true);
  };

  const handleMapCheckbox = (item: string, e: any) => {
    const checkedStatus = e.currentTarget.checked;

    const trueIntegration = integrations.filter((single: any) => single.isSelected)[0];
    const sourceAliases = trueIntegration.source_aliases.map((single: any) => (single.name === item ? { ...single, isSelectedAlias: checkedStatus } : { ...single }));
    const alteredTrueIntegration = {
      ...trueIntegration,
      source_aliases: sourceAliases,
    };
    const alteredIntegrations = integrations.map((single: any) => (single.isSelected ? { ...alteredTrueIntegration } : { ...single }));
    setIntegrations(alteredIntegrations);
  };

  const submitSourceMap = async () => {
    const selectedIntegration = integrations.filter((single: any) => single.isSelected)[0];
    const onlyTrueSources = selectedIntegration.source_aliases.filter(
      (single: any) => single.isSelectedAlias === true,
    );

    const getActivityTemplateByIntegrationType = await getData(
      `/activitytemplates?integration_type=${selectedIntegration.integration_type}`,
    );
    if (getActivityTemplateByIntegrationType.status !== 200) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Error while fetching activity templates. Please try again',
        severity: 'error',
      }));
      return;
    }

    const selectedActivityType = activityTypes.filter((single: any) => single.isSelected)[0];
    const filteredAfterSelect = getActivityTemplateByIntegrationType.data.filter(
      (single: any) => single.activity_type === selectedActivityType.id,
    );
    if (filteredAfterSelect.length === 0) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: `No matching activity template found for ${selectedActivityType.name}. Please try again later.`,
        severity: 'error',
      }));
      return;
    }

    onlyTrueSources.forEach((single: any) => {
      const fetchData = async () => {
        const body = {
          organisation: orgId,
          activity_template_name: filteredAfterSelect[0].name,
          integration: selectedIntegration.id,
          is_active: true,
          map: [
            {
              source_alias: single.name,
              target_alias:
                // Assuming that Templates that will be used by integrations
                // will always have only 1 input
                filteredAfterSelect[0].structure[0].key,
            },
          ],
        };

        const res = await postData('/activitysourcemaps', body);
        if (res.status !== 201) {
          setMessagebar((prev: any) => ({
            ...prev,
            show: true,
            text: `${selectedActivityType.name} - ${res.data}`,
            severity: 'error',
          }));
          setShowSelectSourceData(false);
          setShowAddExistingIntegration(false);
          return;
        }

        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: `${selectedActivityType.name} is now mapped to selected item(s) successfully`,
          severity: 'success',
        }));
      };

      fetchData();
    });
  };

  const handleNewIntegrationButton = () => {
    setShowActivityDetails(false);
    setShowHandleNewIntegration(true);
  };

  const handleManualDataEntry = async () => {
    setManualEntry({
      template_id: '',
      organisation_id: '',
      start_date: null,
      end_date: new Date(),
      input_values: ['', ''],
      source: {},
    }); // Resetting to empty values
    setFilteredActivityTemplates([]);
    setSelectedActivityFormValues([]);

    setShowManualDataEntryForm(true);
  };

  const handleDatesChange = async (input: any) => {
    const selectedActivityType = activityTypes.filter((single: any) => single.isSelected)[0];

    if (input.type === 'start') {
      setManualEntry((prev: any) => ({
        ...prev,
        start_date: input.date,
      }));
      const startDate = DateToISO(input.date);
      const getRecommendedActivityTemplates = await getData(
        `/activitytemplates?start_date=${startDate}&organisation_id=${orgId}`,
      );
      if (getRecommendedActivityTemplates.status !== 200) {
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: 'Error while fetching recommended activity templates',
          severity: 'error',
        }));
        return;
      }
      const filteredActivityTemplatesById = getRecommendedActivityTemplates.data.filter(
        (single: any) => single.activity_type === selectedActivityType.id,
      );
      setFilteredActivityTemplates(filteredActivityTemplatesById);
    }

    if (input.type === 'end') {
      setManualEntry((prev: any) => ({
        ...prev,
        end_date: input.date,
      }));
    }
  };

  const handleActivityFormValues = (e: any) => {
    const selectedFormTemplate = filteredActivityTemplates.filter(
      (single: any) => single.id === e.target.value,
    );
    setSelectedActivityFormValues(selectedFormTemplate[0].structure);
    setManualEntry((prev: any) => ({
      ...prev,
      template_id: selectedFormTemplate[0].id,
      organisation_id: orgId,
      input_values: ['', ''],
      // source: {},
    }));
  };

  const handleManualInputs = (index: number, value: any) => {
    const values = manualEntry.input_values;
    if (index === 0) {
      values[0] = value;
    } else if (index === 1) {
      values[1] = value;
    }

    setManualEntry((prev: any) => ({
      ...prev,
      input_values: values,
    }));
  };

  const handleManualFormSubmitButton = async () => {
    const editedManualEntryValues = manualEntry.input_values.filter((single) => single !== '');

    const body = {
      template_id: manualEntry.template_id,
      organisation_id: manualEntry.organisation_id,
      start_date: DateToISO(manualEntry.start_date),
      end_date: DateToISO(manualEntry.end_date),
      input_values: editedManualEntryValues,
      source: { action: 'manual_entry' },
    };
    const res = await postData('/activities/createfromtemplate', body);
    if (res.status === 400) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: `${res.data}`,
        severity: 'error',
      }));
    }
    if (res.status === 201) {
      setShowManualDataEntryForm(false);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Data successfully added',
        severity: 'success',
      }));

      // Update state "activities" locally
      const getAllActivities = await getData(`/organisationactivities/${orgId}`);
      if (getAllActivities.status !== 200) {
        // console.log("No Activities found");
        setLoading(false);
        return;
      }
      const sorted = getAllActivities.data.sort((a: any, b: any) => (a.start_date > b.start_date ? -1 : a.start_date < b.start_date ? 1 : 0));

      const activityType = activityTypes.filter((single: any) => single.isSelected)[0];
      const updatedActivities = sorted.map((single: any) => (single.activity_type === activityType.id
        ? { ...single, toShow: true }
        : { ...single, toShow: false }));
      setActivities(updatedActivities);

      /// // To upate activity types with lastUpdated property //////////////////
      const getIntegrations = await getData(`/integrations/organisation/${orgId}`);
      if (getIntegrations.status !== 200) {
        // console.log("No Integrations found");
        return;
      }

      // New Array created to find the lastUpdated date of ActivityTypes
      const activityTypesWithLastUpdatedProperty = activityTypes
        .map((single: any) => {
          if (getAllActivities.status !== 404) {
            const getOnlyCertainActivities = getAllActivities.data.map((oneActivity: any) => (oneActivity.activity_type === single.id ? oneActivity.updated_at : ''));
            const getOnlyUpdatedDatesSorted = getOnlyCertainActivities.sort((a: any, b: any) => (a < b ? -1 : a > b ? 1 : 0));

            // Get name of integration used in this activity type
            const filtered = getIntegrations.data.map((integration: any) => {
              const integrationTypes = integration.linked_activity_types.map(
                (type: any) => type.activity_type,
              );
              const filteredIntegrationTypes = integrationTypes.filter(
                (v: any, i: any, a: any) => a.indexOf(v) === i,
              );
              if (filteredIntegrationTypes.includes(single.id)) {
                return integration.integration_type;
              }
              return '';
            });

            return {
              ...single,
              lastUpdated: getOnlyUpdatedDatesSorted[getOnlyUpdatedDatesSorted.length - 1],
              integrations: filtered,
            };
          }
          return { ...single, lastUpdated: '' };
        })
        .sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      setActivityTypes(activityTypesWithLastUpdatedProperty);
    }
  };

  const handleChangePage = (e: any, value: any) => {
    setActivityTypeStartNo((value - 1) * NO_OF_ACTIVITY_TYPES_IN_A_PAGE);
    setActivityTypeEndNo(
      (value - 1) * NO_OF_ACTIVITY_TYPES_IN_A_PAGE + NO_OF_ACTIVITY_TYPES_IN_A_PAGE,
    );
  };

  useEffect(() => {
    // Resetting
    setOrganisations([{ id: '' }]);
    setActivityTypes([{ id: '', name: '' }]);
    setActivities([{}]);

    setLoading(true);
    const fetchData = async () => {
      /// /////// Set organisations
      const currentUser = await getData(`/accounts/${accountId}`);
      if (currentUser.status !== 200) {
        // console.log("No user detail found");
        setLoading(false);
        return;
      }
      const getOrgDetails = await getData(`/organisations/${currentUser.data.organisation}`);
      if (getOrgDetails.status !== 200) {
        // console.log("Not able to retrieve organisation details");
        setLoading(false);
        return;
      }
      const updatedOrganisations = getOrgDetails.data.child_organisations.map((single: any) => (single.id === orgId
        ? { ...single, isSelectedOrg: true }
        : { ...single, isSelectedOrg: false }));
      setOrganisations(updatedOrganisations);

      /// /////// Get Current ActivitySet
      const getCurrentActivitySet = await getData(
        `/activitysets/getactiveset?organisation_id=${orgId}`,
      );
      if (getCurrentActivitySet.status !== 200) {
        setShowWelcome(true);
        setLoading(false);
        return;
      }

      /// /////// Set Activities
      const getAllActivities = await getData(`/organisationactivities/${orgId}`);

      /// /////// Set ActivityTypes
      const getIntegrations = await getData(`/integrations/organisation/${orgId}`);
      if (getIntegrations.status !== 200) {
        // console.log("No Integrations found");
        setLoading(false);
        return;
      }

      // New Array created to find the lastUpdated date of ActivityTypes
      const activityTypesWithLastUpdatedProperty = getCurrentActivitySet.data.activity_type_info
        .map((single: any) => {
          if (getAllActivities.status !== 404) {
            const getOnlyCertainActivities = getAllActivities.data.map((oneActivity: any) => (oneActivity.activity_type === single.id ? oneActivity.updated_at : ''));
            const getOnlyUpdatedDatesSorted = getOnlyCertainActivities.sort((a: any, b: any) => (a < b ? -1 : a > b ? 1 : 0));

            // Get name of integration used in this activity type
            const filtered = getIntegrations.data.map((integration: any) => {
              const integrationTypes = integration.linked_activity_types.map(
                (type: any) => type.activity_type,
              );
              const filteredIntegrationTypes = integrationTypes.filter(
                (v: any, i: any, a: any) => a.indexOf(v) === i,
              );
              if (filteredIntegrationTypes.includes(single.id)) {
                return integration.integration_type;
              }
              return '';
            });

            return {
              ...single,
              lastUpdated: getOnlyUpdatedDatesSorted[getOnlyUpdatedDatesSorted.length - 1],
              integrations: filtered,
            };
          }
          return { ...single, lastUpdated: '' };
        })
        .sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      setActivityTypes(activityTypesWithLastUpdatedProperty);

      if (getAllActivities.status !== 200) {
        // console.log("No Activities found");
        setLoading(false);
        return;
      }
      const sorted = getAllActivities.data.sort((a: any, b: any) => (a.start_date > b.start_date ? -1 : a.start_date < b.start_date ? 1 : 0));
      setActivities(sorted);

      setLoading(false);
    };
    fetchData();
  }, [setLoading, refresh, activitySets, accountId, orgId]);

  useEffect(() => {
    // Resetting
    setActivitySets([{ id: '', activity_types: [''], is_template: false }]);

    setLoading(true);
    const fetchData = async () => {
      /// /////// Set ActivitySets
      const allActivitySets = await getData('/activitysets');
      if (allActivitySets.status !== 200) {
        // console.log("No ActivitySets found");
        setLoading(false);
        return;
      }
      setActivitySets(allActivitySets.data);

      /// /////// Get Current ActivitySet
      const getCurrentActivitySet = await getData(
        `/activitysets/getactiveset?organisation_id=${orgId}`,
      );
      if (getCurrentActivitySet.status !== 200) {
        setActivityTypes([]);
        setShowWelcome(true);
        setLoading(false);
        return;
      }
      const newAlteredActivitySets = allActivitySets.data.map((single: any) => (single.id === getCurrentActivitySet.data.id
        ? { ...single, isCurrent: true }
        : { ...single, isCurrent: false }));
      setActivitySets(newAlteredActivitySets);
    };
    fetchData();
  }, [setLoading, refresh, orgId]);

  return (
    <>
      <Card
        sx={{
          margin: '20px',
          borderRadius: '20px',
          padding: '40px',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
          opacity: 1,
          filter: 'brightness(1.05)',
          minHeight: 'calc(100% - 50px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <HamburgerIcon />

        {/* First Row */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Box>
            {!editMode && (
              <Box sx={{ color: 'rgb(100,100,100)' }}>
                {activitySets.map((single: any) => (single.isCurrent === true ? (
                  <Box key={single.id} sx={{ color: '#rgb(100,100,100)', fontSize: '22px' }}>
                    {single.name}
                  </Box>
                ) : (
                  <React.Fragment key={`frag-${single.id}`} />
                )))}
              </Box>
            )}

            {editMode && (
              <input
                style={{
                  color: '#fff',
                  fontWeight: '400',
                  outline: 'none',
                  border: '1px solid transparent',
                  borderRight: 'none',
                  borderRadius: '5px 0 0 5px',
                  padding: '3px',
                  paddingLeft: '9px',
                  width: '300px',
                  backgroundColor: 'rgb(200,200,200)',
                }}
                type="text"
                value={tempCustomActivitySetName}
                placeholder="Activity Set name"
                onChange={(e: any) => setTempCustomActivitySetName(e.target.value)}
              />
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
            {!editMode && (
              <>
                <Button
                  sx={{ color: 'rgb(100, 100, 100)' }}
                  size="large"
                  startIcon={<DashboardCustomizeIcon className="start-icons" />}
                  onClick={() => handleSwitchActivitySet()}
                >
                  Switch Activity Set
                </Button>
                <Button
                  sx={{ color: 'rgb(100, 100, 100)' }}
                  size="large"
                  startIcon={<UploadIcon className="start-icons" />}
                  onClick={() => handleCustomFileButton()}
                >
                  Custom File
                </Button>
              </>
            )}
            {editMode && (
              <>
                <Button
                  // sx={{ color: "rgb(100, 100, 100)" }}
                  sx={{ color: 'rgb(255, 0, 0)' }}
                  size="large"
                  startIcon={<DeleteForeverIcon className="start-icons" />}
                  onClick={() => setShowConfirmActivitySetDelete(true)}
                >
                  Delete Current Activity Set
                </Button>
                <Button
                  sx={{ color: 'rgb(100, 100, 100)' }}
                  size="large"
                  startIcon={<DashboardCustomizeIcon className="start-icons" />}
                  onClick={() => handleAddActivityTypeButton()}
                >
                  Add Activity Type
                </Button>
                <Button
                  sx={{ color: 'rgb(100, 100, 100)' }}
                  size="large"
                  startIcon={<EditIcon className="start-icons" />}
                  onClick={() => exitEditMode()}
                >
                  Done
                </Button>
              </>
            )}
            {!editMode && (
              <Button
                sx={{ color: 'rgb(100, 100, 100)' }}
                size="large"
                startIcon={<EditIcon className="start-icons" />}
                onClick={() => toEditMode()}
              >
                Edit
              </Button>
            )}
          </Box>
        </Box>

        {/* Second Row */}
        {!loading && (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                margin: '30px 0',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
                gap: '40px',
              }}
            >
              {activityTypes.slice(activityTypeStartNo, activityTypeEndNo).map((single: any) => (
                <Box key={single.id}>
                  {editMode && (
                    <Box
                      onClick={() => deleteActivityType(single)}
                      sx={{
                        width: '25px',
                        lineHeight: '25px',
                        borderRadius: '50%',
                        textAlign: 'center',
                        fontSize: '16px',
                        fontWeight: '700',
                        backgroundColor: '#61b9c3',
                        color: '#fff',
                        margin: '0 0 -15px 236px',
                        cursor: 'pointer',
                        position: 'relative',
                        zIndex: '100',
                      }}
                    >
                      X
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: '250px',
                      height: '160px',
                      padding: '10px 20px',
                      borderRadius: '15px',
                      boxShadow: '3',
                      backgroundColor: '#fff',
                      cursor: 'pointer',
                      zIndex: '-100',
                      transition: 'transform .2s',
                      '&:hover': { transform: 'scale(1.03)' },
                    }}
                    onClick={() => !editMode && handleActivityTypeClick(single)}
                  >
                    <Box>
                      <Box component="span">
                        <i className={`${single.icon} fa-lg`} style={{ color: '#666' }}>
                          &nbsp;
                        </i>
                      </Box>
                      <Box component="span" sx={{ color: '#666', fontSize: '20px' }}>
                        {single.name}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        fontSize: '12px',
                        margin: '15px 0',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Box sx={{ color: '#666' }}>
                        <div>
                          {single.lastUpdated === '' || single.lastUpdated === undefined
                            ? 'Never updated'
                            : `Last updated ${formatDistance(
                              ISOToJSDate(single.lastUpdated),
                              new Date(),
                              { addSuffix: true },
                            )}`}
                        </div>
                      </Box>
                      {differenceInDays(ISOToJSDate(single.lastUpdated), new Date()) < 31 && (
                        <Box
                          component="div"
                          sx={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: '#0BDA51',
                            borderRadius: '50%',
                          }}
                        />
                      )}
                      {differenceInDays(ISOToJSDate(single.lastUpdated), new Date()) >= 31 && (
                        <Box
                          component="div"
                          sx={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: 'orange ',
                            borderRadius: '50%',
                          }}
                        />
                      )}
                      {single.lastUpdated === '' && (
                        <Box
                          component="div"
                          sx={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: 'red ',
                            borderRadius: '50%',
                          }}
                        />
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                      {single.integrations
                        && single.integrations.map((integration: any) => (
                          <div key={integration}>
                            {integration && (
                              <Avatar
                                sx={{ width: 30, height: 30 }}
                                alt="Integration"
                                src={`${integration}_logo.png`}
                              />
                            )}
                          </div>
                        ))}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>

            {Math.ceil(activityTypes.length / NO_OF_ACTIVITY_TYPES_IN_A_PAGE) > 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  count={Math.ceil(activityTypes.length / NO_OF_ACTIVITY_TYPES_IN_A_PAGE)}
                  onChange={handleChangePage}
                />
              </Box>
            )}
          </Box>
        )}
      </Card>

      {/* Welcome Dialog */}
      <DialogBox show={showWelcome} setShow={setShowWelcome}>
        <div style={{ padding: '50px 70px' }}>
          <div
            className="card-title text-customFontColor"
            style={{ fontSize: '30px', textAlign: 'center' }}
          >
            Welcome to your inventory!
          </div>
          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            <p>
              This is where you monitor, manage, and modify the activities that are linked to your
              organisation. You do not have any activities set up yet.
            </p>
            <p>Start by selecting an activity set below!</p>
            <p>Don't stress, you can change or edit your activity set at any point.</p>
          </div>

          {/* Three menus */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              gap: '10px 10px',
            }}
          >
            {activitySets.length > 1
              && activitySets
                .filter((single: any) => single.is_template === true)
                .sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map((single: any, index: number) => (
                  <div key={single.id}>
                    <ActivitySetTile
                      activitySetData={single}
                      idx={index}
                      handleSelectActivitySet={handleSelectActivitySet}
                    />
                  </div>
                ))}
          </div>

          {/* More options in dropdown */}
          <Box sx={{ marginTop: '50px', textAlign: 'center' }}>
            <select
              defaultValue="select"
              style={{ width: '300px' }}
              // name="options"
              // id="options"
              className="p-1 rounded-lg border-2 border-customPrimaryMain text-customPrimaryMain"
              onChange={(e) => handleSelectActivitySet(e.target.value)}
            >
              <option value="select">Select an option</option>
              {activitySets
                .filter((single: any) => single.is_template === false)
                .map((single: any) => (
                  <option key={single.id} value={single.id}>
                    {single.name}
                  </option>
                ))}
            </select>
          </Box>
        </div>
      </DialogBox>

      {/* Activity Details Dialog */}
      <DialogBox show={showActivityDetails} setShow={setShowActivityDetails}>
        <div style={{ padding: '50px' }}>
          <div className="card-title" style={{ fontSize: '30px' }}>
            {activityTypes
              .filter((single: any) => single.isSelected)
              .map((single: any) => (
                <React.Fragment key={single.id}>{single.name}</React.Fragment>
              ))}
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button
              sx={{ color: 'rgb(100, 100, 100)' }}
              size="large"
              startIcon={<DashboardCustomizeIcon className="start-icons" />}
              onClick={() => handleAddExistingIntegrationButton()}
            >
              Add Existing Integration
            </Button>
            <Button
              sx={{ color: 'rgb(100, 100, 100)' }}
              size="large"
              startIcon={<DashboardCustomizeIcon className="start-icons" />}
              onClick={() => handleNewIntegrationButton()}
            >
              New Integration
            </Button>
            <Button
              sx={{ color: 'rgb(100, 100, 100)' }}
              size="large"
              startIcon={<DashboardCustomizeIcon className="start-icons" />}
              onClick={() => handleManualDataEntry()}
            >
              Manual Data Entry
            </Button>
          </div>

          <Box sx={{ maxHeight: '500px', overflow: 'scroll' }}>
            <TableContainer component={Paper} sx={{ margin: '20px 0' }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Activity Name</StyledTableCell>
                    <StyledTableCell align="right">Source</StyledTableCell>
                    <StyledTableCell align="right">Values</StyledTableCell>
                    <StyledTableCell align="right">Date Range</StyledTableCell>
                    <StyledTableCell align="right">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activities.filter((single: any) => single.toShow).length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell>
                        <Box sx={{ margin: '10px', textAlign: 'center' }} />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box sx={{ margin: '10px', textAlign: 'center' }} />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box sx={{ margin: '10px', textAlign: 'center' }}>
                          No activity to show...
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box sx={{ margin: '10px', textAlign: 'center' }} />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box sx={{ margin: '10px', textAlign: 'center' }} />
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                  {activities
                    .filter((single: any) => single.toShow)
                    .map((single: any) => (
                      <StyledTableRow key={single.id}>
                        <StyledTableCell align="right">
                          {single.name}
                          {' '}
                          <br />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {single.source.action}
                          {single.source.integration_type && (
                            <>
                              {' '}
                              -
                              {single.source.integration_type}
                            </>
                          )}
                          {single.source.source_alias && (
                          <>
                            {' '}
                            -
                            {single.source.source_alias}
                          </>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {single.attributes.map((attribute: any, idx: number) => (
                            <span key={attribute.value}>
                              {idx > 0 && <>,</>}
                              {' '}
                              {attribute.value}
                              {' '}
                              {attribute.display_unit}
                            </span>
                          ))}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {format(new Date(single.start_date), 'dd/MM/yyyy')}
                          {' '}
                          -
                          {' '}
                          {format(new Date(single.end_date), 'dd/MM/yyyy')}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Box style={{ display: 'flex' }}>
                            <i
                              style={{ cursor: 'pointer', margin: '0 5px' }}
                              className="fas fa-trash"
                              onClick={() => handleActivityDeleteIcon(single)}
                            />
                            <i
                              style={{ cursor: 'pointer', margin: '0 5px' }}
                              className="fas fa-pencil-alt"
                              onClick={() => handleActivitytEditIcon(single)}
                            />
                            <i
                              style={{ cursor: 'pointer', margin: '0 5px' }}
                              className="fa-solid fa-circle-info"
                              onClick={() => handleActivitytInfoIcon(single)}
                            />
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </DialogBox>

      {/* Add Activity Type Dialog */}
      <DialogBox show={showAddActivityTypes} setShow={setShowAddActivityTypes}>
        <Box sx={{ padding: '50px' }}>
          <div className="flex justify-between">
            <div className="text-2xl text-customFontColor">Add an activity Type</div>
            <input
              placeholder="Search here..."
              className="w-60 px-2 outline-none text-customFontColor rounded border-2 border-gray-400"
              onChange={(e: any) => setSearchActivityType(e.target.value)}
            />
          </div>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '20px 15px',
              margin: '30px 0',
            }}
          >
            {/* {notSelectedActivityTypes.length === 0 && <>No more activity types</>} */}
            {tempNotUsedActivityTypes
              .filter((single: any) => single.name.toLowerCase().includes(searchActivityType.toLowerCase()))
              .map((single: any) => (
                <Box
                  key={single.name}
                  sx={{
                    width: '250px',
                    padding: '10px 20px',
                    borderRadius: '15px',
                    boxShadow: '3',
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    transition: 'transform .2s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                  onClick={() => addActivityType(single)}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box component="span" sx={{ color: '#666', fontSize: '20px' }}>
                      {single.name}
                    </Box>
                    {' '}
                    <Box component="span">
                      <AiFillPlusCircle size={25} color="#666" />
                    </Box>
                  </Box>
                </Box>
              ))}
            {tempNotUsedActivityTypes.length === 0 && <p>No more activity types to display ...</p>}
          </Box>

          <Box sx={{ textAlign: 'center' }}>
            <Button
              sx={{ color: '#fff' }}
              variant="contained"
              onClick={() => handleDoneButtonInAddActivityDialog()}
            >
              Done
            </Button>
          </Box>
        </Box>
      </DialogBox>

      {/* Custom CSV/XLSX File upload Dialog */}
      <DialogBox show={showCustomFileUpload} setShow={setShowCustomFileUpload}>
        <Box
          sx={{
            padding: '50px',
            minHeight: '450px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div className="text-2xl text-customFontColor">Custom file upload</div>
          <CustomUploadTabs handleExcelUpload={handleExcelUpload} />
        </Box>
      </DialogBox>

      {/* Confirm Delete Activity Set */}
      <Dialog
        open={showConfirmActivitySetDelete}
        onClose={() => setShowConfirmActivitySetDelete(false)}
        PaperProps={{
          style: {
            borderRadius: '10px',
          },
        }}
      >
        <Confirm closeFunc={setShowConfirmActivitySetDelete} confirmFunc={deleteCurrentActivitySet}>
          Are you sure you want to delete?
        </Confirm>
      </Dialog>

      {/* Confirm Delete Single Activity */}
      <Dialog
        open={showConfirmSingleActivityDelete}
        onClose={() => setShowConfirmSingleActivityDelete(false)}
        PaperProps={{
          style: {
            borderRadius: '10px',
          },
        }}
      >
        <Confirm closeFunc={setShowConfirmSingleActivityDelete} confirmFunc={deleteSingleActivity}>
          Are you sure you want to delete?
        </Confirm>
      </Dialog>

      {/* Show Manual Data Entry Dialog */}
      <DialogBox show={showManualDataEntryForm} setShow={setShowManualDataEntryForm}>
        <Box sx={{ padding: '50px', minHeight: '500px' }}>
          <Box className="card-title" sx={{ fontSize: '30px' }}>
            {activityTypes
              .filter((single: any) => single.isSelected)
              .map((single: any) => (
                <React.Fragment key={single.name}>
                  {single.name}
                  {' '}
                  - Manual Data Entry
                </React.Fragment>
              ))}
          </Box>

          <Box sx={{ marginTop: '20px' }}>
            <Box>Start Date:</Box>
            <Box sx={{ border: '2px solid #61b9c3', padding: '5px 10px' }}>
              <DatePicker
                maxDate={subDays(new Date(), 1)}
                selected={manualEntry.start_date}
                dateFormat="dd/MM/yyyy"
                onChange={(date: any) => handleDatesChange({ type: 'start', date })}
                className="w-100 outline-0 cursor-pointer"
              />
            </Box>
            <br />
            <Box>End Date:</Box>
            <Box sx={{ border: '2px solid #61b9c3', padding: '5px 10px' }}>
              <DatePicker
                // minDate={orgActivityStartDate}
                maxDate={subDays(new Date(), 1)}
                selected={manualEntry.end_date}
                dateFormat="dd/MM/yyyy"
                onChange={(date: any) => handleDatesChange({ type: 'end', date })}
                className="w-100 outline-0 cursor-pointer"
              />
            </Box>
            <br />

            {filteredActivityTemplates.length === 0 && (
              <>
                <Box>Options</Box>
                <Box>
                  <select
                    defaultValue="default"
                    style={{
                      width: '100%',
                      padding: '5px 10px',
                      border: '2px solid #61b9c3',
                      outline: 'none',
                    }}
                    name="options"
                    id="options"
                  >
                    <option value="default" disabled>
                      Select a start date first
                    </option>
                  </select>
                </Box>
              </>
            )}

            {filteredActivityTemplates.length > 0 && (
              <>
                <Box>Options</Box>
                <Box>
                  <select
                    defaultValue="default"
                    style={{
                      width: '100%',
                      padding: '5px 10px',
                      border: '2px solid #61b9c3',
                      outline: 'none',
                    }}
                    name="options"
                    id="options"
                    onChange={(e) => handleActivityFormValues(e)}
                  >
                    <option value="default" disabled>
                      Select an option
                    </option>
                    {filteredActivityTemplates.map((single: any) => (
                      <option key={`${single.id}_${single.name}`} value={single.id}>
                        {single.name}
                      </option>
                    ))}
                  </select>
                </Box>
                <br />
              </>
            )}

            {selectedActivityFormValues.length > 0 && (
              <>
                {selectedActivityFormValues.map((single: any, index: number) => (
                  <Box key={`${single.name}_${single.value}`}>
                    <Box>{capitalizeFirstLetter(single.key)}</Box>
                    <Box>
                      {single.validation === 'float' && (
                        <input
                          style={{
                            color: '#61b9c3',
                            fontWeight: '400',
                            outline: 'none',
                            borderRight: 'none',
                            borderRadius: '5px 0 0 5px',
                            paddingLeft: '9px',
                            margin: '3px 0',
                            width: '100%',
                            border: '2px solid #61b9c3',
                            padding: '5px 10px',
                          }}
                          type="text"
                          value={manualEntry.input_values[index]}
                          placeholder={`${single.key} in ${single.display_unit}`}
                          name="values"
                          onChange={(e: any) => handleManualInputs(index, e.target.value)}
                        />
                      )}
                      {single.validation === 'string' && (
                        <select
                          defaultValue="default"
                          style={{
                            width: '100%',
                            padding: '5px 10px',
                            border: '2px solid #61b9c3',
                            outline: 'none',
                          }}
                          name="options"
                          id="options"
                          onChange={(e) => handleManualInputs(index, e.target.value)}
                        >
                          <option value="default" disabled>
                            Select an option
                          </option>
                          {Object.keys(single.calculation_value).map((singleKey: any) => (
                            <option key={singleKey} value={singleKey}>
                              {capitalizeFirstLetter(singleKey)}
                            </option>
                          ))}
                        </select>
                      )}
                    </Box>
                    <br />
                  </Box>
                ))}
              </>
            )}

            <br />

            <Box sx={{ textAlign: 'center', margin: '0 auto' }}>
              <Button
                sx={{ color: '#fff' }}
                variant="contained"
                onClick={() => handleManualFormSubmitButton()}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogBox>

      {/* Edit Manual Data Entry Dialog */}
      <DialogBox show={showEditActivityDialog} setShow={setShowEditActivityDialog}>
        <Box sx={{ padding: '50px' }}>
          <Box className="card-title" sx={{ fontSize: '30px' }}>
            {activityTypes
              .filter((single: any) => single.isSelected)
              .map((single: any) => (
                <span key={single}>{single.name}</span>
              ))}
            {' '}
            - Edit Data
          </Box>

          <Box sx={{ marginTop: '20px' }}>
            <TableContainer component={Paper} sx={{ margin: '20px 0' }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Activity Name</StyledTableCell>
                    <StyledTableCell align="right">Source</StyledTableCell>
                    <StyledTableCell align="right">
                      Value
                      {' '}
                      {`in ${tempSelectedActivity.attributes.display_unit}`}
                      {' '}
                    </StyledTableCell>
                    <StyledTableCell align="right">Date Range</StyledTableCell>
                    <StyledTableCell align="right">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="right">{tempSelectedActivity.name}</StyledTableCell>
                    <StyledTableCell align="right">
                      {tempSelectedActivity.source.action}
                      {' '}
                      {tempSelectedActivity.source.integration_type && (
                        <>
                          {' '}
                          -
                          {tempSelectedActivity.source.integration_type}
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <input
                        style={{ textAlign: 'center' }}
                        type="text"
                        value={tempSelectedActivity.attributes.value}
                        placeholder="in X unit"
                        onChange={(e) => setTempSelectedActivity((prev: any) => ({
                          ...prev,
                          attributes: {
                            ...prev.attributes,
                            value: e.target.value,
                          },
                        }))}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {format(new Date(tempSelectedActivity.start_date), 'dd/MM/yyyy')}
                      {' '}
                      -
                      {' '}
                      {format(new Date(tempSelectedActivity.end_date), 'dd/MM/yyyy')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Chip
                        icon={<DoneIcon style={{ fill: '#fff' }} />}
                        label="Confirm"
                        size="small"
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: '#61b9c3',
                          color: '#fff',
                        }}
                        onClick={() => editSingleActivity()}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </DialogBox>

      {/* Single activity detail with Evidence Dialog */}
      <DialogBox show={showInfoActivityDialog} setShow={setShowInfoActivityDialog}>
        <Box sx={{ padding: '50px' }}>
          <Box className="card-title" sx={{ fontSize: '30px' }}>
            {tempSelectedActivity.name}
            {' '}
            (With Evidence)
          </Box>

          <Box sx={{ marginTop: '20px' }}>
            <TableContainer component={Paper} sx={{ margin: '20px 0' }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Activity Name</StyledTableCell>
                    <StyledTableCell align="right">Source</StyledTableCell>
                    <StyledTableCell align="right">Value</StyledTableCell>
                    <StyledTableCell align="right">Date Range</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="right">
                      {tempSelectedActivity.name}
                      {' '}
                      <br />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {tempSelectedActivity.source.action}
                      {tempSelectedActivity.source.integration_type && (
                        <>
                          {' '}
                          -
                          {tempSelectedActivity.source.integration_type}
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <span>
                        {tempSelectedActivity.attributes.value}
                        {' '}
                        {tempSelectedActivity.attributes.display_unit}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {format(new Date(tempSelectedActivity.start_date), 'dd/MM/yyyy')}
                      {' '}
                      -
                      {' '}
                      {format(new Date(tempSelectedActivity.end_date), 'dd/MM/yyyy')}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer component={Paper} sx={{ margin: '2  0px 0' }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Evidence Name</StyledTableCell>
                    <StyledTableCell align="right">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempSelectedActivity.evidences.map(({ fileData, fileName }: any) => (
                    <StyledTableRow key={fileName}>
                      <StyledTableCell align="right">{fileName.split(/-(.*)/s)[1]}</StyledTableCell>
                      <StyledTableCell align="right">
                        <a
                          href={`data:application/octet-stream;base64,${fileData}`}
                          download={fileName.split(/-(.*)/s)[1]}
                        >
                          <i
                            style={{
                              cursor: 'pointer',
                              margin: '0 5px',
                              color: '#000',
                            }}
                            className="fa-solid fa-download"
                          />
                        </a>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}

                  <StyledTableRow>
                    <StyledTableCell align="right">
                      {!uploadLoading && (
                        <>
                          <label htmlFor="evidence-file">
                            <Chip
                              icon={<UploadIcon style={{ fill: '#fff' }} />}
                              label="Upload Evidence"
                              sx={{
                                cursor: 'pointer',
                                backgroundColor: '#61b9c3',
                                color: '#fff',
                                '&:hover': { backgroundColor: '#438188' },
                              }}
                            />
                          </label>
                          <input
                            style={{ display: 'none' }}
                            id="evidence-file"
                            type="file"
                            onChange={(e: any) => handleUploadEvidence(tempSelectedActivity, e)}
                          />
                        </>
                      )}
                      {uploadLoading && (
                        <label htmlFor="evidence-file">
                          <LoadingButton
                            size="small"
                            endIcon={<SendIcon />}
                            loading={uploadLoading}
                            loadingPosition="end"
                            variant="contained"
                          >
                            Please wait ...
                          </LoadingButton>
                        </label>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right" />
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </DialogBox>

      {/* List integration types Dialog */}
      <DialogBox show={showAddExistingIntegration} setShow={setShowAddExistingIntegration}>
        <div style={{ padding: '50px' }}>
          <div className="card-title" style={{ fontSize: '30px' }}>
            Add existing integration
          </div>

          <TableContainer component={Paper} sx={{ margin: '20px 0' }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Integration Name</StyledTableCell>
                  <StyledTableCell align="right">Integration Type</StyledTableCell>
                  <StyledTableCell align="right">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {integrations.length === 0 && (
                  <StyledTableRow>
                    <StyledTableCell>
                      <Box sx={{ margin: '10px', textAlign: 'center' }} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box sx={{ margin: '10px', textAlign: 'center' }}>
                        No integrations to show...
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box sx={{ margin: '10px', textAlign: 'center' }} />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {integrations.map((single: any) => (
                  <StyledTableRow key={single.id}>
                    <StyledTableCell align="right">{single.name}</StyledTableCell>
                    <StyledTableCell align="right">{single.integration_type}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Chip
                        icon={<DoneIcon style={{ fill: '#fff' }} />}
                        label="Select"
                        size="small"
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: '#61b9c3',
                          color: '#fff',
                        }}
                        onClick={() => handleSelectIntegrationType(single)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogBox>

      {/* Select source data Dialog */}
      <DialogBox show={showSelectSourceData} setShow={setShowSelectSourceData}>
        <div style={{ padding: '50px', maxHeight: '700px' }}>
          <div className="card-title" style={{ fontSize: '30px' }}>
            {activityTypes
              .filter((single: any) => single.isSelected)
              .map((single: any) => (
                <React.Fragment key={single.name}>
                  Select source data for
                  {' '}
                  {single.name}
                </React.Fragment>
              ))}
          </div>

          <Box sx={{ maxHeight: '500px', overflow: 'scroll' }}>
            <TableContainer component={Paper} sx={{ margin: '20px 0' }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Source Alias</StyledTableCell>
                    <StyledTableCell align="right">Select</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {integrations
                    .filter((single: any) => single.isSelected)
                    .map(
                      (integration: any) => integration.source_aliases.length === 0 && (
                      <StyledTableRow key={integration.id}>
                        <StyledTableCell>
                          <Box sx={{ margin: '10px', textAlign: 'center' }} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box sx={{ margin: '10px', textAlign: 'center' }}>
                            No source aliases to show...
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                      ),
                    )}

                  {integrations
                    .filter((single: any) => single.isSelected)
                    .map((integration: any) => integration.source_aliases.map((single: any) => (
                      <StyledTableRow key={single.name}>
                        <StyledTableCell align="right">{single.name}</StyledTableCell>
                        <StyledTableCell align="right">
                          {single.isLinkedActivityType && (
                          <Checkbox
                            disabled
                            checked
                            onChange={(e) => handleMapCheckbox(single.name, e)}
                          />
                          )}
                          {!single.isLinkedActivityType && (
                          <Checkbox onChange={(e) => handleMapCheckbox(single.name, e)} />
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    )))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <br />
          <Box sx={{ textAlign: 'center' }}>
            <Button sx={{ color: '#fff' }} variant="contained" onClick={() => submitSourceMap()}>
              Map selected to
              {' '}
              {activityTypes
                .filter((single: any) => single.isSelected)
                .map((single: any) => (
                  <React.Fragment key={single.id}>{single.name}</React.Fragment>
                ))}
            </Button>
          </Box>
        </div>
      </DialogBox>

      {/* Select New integration */}
      <DialogBox show={showHandleNewIntegration} setShow={setShowHandleNewIntegration}>
        <div style={{ padding: '50px' }}>
          <div className="card-title" style={{ fontSize: '30px' }}>
            New integration
          </div>
          <p>
            You will be redirected to Integrations page to add a new integration. At that page,
            please click on "Add Integration" at the top-right corner to start the process of adding
            a new integration.
          </p>
          <Box sx={{ textAlign: 'center', margin: '0 auto' }}>
            <Button
              sx={{ color: '#fff' }}
              variant="contained"
              onClick={() => navigate('/integrations')}
            >
              Go to integration page
            </Button>
          </Box>
        </div>
      </DialogBox>
    </>
  );
}

export default Inventory;
