import { useState } from 'react';
import './Recommendation.scss';
import { Box, Card } from '@mui/material';
import backgroundImage from '../../../images/FaintBackground.jpg';
import HamburgerIcon from '../../../components/HamburgerIcon/HamburgerIcon';
import Thinleaf from '../../../images/thinleaf.png';
import BlueWave from '../../../images/blue_wave.png';
import PurpleBulb from '../../../images/purple_bulb.png';
import DialogBox from '../../../components/DialogBox/DialogBox';
import CustomTabs from '../../../components/CustomTabs/CustomTabs';

const data = [
  {
    id: '1',
    label: 'Smart metering',
    content: `If you have a long lease, consider installing a smart meter and a programmable thermostat. A smart meter will provide information that will help you save energy. A
    programmable thermostat enables you to heat and cool work spaces only when people will be in them.`,
  },
  {
    id: '2',
    label: 'Power and Electricity',
    content: `Replace traditional bulbs with light emitting diode (LED) bulbs. Also consider installing a power strip with a switch for each workstation. Get staff members to
    switch on their workstation's power strip at the start of their shift and switch it off at the end.`,
  },
  {
    id: '3',
    label: 'Office Space',
    content: `Consider how much office space you need. Smaller spaces need less heating and cooling. A good rule of thumb for personal space is to allocate 6.25 square metres per
    individual workstation, including furniture and fittings, but excluding passageways and amenities. The Building Code of Australia and Standards Australia AS
    1668.2-2002: The use of ventilation and air conditioning in buildings - Ventilation design for indoor air contaminant control set a minimum space allowance of 10
    square metres per person including passageways and amenities. Most businesses in Australia allow far more than this, with 12 to 14 square metres per person being
    common, and 14-20 in situations where privacy is required. (Note that the minimum requirement may not be set by the Building Code, Standards Australia, or
    operational requirements. Check the awards and enterprise agreements your staff are engaged under, as some of these specify minimum space per person allowances.)`,
  },
  {
    id: '4',
    label: 'Heating and Cooling',
    content: `Keep your office in the temperature range of 20-26 degrees Celsius (lower in winter and higher in
      summer) in Australia. If you have a workshop where staff are exerting themselves physically, keep
      it in range of 18-26 degrees Celsius. (Different temperature ranges optimise productivity in different countries, as staff adapt to
      ambient temperatures.)`,
  },
];

const dataRenew = [
  {
    id: '1',
    label: 'Efficient elec. appliances',
    content: 'Replace old electrical items with new ones as they age. The star rating indicates the energy efficiency of the device.',
  },
  {
    id: '2',
    label: 'Electric over gas',
    content: 'When you upgrade, choose electrical rather than gas-powered appliances.',
  },
  {
    id: '3',
    label: 'Electric vehicles',
    content: 'When you upgrade your fleet, consider electric vehicles and the installation of on-site charging facilities.',
  },
  {
    id: '4',
    label: 'Double glazed windows',
    content: 'Consider double-glazing your windows.',
  },
  {
    id: '5',
    label: 'Insulate buildings',
    content: 'Consider insulating your ceilings and roof.',
  },
  {
    id: '6',
    label: 'Draught-proof buildings',
    content: 'Apply weather seals to doors, windows, skirting boards, skylights and cornices.',
  },
];
const dataRegenerate = [
  {
    id: '1',
    label: 'Plant forests',
    content: 'You can regenerate through your choice of carbon offsets. Evalue8 Sustainability arranges for the purchase of carbon offsets for its customers without charging any fees or commission. Some of these involve regeneration. For example, \'Reforest plants\' forests in Australia and encourages the customers of business to become involved in matching activity, generating Australian carbon credit units (a type of carbon offset).',
  },
];

function Recommendation() {
  const [showRecommendationsReduce, setShowRecommendationsReduce] = useState(false);
  const [showRecommendationsRenew, setShowRecommendationsRenew] = useState(false);
  const [showRecommendationsRegenerate, setShowRecommendationsRegenerate] = useState(false);

  return (
    <>
      <Card
        sx={{
          margin: '20px',
          borderRadius: '20px',
          padding: '40px',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
          opacity: 1,
          filter: 'brightness(1.05)',
          minHeight: 'calc(100% - 50px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <HamburgerIcon />

        <Box sx={{}}>
          <Box
            sx={{
              padding: '15px 0',
              textAlign: 'center',
              fontSize: '32px',
              color: '#61b9c3',
            }}
          >
            Welcome to Your Savings Recommendations!
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              color: 'rgb(100,100,100)',
              padding: '5px 5%',
              marginBottom: '20px',
            }}
          >
            <Box sx={{ fontSize: '18px' }}>
              Let's get started! Select your desired savings pathway below.
            </Box>
            <Box sx={{ fontSize: '18px' }}>
              Don't stress, you can come back to select a different pathway at
              any time.
            </Box>
          </Box>

          <div className="p-10 flex justify-center flex-wrap">
            <div
              onClick={() => setShowRecommendationsReduce(true)}
              className="m-3 w-72 h-58 bg-white flex flex-col justify-between cursor-pointer shadow-lg hover:scale-105"
            >
              <div className="py-3 flex flex-col h-full justify-between items-center">
                <div className="">
                  <img src={PurpleBulb} width="140px" alt="Thin leaf" />
                </div>
              </div>
              <div className="">
                <div className=" text-customTertiary font-bold text-xl text-center">
                  REDUCE
                </div>
                <div className="h-20 p-3 text-customFontColor text-sm text-center">
                  Find "high impact - low effort" methods to reduce your carbon
                  footprints
                </div>
                <div className="p-3 bg-customTertiary text-lg text-white text-center ">
                  Select
                </div>
              </div>
            </div>

            <div
              onClick={() => setShowRecommendationsRenew(true)}
              className="m-3 w-72 h-58 bg-white flex flex-col justify-between cursor-pointer shadow-lg hover:scale-105"
            >
              <div className="py-3 flex flex-col h-full justify-around items-center">
                <div className="p-4">
                  <img src={BlueWave} width="260px" alt="Thin leaf" />
                </div>
              </div>
              <div className="">
                <div className="text-customSecondary font-bold text-xl text-center ">
                  RENEW
                </div>
                <div className="h-20 p-3 text-customFontColor text-sm text-center">
                  Improve your current business infrastructure to support
                  reduced carbon operations
                </div>
                <div className="p-3 bg-customSecondary text-lg text-white text-center ">
                  Select
                </div>
              </div>
            </div>

            <div
              onClick={() => setShowRecommendationsRegenerate(true)}
              className="m-3 w-72 h-58 bg-white flex flex-col justify-between cursor-pointer shadow-lg hover:scale-105"
            >
              <div className="py-3 flex flex-col h-full justify-around items-center">
                <div>
                  <img src={Thinleaf} width="200px" alt="Thin leaf" />
                </div>
              </div>
              <div className="">
                <div className=" text-customPrimary font-bold text-xl text-center ">
                  REGENERATE
                </div>
                <div className="h-20 p-3 text-customFontColor text-sm text-center">
                  Improve your carbon footprint through carbon offsetting
                  schemes such as replantation
                </div>
                <div className="p-3 bg-customPrimary text-lg text-white text-center ">
                  Select
                </div>
              </div>
            </div>

            {/* ////////////////////////////// */}
          </div>
        </Box>
      </Card>

      {/* Pathway Reduce  */}
      <DialogBox
        show={showRecommendationsReduce}
        setShow={setShowRecommendationsReduce}
      >
        <Box sx={{ padding: '60px 45px' }}>
          <Box
            sx={{
              marginBottom: '25px',
              padding: '0px 8px',
              textAlign: 'left',
              fontSize: '32px',
              color: '#61b9c3',
            }}
          >
            Savings Recommendations - Reduce
          </Box>
          <CustomTabs data={data} />
        </Box>
      </DialogBox>
      {/* Pathway Renew  */}
      <DialogBox
        show={showRecommendationsRenew}
        setShow={setShowRecommendationsRenew}
      >
        <Box sx={{ padding: '60px 45px' }}>
          <Box
            sx={{
              marginBottom: '25px',
              padding: '0px 8px',
              textAlign: 'left',
              fontSize: '32px',
              color: '#61b9c3',
            }}
          >
            Savings Recommendations - Renew
          </Box>
          <CustomTabs data={dataRenew} />
        </Box>
      </DialogBox>
      {/* Pathway Regenerate  */}
      <DialogBox
        show={showRecommendationsRegenerate}
        setShow={setShowRecommendationsRegenerate}
      >
        <Box sx={{ padding: '60px 45px' }}>
          <Box
            sx={{
              marginBottom: '25px',
              padding: '0px 8px',
              textAlign: 'left',
              fontSize: '32px',
              color: '#61b9c3',
            }}
          >
            Savings Recommendations - Regenerate
          </Box>
          <CustomTabs data={dataRegenerate} />
        </Box>
      </DialogBox>
    </>
  );
}

export default Recommendation;
