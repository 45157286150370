/* eslint-disable max-len */
import React from 'react';
import { IconType } from 'react-icons';
import { AiFillStar } from 'react-icons/ai';
import { FaIndustry } from 'react-icons/fa';
import { MdAgriculture } from 'react-icons/md';
import { formatToTailwind } from '../../../constants/styles';
import { ActivitySet } from '../../../interfaces';
import { classNames } from '../../../utils/utils';

interface DisplayActivitySet extends ActivitySet {
  color: string;
}

interface PropsActivitySetTile {
  handleSelectActivitySet: (id: string) => void;
  idx: number;
  activitySetData: DisplayActivitySet;
}

const ActivitySetStyling: {
  [name: string]: { icon: IconType; color: string };
} = {
  0: {
    icon: MdAgriculture,
    color: '#54D8C1',
  },
  1: {
    icon: AiFillStar,
    color: '#63B9C4',
  },
  2: {
    icon: FaIndustry,
    color: '#8D5BC0',
  },
};
function ActivitySetTile({ handleSelectActivitySet, idx, activitySetData }: PropsActivitySetTile) {
  const { name } = activitySetData;
  const styles = ActivitySetStyling[idx];
  const { color, icon: Icon } = styles;

  return (
    <div
      onClick={() => handleSelectActivitySet(activitySetData.id)}
      className={classNames(
        'h-48',
        'w-40',
        'text-center',
        'border-3',
        'rounded',
        'flex',
        'flex-col',
        'justify-between',
        'items-center',
        'cursor-pointer',
        'hover:scale-105',
      )}
      style={{
        borderColor: color,
      }}
    >
      <div className="pt-4 flex flex-col items-center">
        <div className="text-center">
          <Icon size="4em" color={color} />
        </div>
        <div
          className={`${formatToTailwind(color)}`}
          style={{
            color,
          }}
        >
          {name}
        </div>
        <div
          style={{
            color: 'grey',
            fontSize: '12px',
            marginBottom: '15px',
          }}
        />
      </div>
      <button
        style={{
          width: '100%',
          backgroundColor: color,
          color: '#fff',
        }}
        type="button"
        onClick={() => handleSelectActivitySet(activitySetData.id)}
      >
        Select
      </button>
    </div>
  );
}

export default ActivitySetTile;
