import { TableCell, tableCellClasses, TableRow } from '@mui/material';
import { styled } from '@mui/system';

export const defaultErrors = {
  emailError: '',
};

export const defaultUsers = {
  created_at: '',
  email: '',
  first_name: '',
  id: '',
  last_name: '',
  role: '',
  license: '',
};

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#61b9c3',
    color: 'white',
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'center',
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgb(234, 234, 234)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
