import { useContext } from 'react';
import './Administration.scss';
import { Hidden } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Card from '../../../components/Card/Card';
import { DataContext } from '../../../context/DataContext';

function Administration() {
  const { setOpenSidebar } = useContext(DataContext);

  return (
    <Card height="100%">
      <div className="administration">
        <Hidden mdUp>
          <div className="show-drawer" onClick={() => setOpenSidebar(true)}>
            <MenuIcon className="show-drawer-icon" />
          </div>
        </Hidden>
        {/* <div className="card-title">Administration</div> */}
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, ut
          itaque! Rem nobis, itaque aliquid nam repellat id fugiat atque
          assumenda totam eum sapiente quasi. Voluptas minima quas ullam ex,
          veniam sed, necessitatibus voluptatem rerum blanditiis nisi minus,
          expedita quae. Eaque illum nobis ea deserunt velit rem distinctio
          accusamus similique.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, ut
          itaque! Rem nobis, itaque aliquid nam repellat id fugiat atque
          assumenda totam eum sapiente quasi. Voluptas minima quas ullam ex,
          veniam sed, necessitatibus voluptatem rerum blanditiis nisi minus,
          expedita quae. Eaque illum nobis ea deserunt velit rem distinctio
          accusamus similique. Lorem, ipsum dolor sit amet consectetur
          adipisicing elit. Labore, non sunt provident culpa, eius nostrum
          exercitationem quisquam atque quos consequatur reprehenderit suscipit
          corrupti odit officia perspiciatis aspernatur nobis? Enim porro iste
          adipisci odio. Architecto cum est exercitationem a rem inventore.
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor
          impedit, amet quos blanditiis recusandae pariatur earum reiciendis,
          odio quas quidem explicabo sapiente nisi quam suscipit! Nam eum ea
          enim mollitia! Quam eos deleniti totam expedita iure odit ea mollitia
          officiis molestias dignissimos porro, repellat ipsum! Magni quasi
          facilis cupiditate dolore omnis! Voluptas totam ipsam natus amet,
          facere nisi quibusdam recusandae dicta quaerat error. Aliquam delectus
          ab reprehenderit corrupti exercitationem animi voluptatum ipsam
          molestias modi magnam ut, aspernatur odit, aperiam asperiores ea
          dolores. Porro nesciunt voluptatibus ducimus minima at molestiae natus
          odio adipisci eos qui distinctio repellendus velit accusantium eveniet
          expedita modi ullam rerum, repudiandae nobis molestias? Atque illo
          vero totam libero eligendi provident fuga, hic nobis asperiores quos
          nostrum ratione officiis possimus reprehenderit corporis tenetur id
          architecto aliquid molestiae? Deserunt eos qui dicta quasi nostrum
          nesciunt dolor voluptatibus, porro, facere laudantium assumenda
          doloribus, quis vero rem. Vitae praesentium esse quis!
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, ut
          itaque! Rem nobis, itaque aliquid nam repellat id fugiat atque
          assumenda totam eum sapiente quasi. Voluptas minima quas ullam ex,
          veniam sed, necessitatibus voluptatem rerum blanditiis nisi minus,
          expedita quae. Eaque illum nobis ea deserunt velit rem distinctio
          accusamus similique.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, ut
          itaque! Rem nobis, itaque aliquid nam repellat id fugiat atque
          assumenda totam eum sapiente quasi. Voluptas minima quas ullam ex,
          veniam sed, necessitatibus voluptatem rerum blanditiis nisi minus,
          expedita quae. Eaque illum nobis ea deserunt velit rem distinctio
          accusamus similique. Lorem ipsum dolor sit amet, consectetur
          adipisicing elit. Est libero iste necessitatibus. Suscipit maiores cum
          voluptatum reprehenderit nulla ratione quis! Eaque culpa blanditiis
          consequuntur omnis.
        </p>
      </div>
    </Card>
  );
}

export default Administration;
