import React from 'react';
import './Dropdown.scss';

interface Props {
  label: string;
  values: {
    id: number;
    title: string;
    shortForm: string;
  }[];
  handleField: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

function Dropdown({ label, values, handleField }: Props) {
  return (
    <>
      {/* <div className={`dropdown${isVertical ? " vert" : ""}`}> */}
      <div className="form_wrapper">
        <label>{label}</label>
        <br />
        <select name="region" onChange={(e) => handleField(e)}>
          <option value="" selected disabled hidden>
            Select an option
          </option>
          {values.map((value) => (
            <option key={value.id} value={value.id}>
              {value.shortForm}
              {' '}
              -
              {value.title}
            </option>
          ))}
        </select>
      </div>
      {/* </div> */}
    </>
  );
}

export default Dropdown;
