/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import './Button.scss';

interface Props {
  [rest: string]: any;
  handleSubmit?: React.MouseEventHandler<HTMLButtonElement>;
}

export function ButtonFilled({ children, handleSubmit, ...rest }: Props) {
  return (
    <button
      className="button-filled"
      onClick={handleSubmit}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      type="submit"
    >
      {children}
    </button>
  );
}

export function ButtonOutlined({ children, handleSubmit }: Props) {
  return (
    <div className="button-outlined">
      <button onClick={handleSubmit} type="button">
        {children}
      </button>
    </div>
  );
}
