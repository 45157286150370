import React from 'react';
import './Card.scss';

interface Props {
  width?: string;
  height?: string;
  backgroundColor?: string;
  children?: any;
}

function Card({
  children, width, height, backgroundColor,
}: Props) {
  return (
    <div
      className="card"
      style={{
        width,
        minHeight: height,
        backgroundColor,
      }}
    >
      <div className="card-content">{children}</div>
    </div>
  );
}

export default Card;
