/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useContext } from 'react';
import * as ReactDOMClient from 'react-dom/client';

import './Integrations.scss';
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { useNavigate } from 'react-router';
import { StyledTableCell, StyledTableRow } from './defaults';
import {
  dateConverter,
  postData,
  getData,
  deleteData,
  getAccountId,
  getCurrentUser,
} from '../../../utils/utils';
import { getServices } from '../../../config/config';
import xeroLogo from '../../../images/xero-logo.png';
import xeroSigninButtonImage from '../../../images/xero-signin-button.png';
import errorIcon from '../../../images/error-icon.png';
import { DataContext } from '../../../context/DataContext';
import DialogBox from '../../../components/DialogBox/DialogBox';
import { ButtonFilled } from '../../../components/Button/Button';
import backgroundImage from '../../../images/FaintBackground.jpg';
import HamburgerIcon from '../../../components/HamburgerIcon/HamburgerIcon';

interface DataToDisplayInterface {
  id: string;
  name: string;
  integration_type: string;
  organisation: string;
  status: string;
  is_active: string;
  last_sync: string;
  linked_activity_types: { [key: string]: any }[];
}

interface SelectedIntegrationInterface {
  id: string;
  name: string;
  integration_type: string;
  organisation: string;
  attributes: { [key: string]: any };
  updated_at: string;
  created_at: string;
  created_by: string;
  status: string;
  is_active: boolean;
  last_sync: string;
  linked_activity_types: { [key: string]: any }[];
}

function Integrations() {
  const { setLoading, setMessagebar } = useContext(DataContext);
  const [showAddIntegrationPopup, setShowAddIntegrationPopup] = useState(false);
  const [showSingleIntegrationPopup, setShowSingleIntegrationPopup] = useState(false);
  const [showDeleteIntegrationWarning, setShowDeleteIntegrationWarning] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<SelectedIntegrationInterface>({
    id: '',
    name: '',
    integration_type: '',
    organisation: '',
    attributes: {},
    updated_at: '',
    created_at: '',
    created_by: '',
    status: '',
    is_active: false,
    last_sync: '',
    linked_activity_types: [],
  });
  const [dataToDisplay, setDataToDisplay] = useState<DataToDisplayInterface[]>([]);
  const [availableIntegrationTypes, setAvailableIntegrationTypes] = useState([]);
  const [subOrganisations, setSubOrganisations] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState('');
  const [selectedTenant, setSelectedTenant] = useState('');
  const [showDeleteSourceMapWarning, setShowDeleteSourceMapWarning] = useState(false);
  const [selectedSourceMapToDelete, setSelectedSourceMapToDelete] = useState<any>({
    activity_type_name: '',
    source_alias: '',
  });

  const navigate = useNavigate();
  const handleSelectOrganisation = async (organistionId: any) => {
    setLoading(true);
    setSelectedOrganisation(organistionId);
    const orgIntegrations = await getData(`/integrations/organisation/${organistionId}`);

    // Set data to show in the table
    setDataToDisplay([]);
    orgIntegrations.data.forEach((single: { [key: string]: any }) => {
      const data = {
        id: single.id,
        name: single.name,
        integration_type: single.integration_type,
        organisation: single.organisation,
        status: single.status,
        is_active: single.is_active,
        last_sync: single.last_sync,
        linked_activity_types: single.linked_activity_types,
      };
      setDataToDisplay((prev) => [...prev, data]);
    });
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);

    const checkURL = async () => {
      // If user has just been redirected back here
      // from an integration log in, connect to the integration
      const urlParams = new URLSearchParams(window.location.search);
      const state = urlParams.get('state');
      const organisationToAddIntegration = state?.split('_')[1];

      // Handle XERO integration create
      if (state?.includes('xero')) {
        try {
          setLoading(true);
          // const accountId = getAccountId();
          // const currentUser = await getData(`/accounts/${accountId.accountId}`);
          const authCode = urlParams.get('code');
          const body = {
            organisation_id: organisationToAddIntegration,
            xero_code: authCode,
          };
          const response = await postData('/integrations/xero/connect', body);
          setLoading(false);
          if (response.status === 400 || response.status === 500) throw new Error(response.data);
          setMessagebar((prev: any) => ({
            ...prev,
            show: true,
            text: 'Integration successfully added!',
            severity: 'success',
          }));
          // Refresh information on page
          // while setting it to the organisation that was just connected
          handleSelectOrganisation(response.data.organisation);
        } catch (e: any) {
          setMessagebar((prev: any) => ({
            ...prev,
            show: true,
            text: e.message,
            severity: 'error',
          }));
        }
      }
    };
    const fetchData = async () => {
      try {
        // Ensure logged in, refresh tokens
        await getCurrentUser(navigate);

        const accountId = getAccountId();
        const currentUser = await getData(`/accounts/${accountId.accountId}`);
        const orgIntegrations = await getData(
          `/integrations/organisation/${currentUser.data.organisation}`,
        );
        const userOrgData = await getData(`/organisations/${currentUser.data.organisation}`);
        setSubOrganisations(userOrgData.data.child_organisations);
        // Initially selected org is user's org
        setSelectedOrganisation(currentUser.data.organisation);

        // Set data to show in the table
        setDataToDisplay([]);
        orgIntegrations.data.forEach((single: { [key: string]: any }) => {
          const data = {
            id: single.id,
            name: single.name,
            integration_type: single.integration_type,
            organisation: single.organisation,
            status: single.status,
            is_active: single.is_active,
            last_sync: single.last_sync,
            linked_activity_types: single.linked_activity_types,
          };
          setDataToDisplay((prev) => [...prev, data]);
        });

        // Check URL params to see if user was redirected
        // back from connecting to an integration.
        // Placed here to override dataToDisplay and show the previous suborg.
        await checkURL();

        // Load available integration types
        const integrationTypes = await getData('/integrations/types');
        setAvailableIntegrationTypes(integrationTypes.data);

        setLoading(false);
      } catch (error: any) {
        // console.log(error.response);
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  const connectIntegration = async (integrationType: string) => {
    const redirectURI = getServices().xeroRedirectURI;
    if (integrationType === 'xero') {
      window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_CLIENT_ID}&redirect_uri=${redirectURI}&scope=openid profile email offline_access accounting.reports.read accounting.journals.read accounting.settings.read &state=xero_${selectedOrganisation}`;
    } else if (integrationType === 'myob') {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Myob is not yet supported',
        severity: 'error',
      }));
    } else {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Your integration type is not yet supported',
        severity: 'error',
      }));
    }
  };

  const disconnectIntegration = async (organisationId: string, integrationType: string) => {
    setLoading(true);
    try {
      if (integrationType === 'xero') {
        const body = {
          organisation_id: organisationId,
        };
        const response = await postData('/integrations/xero/disconnect', body);
        setLoading(false);
        if (response.status !== 200) {
          throw new Error(response.data);
        } else {
          setMessagebar((prev: any) => ({
            ...prev,
            show: true,
            text: 'Successfully Disconnected!',
            severity: 'success',
          }));
          setShowSingleIntegrationPopup(false);
          handleSelectOrganisation(selectedOrganisation); // Refresh information on page
        }
      } else if (integrationType === 'myob') {
        throw new Error('myob integrations are not supported yet.');
      } else {
        throw new Error('Error when disconnecting integration: integration type not supported.');
      }
    } catch (error: any) {
      setLoading(false);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: error.message,
        severity: 'error',
      }));
    }
  };

  const deleteIntegration = async (integrationId: string) => {
    try {
      const response = await deleteData(`/integrations/${integrationId}`);
      if (response.status === 400) {
        throw new Error(response.data);
      }
      setShowSingleIntegrationPopup(false);
      setShowDeleteIntegrationWarning(false);
      handleSelectOrganisation(selectedOrganisation); // Refresh information on page
    } catch (error: any) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: error.message,
        severity: 'error',
      }));
    }
  };

  const deleteSourceMap = async (sourceMapId: string) => {
    try {
      const response = await deleteData(`/activitysourcemaps/${sourceMapId}`);
      if (response.status === 400) {
        throw new Error(response.data);
      }
      setShowDeleteSourceMapWarning(false);
      setShowSingleIntegrationPopup(false);
      handleSelectOrganisation(selectedOrganisation); // Refresh information on page
    } catch (error: any) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: error.message,
        severity: 'error',
      }));
    }
  };

  const forceSync = async () => {
    // TODO!!!! Need to set this up to handle other types of integrations as well
    try {
      setLoading(true);
      setShowSingleIntegrationPopup(false);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Sync initiated, this may take up to a minute...',
        severity: 'info',
      }));
      const body = {
        integration_id: selectedIntegration.id,
      };
      const response = await postData('/sync/xero', body);
      if (response.status === 400 || response.status === 500) {
        throw new Error(response.data);
      }
      setShowSingleIntegrationPopup(false);
      handleSelectOrganisation(selectedOrganisation); // Refresh information on page
    } catch (error: any) {
      setLoading(false);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: error.message,
        severity: 'error',
      }));
    }
  };

  const handleViewSingleIntegration = async (e: any) => {
    setShowSingleIntegrationPopup(true);
    const orgIntegrations = await getData(`/integrations/organisation/${selectedOrganisation}`);
    const selectedIntegrationItem = orgIntegrations.data.filter(
      // eslint-disable-next-line func-names
      (entry: { [key: string]: any }) => entry.id === e.id,
    )[0];
    setSelectedIntegration(selectedIntegrationItem);
  };

  // Handles event when user selects
  // a sub-organisation in dropdown,
  // displays integrations for that sub-organisation

  const renderIntegrationFrame = async (integrationType = '') => {
    // Renders the content of the add integration frame based on selected integration type
    const renderOutput = [];
    if (integrationType === 'xero') {
      renderOutput.push(
        <div className="flex flex-col items-center">
          <img
            src={xeroSigninButtonImage}
            style={{
              marginTop: '10px',
              width: '200px',
              cursor: 'pointer',
              borderRadius: '10px',
            }}
            onClick={(e: any) => {
              e.preventDefault();
              connectIntegration('xero');
            }}
            alt="logo"
          />
          <br />
          <div
            style={{
              fontSize: '12px',
              maxWidth: '400px',
              margin: '20px auto 0 auto',
            }}
          >
            Note: Only 1 Xero integration is allowed per organisation. If you already have an active
            Xero integration, disconnect it and try again.
          </div>
        </div>,
      );
    } else if (integrationType === 'myob') {
      renderOutput.push(
        <div>MYOB Integrations are not yet supported. Please check back later.</div>,
      );
    } else {
      renderOutput.push(<div>Integration Type not supported</div>);
    }
    ReactDOMClient.createRoot(document.getElementById('integration-frame')).render(renderOutput);
  };

  const retrieveIntegrationLogo = (integrationType: string) => {
    if (integrationType === 'xero') {
      return <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={xeroLogo} alt="Xero Logo" />;
    }
    return <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={errorIcon} alt="Error Icon" />;
  };

  // const title = <div className="card-title">Integrations</div>;

  // TODO!!! Currently specific to XERO integrations only??
  // Might need to adapt when new integrations come in
  const selectTenant = async () => {
    try {
      setLoading(true);
      setShowSingleIntegrationPopup(false);
      const body = {
        integration_id: selectedIntegration.id,
        tenant_id: selectedTenant,
      };
      const response = await postData('/integrations/xero/selecttenant', body);
      if (response.status === 400 || response.status === 500) {
        throw new Error(response.data);
      }
      setShowSingleIntegrationPopup(false);
      handleSelectOrganisation(selectedOrganisation); // Refresh information on page
    } catch (error: any) {
      setLoading(false);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: error.message,
        severity: 'error',
      }));
    }
  };

  return (
    <Card
      sx={{
        margin: '20px',
        borderRadius: '20px',
        padding: '40px',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        opacity: 1,
        filter: 'brightness(1.05)',
        minHeight: 'calc(100% - 50px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className="integrations">
        <HamburgerIcon />
        {/* <Hidden mdUp>
          <Box sx={{ textAlign: "center" }}>{title}</Box>
        </Hidden>
        <Hidden mdDown>{title}</Hidden> */}

        <div className="infos">
          <div className="right-infos">
            <select
              name="options"
              value={selectedOrganisation}
              id="options"
              className="dropdown-options"
              onChange={(e) => handleSelectOrganisation(e.target.value)}
            >
              {subOrganisations.map((row: { [key: string]: any }) => (
                <option value={row.id}>{row.name}</option>
              ))}
            </select>
            <Button
              sx={{ color: 'rgb(100, 100, 100)' }}
              size="large"
              startIcon={<DashboardCustomizeIcon className="start-icons" />}
              onClick={() => setShowAddIntegrationPopup(true)}
            >
              Add Integration
            </Button>
          </div>
        </div>

        {/* Main Table */}
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <i className="fas fa-cloud" style={{ marginRight: '8px' }} />
                  Integration Type
                </StyledTableCell>
                <StyledTableCell>
                  <i className="fas fa-i-cursor" style={{ marginRight: '8px' }} />
                  Integration Name
                </StyledTableCell>
                <StyledTableCell align="right">
                  <i className="fas fa-question-circle" style={{ marginRight: '8px' }} />
                  Status
                </StyledTableCell>
                <StyledTableCell align="right">
                  <i className="fas fa-external-link" style={{ marginRight: '8px' }} />
                  Linked in Inventory
                </StyledTableCell>
                <StyledTableCell align="right">
                  <i className="fas fa-sync" style={{ marginRight: '4px' }} />
                  Last Sync
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataToDisplay.map((row: DataToDisplayInterface) => (
                <StyledTableRow
                  key={row.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleViewSingleIntegration(row)}
                >
                  <StyledTableCell align="right">
                    <div style={{ width: '30px', margin: 'auto' }}>
                      {retrieveIntegrationLogo(row.integration_type)}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.name}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.is_active ? `Active, ${row.status}` : `Inactive, ${row.status}`}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.linked_activity_types.length > 0
                      ? `${row.linked_activity_types.length} linked emission type(s)`
                      : 'No linked items found'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.last_sync !== '' ? dateConverter(row.last_sync) : 'Not synced yet'}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Add Integration Dialog */}
        <DialogBox show={showAddIntegrationPopup} setShow={setShowAddIntegrationPopup}>
          <div style={{ padding: '50px', textAlign: 'center' }}>
            <div style={{ fontWeight: 'bold', fontSize: '24px', color: '#61B9C3' }}>
              Add Integration
            </div>
            <div>
              <div style={{ fontSize: '14px' }}>Select an Integration Type to get started!</div>
              <select
                className="px-3 py-1 dropdown-options bg-transparent rounded-lg border-2 border-customPrimaryMain text-customPrimaryMain"
                style={{ marginTop: '30px' }}
                id="select-integration-type"
                onChange={(e) => renderIntegrationFrame(e.target.value)}
              >
                <option value="" disabled selected>
                  Select Integration Type
                </option>
                {availableIntegrationTypes.map((entry: string) => (
                  <option value={entry}>{entry}</option>
                ))}
              </select>
            </div>
            <div className="integration-frame" id="integration-frame" />
            <ButtonFilled
              style={{
                marginTop: '20px',
                width: '130px',
                backgroundColor: '#C36670',
              }}
              handleSubmit={() => setShowAddIntegrationPopup(false)}
            >
              Cancel
            </ButtonFilled>
          </div>
        </DialogBox>

        {/* Select single integration dialog */}
        <DialogBox show={showSingleIntegrationPopup} setShow={setShowSingleIntegrationPopup}>
          <div style={{ padding: '50px', textAlign: 'center' }}>
            <div style={{ width: '150px', margin: '30px auto 20px auto' }}>
              {retrieveIntegrationLogo(selectedIntegration.integration_type)}
            </div>
            <h2>{selectedIntegration.name}</h2>
            {/* XERO specific issue, if multiple tenants
            found, we need to remove all unwanted tenants in DB for the user */}
            {'tenants' in selectedIntegration.attributes
            && selectedIntegration.attributes.tenants.length > 1 ? (
              <div style={{ backgroundColor: 'mistyrose', padding: '20px' }}>
                <div
                  style={{
                    fontWeight: 'bold',
                    color: 'red',
                    marginBottom: '5px',
                  }}
                >
                  "Warning: Multiple tenants(organisations) were found for your integration. Please
                  select one below to continue activating your integration."
                </div>
                <select
                  name="options"
                  id="options"
                  className="dropdown-options"
                  onChange={(e) => {
                    setSelectedTenant(e.target.value);
                  }}
                >
                  <option selected value="" disabled>
                    Select an organisation
                  </option>
                  {selectedIntegration.attributes.tenants.map((row: { [key: string]: any }) => (
                    <option value={row.tenantId}>{row.tenantName}</option>
                  ))}
                </select>
                <ButtonFilled
                  style={{ margin: '15px 0 0 20px', width: '230px' }}
                  handleSubmit={() => selectTenant()}
                >
                  Activate & Connect Integration
                </ButtonFilled>
              </div>
              ) : null}

            <table style={{ width: '100%', color: '#60656d', marginTop: '20px' }}>
              <tr>
                <td
                  style={{
                    textAlign: 'right',
                    width: '50%',
                    paddingRight: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Status
                </td>
                <td style={{ textAlign: 'left' }}>
                  {selectedIntegration.is_active
                    ? `Active, ${selectedIntegration.status}`
                    : `Inactive, ${selectedIntegration.status}`}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: 'right',
                    width: '50%',
                    paddingRight: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Last Sync
                </td>
                <td style={{ textAlign: 'left' }}>
                  {selectedIntegration.last_sync !== ''
                    ? dateConverter(selectedIntegration.last_sync)
                    : 'Not synced yet'}
                  {selectedIntegration.is_active && (
                    <button type="button" className="force-sync-button" onClick={() => forceSync()}>
                      Force Sync
                    </button>
                  )}
                </td>
              </tr>
            </table>

            <div
              style={{
                color: '#60656d',
                margin: '20px auto 0 auto',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              Linked Emission Types
            </div>
            <table style={{ color: '#60656d', margin: '10px auto 0 auto' }}>
              {selectedIntegration.linked_activity_types.length > 0 ? (
                <tr style={{ backgroundColor: 'lightgray' }}>
                  <td
                    style={{
                      textAlign: 'right',
                      fontWeight: 'bold',
                      padding: '0 10px 0 10px',
                    }}
                  >
                    Target
                  </td>
                  <td />
                  <td
                    style={{
                      textAlign: 'left',
                      fontWeight: 'bold',
                      padding: '0 10px 0 10px',
                    }}
                  >
                    Source
                  </td>
                  <td
                    style={{
                      textAlign: 'left',
                      fontWeight: 'bold',
                      padding: '0 10px 0 10px',
                    }}
                  >
                    Unlink
                  </td>
                </tr>
              ) : null}
              {selectedIntegration.linked_activity_types.length > 0
                ? selectedIntegration.linked_activity_types.map((entry) => (
                  <tr>
                    <td
                      style={{
                        textAlign: 'right',
                        fontWeight: 'bold',
                        padding: '0 10px 0 10px',
                      }}
                    >
                      {entry.activity_type_name}
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        padding: '0 10px 0 10px',
                      }}
                    >
                      ←
                    </td>
                    <td style={{ textAlign: 'left', padding: '0 10px 0 10px' }}>
                      {entry.source_alias}
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        padding: '0 10px 0 10px',
                      }}
                    >
                      <i
                        className="fa-solid fa-circle-minus"
                        onClick={() => {
                          setSelectedSourceMapToDelete(entry);
                          setShowDeleteSourceMapWarning(true);
                        }}
                      />
                    </td>
                  </tr>
                ))
                : 'No linked items found. Link emission types to integrations in your Inventory page.'}
            </table>

            <div
              style={{
                display: 'flex',
                gap: '5%',
                padding: '40px 80px 20px 80px',
              }}
            >
              <ButtonFilled handleSubmit={() => setShowSingleIntegrationPopup(false)}>
                Close
              </ButtonFilled>
              {selectedIntegration.is_active ? (
                <ButtonFilled
                  handleSubmit={() => disconnectIntegration(
                    selectedIntegration.organisation,
                    selectedIntegration.integration_type,
                  )}
                  style={{ backgroundColor: '#C36670' }}
                >
                  Disconnect Integration
                </ButtonFilled>
              ) : (
                <ButtonFilled
                  handleSubmit={() => connectIntegration(selectedIntegration.integration_type)}
                  style={{ backgroundColor: '#21d980' }}
                >
                  Reconnect Integration
                </ButtonFilled>
              )}
              <ButtonFilled
                style={{ backgroundColor: '#C36670' }}
                handleSubmit={() => setShowDeleteIntegrationWarning(true)}
              >
                Delete Integration
              </ButtonFilled>
            </div>
          </div>
        </DialogBox>

        {/* Delete integration warning dialog */}
        <DialogBox show={showDeleteIntegrationWarning} setShow={setShowDeleteIntegrationWarning}>
          <div style={{ padding: '100px 50px', textAlign: 'center' }}>
            <h2>Are you sure you want to delete this integration?</h2>
            {' '}
            <br />
            <p>
              This integration, along with all the links your organisation has created between this
              integration and its emission types will be deleted permanently. Activities that have
              been created in the past will not be removed.
            </p>
            <p>
              If you intend to temporarily disconnect this integration or change tenants/entities,
              please use the "Disconnect Integration" button.
            </p>
            <div
              style={{
                display: 'flex',
                gap: '5%',
                padding: '30px 80px 20px 80px',
              }}
            >
              <ButtonFilled handleSubmit={() => setShowDeleteIntegrationWarning(false)}>
                Cancel
              </ButtonFilled>
              <ButtonFilled
                style={{ backgroundColor: '#C36670' }}
                handleSubmit={() => deleteIntegration(selectedIntegration.id)}
              >
                I understand, proceed
              </ButtonFilled>
            </div>
          </div>
        </DialogBox>

        {/* Delete sourcemap (linked emission types) warning dialog */}
        <DialogBox show={showDeleteSourceMapWarning} setShow={setShowDeleteSourceMapWarning}>
          <div style={{ padding: '100px 50px', textAlign: 'center' }}>
            <h2>Are you sure you want to delete this integration link?</h2>
            {' '}
            <br />
            <h2 style={{ backgroundColor: 'lightgray', padding: '10px' }}>
              {selectedSourceMapToDelete.activity_type_name}
              {' '}
              ←
              {' '}
              {selectedSourceMapToDelete.source_alias}
            </h2>
            {' '}
            <br />
            <p>
              Deleting this integration link will NOT remove activities that were created with it in
              the past. To delete activities, please go to your Inventory page, choose your desired
              category, and delete individual activities.
            </p>
            <p>
              You may also restore this link on your Inventory page, by adding an existing
              integration under desired categories.
            </p>
            <div
              style={{
                display: 'flex',
                gap: '5%',
                padding: '30px 80px 20px 80px',
              }}
            >
              <ButtonFilled handleSubmit={() => setShowDeleteSourceMapWarning(false)}>
                Cancel
              </ButtonFilled>
              <ButtonFilled
                style={{ backgroundColor: '#C36670' }}
                handleSubmit={() => deleteSourceMap(selectedSourceMapToDelete.activity_source_map)}
              >
                I understand, proceed
              </ButtonFilled>
            </div>
          </div>
        </DialogBox>
      </div>
    </Card>
  );
}

export default Integrations;
