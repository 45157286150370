/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState, useContext } from 'react';
import './Team.scss';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import NoteIcon from '@mui/icons-material/Note';
import {
  Box,
  Card,
  Chip,
  Dialog,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ButtonFilled } from '../../../components/Button/Button';
import FormField from '../../../components/FormField/FormField';
import {
  emailIsValid,
  dateConverter,
  postData,
  getData,
  getAccountId,
  deleteData,
  StyledTableCell,
  StyledTableRow,
  randomRGB,
} from '../../../utils/utils';
import { DataContext } from '../../../context/DataContext';
import ExportUsersCSV from './ExportUsersCSV';
import {
  defaultErrors, defaultUsers, defaultOrg, defaultSelectedUser,
} from './defaults';
import DialogBox from '../../../components/DialogBox/DialogBox';
import TabLinks from '../../../components/TabLinks/TabLinks';
import Dropdown from '../../../components/Dropdown/Dropdown';
import useAxios from '../../../hooks/useAxios';
import PlacesAutocomplete from '../../../components/AddressField/AddressField';
import backgroundImage from '../../../images/FaintBackground.jpg';
import HamburgerIcon from '../../../components/HamburgerIcon/HamburgerIcon';
import Orders from './Orders';

const tabDetails = [
  {
    name: 'People',
    value: 'people',
    active: true,
  },
  {
    name: 'Organisation',
    value: 'organisation',
    active: false,
  },
];

interface MemberType {
  created_at: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  license: string;
  role: string;
}

interface OrgInterface {
  active_activity_set: string;
  activity_sets: string[];
  address: any;
  admin_members: string[];
  child_ids: string[];
  child_organisations: object[];
  created_at: string;
  created_by: string;
  current_invite_key: string;
  floor_space: number;
  id: string;
  identifier: string;
  identifier_type: string;
  invite_key_exp: string;
  is_deleted: boolean;
  member_list: MemberType[];
  merged_from: string[];
  name: string;
  number_of_staff: number;
  parent_id: string;
  region: string;
  turnover: number;
  updated_at: string;
}

interface DataToDisplayInterface {
  id: string;
  name: string;
  email: string;
  organisation: string;
  license: string;
  created_at: string;
  is_evalue8_team: boolean;
}

interface valuesType {
  id: number;
  title: string;
  shortForm: string;
}

function RecursiveOrgs(props: any) {
  const {
    parent, childrens, handleAddSubOrgButton, handleRemoveSubOrgButton,
  } = props;
  const [parentOrgs, setParentOrgs] = useState([{ id: '', name: '' }]);
  const [childOrgs, setChildOrgs] = useState([]);

  useEffect(() => {
    if (!childrens) {
      // Entering first time
      const getParentOrgs = parent.filter((single: any) => single.parent_id === '');
      const getParentOrgsWithColors = getParentOrgs.map((child: any) => ({
        ...child,
        color: '#61b9c3',
      }));
      setParentOrgs(getParentOrgsWithColors);

      const getChildOrgs = parent.filter((single: any) => single.parent_id !== '');
      setChildOrgs(getChildOrgs);
    } else {
      // Entering second time and further
      const getParentOrgs = childrens.filter((child: any) => child.parent_id === parent.id);
      const randomColor = randomRGB();
      const getParentOrgsWithColors = getParentOrgs.map((child: any) => ({
        ...child,
        color: `rgb(${randomColor})`,
      }));
      setParentOrgs(getParentOrgsWithColors);

      const getChildOrgs = childrens.filter((single: any) => single.parent_id !== parent.id);
      setChildOrgs(getChildOrgs);
    }
  }, [parent]);

  return (
    <>
      {parentOrgs.map((single: any) => (
        <Box key={single.id} sx={{ marginLeft: `${single.parent_id === '' ? '0px' : '35px'}` }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              borderLeft: `3px solid ${single.color}`,
              padding: '3px',
              paddingLeft: '10px',
              margin: '10px',
            }}
          >
            <Box>{single.name}</Box>

            <AddCircleIcon
              sx={{ color: '#61b9c3', fontSize: '18px', cursor: 'pointer' }}
              onClick={() => handleAddSubOrgButton(single)}
            />
            {single.parent_id !== '' && (
              <RemoveCircleIcon
                sx={{ color: '#d9534f', fontSize: '18px', cursor: 'pointer' }}
                onClick={() => handleRemoveSubOrgButton(single)}
              />
            )}
          </Box>
          {childOrgs.length > 0 && (
            <RecursiveOrgs
              parent={single}
              childrens={childOrgs}
              handleAddSubOrgButton={handleAddSubOrgButton}
              handleRemoveSubOrgButton={handleRemoveSubOrgButton}
            />
          )}
        </Box>
      ))}
    </>
  );
}

function Team() {
  const { loading, setLoading, setMessagebar } = useContext(DataContext);
  const [currentUser, setCurrentUser] = useState(defaultUsers);
  const [currentOrg, setCurrentOrg] = useState<OrgInterface>(defaultOrg);
  const [childOrganisations, setChildOrganisations] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState<DataToDisplayInterface[]>([]);
  const [showInviteMemberPopup, setShowInviteMemberPopup] = useState(false);
  const [showPaymentsPopup, setShowPaymentsPopup] = useState(false);

  const [inviteEmail, setInviteEmail] = useState('');
  const [errors, setErrors] = useState(defaultErrors);
  const [showEditMemberPopup, setShowEditMemberPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState<DataToDisplayInterface>(defaultSelectedUser);
  const [totalLicenses, setTotalLicenses] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [tabValues, setTabValues] = useState(tabDetails);
  const [orgDetails, setOrgDetails] = useState({
    orgName: '',
    region: '',
    address: {},
    identifier: '',
    identifier_type: '',
    orgFloorSpace: '',
    orgTurnover: '',
    orgNoOfStaff: '',
  });
  const { data: regions } = useAxios('/regions');
  const [regionsLevel0, setRegionsLevel0] = useState<valuesType[]>([]);
  const [regionsLevel1, setRegionsLevel1] = useState<valuesType[]>([]);
  const [regionsLevel2, setRegionsLevel2] = useState<valuesType[]>([]);
  const [showAddSubOrg, setShowAddSubOrg] = useState(false);
  const [selectedParentOrg, setSelectedParentOrg] = useState({ id: '' });
  const [selectedOrgToDelete, setSelectedOrgToDelete] = useState({ id: '' });
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const accountId = getAccountId();

        const currentUserResponse = await getData(`/accounts/${accountId.accountId}`);
        setCurrentUser(currentUserResponse.data);

        const currentOrgResponse = await getData(
          `/organisations/${currentUserResponse.data.organisation}`,
        );
        setCurrentOrg(currentOrgResponse.data);
        setChildOrganisations(currentOrgResponse.data.child_organisations);

        // Set data to show in the table
        setDataToDisplay([]);
        currentOrgResponse.data.member_list.forEach(async (single: MemberType) => {
          const singleUserInThisCycle = await getData(`/accounts/${single.id}`);
          // Hiding Evalue8 Admins from Team page
          const { is_evalue8_team: isEvalue8Team } = singleUserInThisCycle.data;

          const data = {
            id: single.id,
            name: `${single.first_name} ${single.last_name}`,
            email: single.email,
            organisation: currentOrgResponse.data.name,
            license: single.license,
            created_at: single.created_at,
            is_evalue8_team: isEvalue8Team,
          };
          setDataToDisplay((prev) => [...prev, data]);
        });

        // Check if a user is admin
        if (currentOrgResponse.data.admin_members.includes(currentUserResponse.data.id)) {
          setIsAdmin(true);

          const totalLicensesResponse = await getData(
            `/licenses/organisation/${currentOrgResponse.data.id}`,
          );
          setTotalLicenses(totalLicensesResponse.data);
        }

        // Get regions
        const regionsResponse = await getData('/regions');
        const regions0 = regionsResponse.data.filter((region: any) => region.level === 0);
        const removedUnderscoreInRegion = regions0.map((region: any) => ({
          ...region,
          shortForm: region.short_form,
        }));
        setRegionsLevel0(removedUnderscoreInRegion);

        setLoading(false);
      } catch (error: any) {
        // console.log(error.response);
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  const fieldsValidations = () => {
    setErrors(defaultErrors);
    if (inviteEmail.length === 0) {
      setErrors((prev) => ({
        ...prev,
        emailError: 'Email field must not be empty',
      }));
      return false;
    }
    if (!emailIsValid(inviteEmail)) {
      setErrors((prev) => ({ ...prev, emailError: 'Invalid email format' }));
      return false;
    }
    return true;
  };

  const handleInvite = async (e: any) => {
    e.preventDefault();
    if (!fieldsValidations()) {
      setLoading(false);
      return;
    }

    setShowInviteMemberPopup(false);
    setLoading(true);

    try {
      const body = {
        organisation_id: currentOrg.id,
        invite_email: inviteEmail,
      };
      await postData('/organisations/invitetoorganisation', body);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Invitation successfully sent',
        severity: 'success',
      }));
      setInviteEmail('');
      setLoading(false);
    } catch (error: any) {
      // console.log("::::ERROR::::", error.response.data);
      setLoading(false);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Unknown error occured. Please try again.',
        severity: 'error',
      }));
      setInviteEmail('');
    }
  };

  const handleEditUser = async (user: DataToDisplayInterface) => {
    setSelectedUser(user);
    setShowEditMemberPopup(true);
  };

  const getTotalLicenses = async () => {
    const totalLicensesResponse = await getData(`/licenses/organisation/${currentOrg.id}`);
    return totalLicensesResponse.data;
  };

  const getUsedLicences = () => {
    const usersWithLicences = dataToDisplay.filter((user) => user.license !== '');
    const alreadyUsedLicences = usersWithLicences.map((userWithLicense) => userWithLicense.license);
    return alreadyUsedLicences;
  };

  const issueLicense = async (user: any) => {
    const totalLicensesResponse = await getTotalLicenses();
    const assignedLicense = getUsedLicences();
    const availableLicenses = totalLicensesResponse.filter(
      (singleLicense: any) => !assignedLicense.includes(singleLicense.id),
    );
    if (availableLicenses.length === 0) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'All licenses are already in use',
        severity: 'error',
      }));
      return;
    }

    setLoading(true);
    try {
      const body = {
        organisation_id: currentOrg.id,
        assign_to_user_id: user.id,
        license_type_id: availableLicenses[0].license_type,
        license_id: availableLicenses[0].id,
      };
      // ASSIGN
      await postData('/licenses/assign', body);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'License assign successful',
        severity: 'success',
      }));
      const mappedUsers = dataToDisplay.map((member) => {
        if (member.id === user.id) {
          return { ...member, license: availableLicenses[0].id };
        }
        return member;
      });
      setDataToDisplay(mappedUsers);
      setLoading(false);
    } catch (error: any) {
      // console.log(error.response.data);
      setLoading(false);
    }
    setShowEditMemberPopup(false);
  };

  const revokeLicense = async (user: any) => {
    if (currentUser.id === user.id) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'You can not revoke your own license',
        severity: 'error',
      }));
      return;
    }

    setLoading(true);
    try {
      const body = {
        license_id: user.license,
        user_id: user.id,
      };
      // REVOKE
      await postData('/licenses/revoke', body);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'License successfully revoked',
        severity: 'success',
      }));
      const mappedUsers = dataToDisplay.map((member) => {
        if (member.id === user.id) {
          return { ...member, license: '' };
        }
        return member;
      });
      setDataToDisplay(mappedUsers);
      setLoading(false);
    } catch (error: any) {
      // console.log(error.response.data);
      setLoading(false);
    }
    setShowEditMemberPopup(false);
  };

  const handleEditUserDelete = async (user: any) => {
    if (currentUser.id === user.id) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'You can not remove yourself from an organisation',
        severity: 'error',
      }));
      setShowEditMemberPopup(false);
      return;
    }

    setLoading(true);
    try {
      const body = {
        user_id: user.id,
        organisation_id: currentOrg.id,
      };
      await postData('/organisations/removeuserfromorganisation', body);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'User successfully removed',
        severity: 'success',
      }));
      const mappedUsers = dataToDisplay.filter((member: any) => member.id !== user.id);
      setDataToDisplay(mappedUsers);
      setLoading(false);
    } catch (error: any) {
      // console.log(error.response);
      setLoading(false);
    }
    setShowEditMemberPopup(false);
  };

  const promptUserPasswordReset = async () => {
    setShowEditMemberPopup(false);
    setLoading(true);
    try {
      const body = {
        email: selectedUser.email,
      };
      const resetResponse = await postData('/accounts_reset_password/reset_password_request', body);
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: resetResponse.data,
        severity: 'success',
      }));
      setLoading(false);
    } catch (error: any) {
      // console.log(error.response);
      setLoading(false);
    }
  };

  // Handle Team-Organisation Tab
  const handleTab = (params: any) => {
    // eslint-disable-next-line max-len
    const alteredData = tabValues.map((single) => (single.value !== params ? { ...single, active: false } : { ...single, active: true }));
    setTabValues(alteredData);
  };
  const handleDropdownLevel0 = (e: any) => {
    const regions1 = regions.filter(
      (region: any) => region.level === 1 && region.parent === e.target.value,
    );
    const removedUnderscoreInRegion = regions1.map((region: any) => ({
      ...region,
      shortForm: region.short_form,
    }));
    setRegionsLevel1(removedUnderscoreInRegion);
    setOrgDetails((prevState) => ({ ...prevState, region: e.target.value }));
  };
  const handleDropdownLevel1 = (e: any) => {
    const regions2 = regions.filter(
      (region: any) => region.level === 2 && region.parent === e.target.value,
    );
    const removedUnderscoreInRegion = regions2.map((region: any) => ({
      ...region,
      shortForm: region.short_form,
    }));
    setRegionsLevel2(removedUnderscoreInRegion);
    setOrgDetails((prevState) => ({ ...prevState, region: e.target.value }));
  };
  const handleDropdownLevel2 = (e: any) => {
    setOrgDetails((prevState) => ({ ...prevState, region: e.target.value }));
  };

  const handleDropdownIdentifierType = (e: any) => {
    if (e.target.value === '1') {
      setOrgDetails((prevState) => ({
        ...prevState,
        identifier_type: 'Australian Business Number',
      }));
    } else if (e.target.value === '2') {
      setOrgDetails((prevState) => ({
        ...prevState,
        identifier_type: 'Australian Company Number',
      }));
    }
  };
  // eslint-disable-next-line no-shadow
  const handleAddSubOrgButton = (selectedParentOrg: any) => {
    setSelectedParentOrg(selectedParentOrg);
    setShowAddSubOrg(true);
  };

  const handleCreateSubOrgButton = async () => {
    const body = {
      name: orgDetails.orgName,
      region: orgDetails.region,
      address: orgDetails.address,
      identifier: orgDetails.identifier,
      identifier_type: orgDetails.identifier_type,
      floor_space: orgDetails.orgFloorSpace,
      turnover: orgDetails.orgTurnover,
      number_of_staff: orgDetails.orgNoOfStaff,
    };
    const createSubOrg = await postData(
      `/organisations/addsuborganisation/${selectedParentOrg.id}`,
      body,
    );

    if (createSubOrg.status !== 201) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: createSubOrg.data,
        severity: 'error',
      }));
      return;
    }
    setOrgDetails((prev: any) => ({
      ...prev,
      orgName: '',
      address: {},
      identifier: '',
      identifier_type: '',
      orgFloorSpace: '',
      orgTurnover: '',
      orgNoOfStaff: '',
    }));
    setRegionsLevel0([]);
    setRegionsLevel1([]);
    setRegionsLevel2([]);
    setMessagebar((prev: any) => ({
      ...prev,
      show: true,
      text: 'Sub-organisation successfully created',
      severity: 'success',
    }));
    setShowAddSubOrg(false);
    // setTimeout(function () {
    //   window.location.reload();
    // }, 500);
    const currentOrgResponse = await getData(`/organisations/${currentUser.organisation}`);
    setCurrentOrg(currentOrgResponse.data);
    setChildOrganisations(currentOrgResponse.data.child_organisations);
  };

  const handleRemoveSubOrgButton = async (selectedOrg: any) => {
    setSelectedOrgToDelete(selectedOrg);
    setShowConfirmDialog(true);
  };

  const handleRemoveSubOrg = async () => {
    setShowConfirmDialog(false);
    const deleteOrg = await deleteData(`/organisations/${selectedOrgToDelete.id}`);
    if (deleteOrg.status === 404) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: deleteOrg.data,
        severity: 'error',
      }));
      return;
    }
    setMessagebar((prev: any) => ({
      ...prev,
      show: true,
      text: 'Organisation successfully deleted',
      severity: 'success',
    }));

    // setTimeout(function () {
    //   window.location.reload();
    // }, 500);
    const currentOrgResponse = await getData(`/organisations/${currentUser.organisation}`);
    setCurrentOrg(currentOrgResponse.data);
    setChildOrganisations(currentOrgResponse.data.child_organisations);
  };

  const title = <div className="card-title">Team</div>;

  return (
    <>
      {!loading && (
        <Card
          sx={{
            margin: '20px',
            borderRadius: '20px',
            padding: '40px',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            opacity: 1,
            filter: 'brightness(1.05)',
            minHeight: 'calc(100% - 50px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className="team">
            <HamburgerIcon />
            <Hidden mdUp>
              <Box sx={{ textAlign: 'center' }}>{title}</Box>
            </Hidden>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
                marginBottom: '20px',
              }}
            >
              <Hidden mdDown>{title}</Hidden>
              <Box sx={{ marginBottom: '8px' }}>
                <TabLinks data={tabValues} func={handleTab} />
              </Box>
            </Box>

            {/* For People tab */}
            <Box sx={{ display: tabValues[0].active ? 'block' : 'none' }}>
              <div className="infos">
                <div>
                  {isAdmin && (
                    <>
                      Licences used:
                      {' '}
                      {getUsedLicences().length}
                      /
                      {totalLicenses.length}
                    </>
                  )}
                </div>
                {isAdmin && (
                  <div className="right-infos">
                    <Button
                      sx={{ color: 'rgb(100, 100, 100)' }}
                      size="large"
                      startIcon={<DashboardCustomizeIcon className="start-icons" />}
                      onClick={() => setShowInviteMemberPopup(true)}
                    >
                      Invite a member
                    </Button>
                    <ExportUsersCSV currentOrg={currentOrg} />
                    <Button
                      sx={{ color: 'rgb(100, 100, 100)' }}
                      size="large"
                      startIcon={<NoteIcon className="start-icons" />}
                      onClick={() => setShowPaymentsPopup(true)}
                    >
                      Orders
                    </Button>
                  </div>
                )}
              </div>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <i className="fas fa-user" style={{ marginRight: '8px' }} />
                        Name
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <i className="fas fa-envelope" style={{ marginRight: '8px' }} />
                        Email
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <i className="fas fa-th" style={{ marginRight: '8px' }} />
                        Organisation
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <i className="fas fa-file-alt" style={{ marginRight: '4px' }} />
                        License
                      </StyledTableCell>
                      {currentOrg.admin_members.includes(currentUser.id) && (
                        <>
                          <StyledTableCell align="right">
                            <i className="fas fa-file-alt" style={{ marginRight: '8px' }} />
                            Action
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <i className="fas fa-pencil-alt" style={{ marginRight: '8px' }} />
                            Edit
                          </StyledTableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataToDisplay
                      .filter((single: DataToDisplayInterface) => !single.is_evalue8_team)
                      .map((row: DataToDisplayInterface) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell align="right">{row.name}</StyledTableCell>
                          <StyledTableCell align="right">{row.email}</StyledTableCell>
                          <StyledTableCell align="right">{row.organisation}</StyledTableCell>

                          <StyledTableCell align="right">
                            {row.license ? <>Default license</> : <>None</>}
                          </StyledTableCell>
                          {isAdmin && (
                            <>
                              <StyledTableCell align="right">
                                {row.license ? (
                                  <Chip
                                    icon={<CloseIcon />}
                                    label="Revoke license"
                                    size="small"
                                    sx={{ cursor: 'pointer', color: '#495057' }}
                                    onClick={() => revokeLicense(row)}
                                  />
                                ) : (
                                  <Chip
                                    icon={<DoneIcon style={{ fill: '#fff' }} />}
                                    label="Assign license"
                                    size="small"
                                    sx={{
                                      cursor: 'pointer',
                                      backgroundColor: '#61b9c3',
                                      color: '#fff',
                                    }}
                                    onClick={() => issueLicense(row)}
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <i
                                  style={{ cursor: 'pointer' }}
                                  className="fas fa-pencil-alt"
                                  onClick={() => handleEditUser(row)}
                                />
                              </StyledTableCell>
                            </>
                          )}
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            {/* For Organisation Tab */}
            <Box sx={{ display: tabValues[1].active ? 'block' : 'none' }}>
              <RecursiveOrgs
                parent={childOrganisations}
                handleAddSubOrgButton={handleAddSubOrgButton}
                handleRemoveSubOrgButton={handleRemoveSubOrgButton}
              />
            </Box>
          </div>
        </Card>
      )}
      <DialogBox show={showInviteMemberPopup} setShow={setShowInviteMemberPopup}>
        <div style={{ padding: '50px', textAlign: 'center' }}>
          <div style={{ fontWeight: 'bold', fontSize: '25px', color: '#61B9C3' }}>
            Invite a member
          </div>
          <div style={{ marginTop: '30px' }}>
            <p style={{ width: '80%', fontSize: '15px', margin: '30px auto' }}>
              This is where you invite a new member to your team!
              {' '}
              <br />
              <br />
              {' '}
              Enter their email address below and submit. They will receive an email with an
              invite key, which can be used to join your organisation at the "Join Organisation"
              page. You will see their details listed here after they join your organisation.
            </p>
            <form
              style={{ width: '350px', margin: 'auto' }}
              onSubmit={(e) => handleInvite(e)}
              autoComplete="off"
            >
              <FormField
                error={errors.emailError}
                label="Email"
                type="text"
                handleField={(e) => setInviteEmail(e.target.value)}
              />
              <br />
              <ButtonFilled handleSubmit={(e) => handleInvite(e)}>Invite</ButtonFilled>
            </form>
          </div>
        </div>
      </DialogBox>
      <DialogBox show={showPaymentsPopup} setShow={setShowPaymentsPopup}>
        <Orders />
      </DialogBox>
      {selectedUser && (
        <DialogBox show={showEditMemberPopup} setShow={setShowEditMemberPopup}>
          {/* <div style={{ fontSize: "24px", color: "rgb(96, 101, 109)" }}>Editing Member</div> */}
          <div
            className="flex flex-col items-center"
            style={{ padding: '50px', textAlign: 'center' }}
          >
            <img
              style={{ clipPath: 'circle()', width: '100px', margin: '30px 0' }}
              src="https://picsum.photos/200/200"
              alt="User"
            />
            <Chip
              icon={<VpnKeyIcon style={{ fill: '#fff' }} />}
              label="Prompt password reset"
              size="small"
              sx={{
                padding: '10px 5px',
                cursor: 'pointer',
                backgroundColor: '#61b9c3',
                color: '#fff  ',
              }}
              onClick={() => promptUserPasswordReset()}
            />
            <br />
            <br />
            <h2>{selectedUser.name}</h2>
            <p>{currentOrg.name}</p>
            <p>
              Joined &nbsp;
              {dateConverter(selectedUser.created_at)}
            </p>
            <p>{selectedUser.email}</p>

            <div
              style={{
                display: 'flex',
                gap: '5%',
                paddingTop: '20px',
                width: '400px',
                margin: 'auto',
              }}
            >
              <ButtonFilled handleSubmit={() => setShowEditMemberPopup(false)}>Ok</ButtonFilled>
              <ButtonFilled
                handleSubmit={() => handleEditUserDelete(selectedUser)}
                style={{ backgroundColor: '#C36670' }}
              >
                Remove Member
              </ButtonFilled>
            </div>
          </div>
        </DialogBox>
      )}
      <DialogBox show={showAddSubOrg} setShow={setShowAddSubOrg}>
        <Box sx={{ padding: '50px', minHeight: '500px' }}>
          <Box className="card-title" sx={{ fontSize: '30px' }}>
            Create a sub organisation
          </Box>

          <Box sx={{ marginTop: '20px' }}>
            <FormField
              error=""
              label="Sub Organisation name"
              value={orgDetails.orgName}
              type="text"
              handleField={(e) => setOrgDetails((prevState) => ({
                ...prevState,
                orgName: e.target.value,
              }))}
            />

            <Dropdown
              label="Region"
              values={regionsLevel0}
              handleField={(e) => handleDropdownLevel0(e)}
            />
            {regionsLevel1.length !== 0 && (
              <Dropdown
                label="Sub-Region"
                values={regionsLevel1}
                handleField={(e) => handleDropdownLevel1(e)}
              />
            )}
            {regionsLevel2.length !== 0 && (
              <Dropdown
                label="Sub-sub-Region"
                values={regionsLevel2}
                handleField={(e) => handleDropdownLevel2(e)}
              />
            )}

            <PlacesAutocomplete
              error=""
              handleField={(description: any) => setOrgDetails((prevState) => ({
                ...prevState,
                address: description,
              }))}
            />

            <Dropdown
              label="Identifier Type"
              values={[
                {
                  id: 1,
                  shortForm: 'ABN',
                  title: 'Australian Business Number',
                },
                {
                  id: 2,
                  shortForm: 'ACN',
                  title: 'Australian Company Number',
                },
              ]}
              handleField={(e) => handleDropdownIdentifierType(e)}
            />

            <FormField
              error=""
              label="Identifier"
              value={orgDetails.identifier}
              type="text"
              handleField={(e) => setOrgDetails((prevState) => ({
                ...prevState,
                identifier: e.target.value,
              }))}
            />
            <FormField
              error=""
              label="Floor space (in m²)"
              value={orgDetails.orgFloorSpace}
              type="text"
              handleField={(e) => setOrgDetails((prevState) => ({
                ...prevState,
                orgFloorSpace: e.target.value,
              }))}
            />
            <FormField
              error=""
              label="Turnover"
              value={orgDetails.orgTurnover}
              type="text"
              handleField={(e) => setOrgDetails((prevState) => ({
                ...prevState,
                orgTurnover: e.target.value,
              }))}
            />
            <FormField
              error=""
              label="No. of Staff"
              value={orgDetails.orgNoOfStaff}
              type="text"
              handleField={(e) => setOrgDetails((prevState) => ({
                ...prevState,
                orgNoOfStaff: e.target.value,
              }))}
            />
            <Box sx={{ marginTop: '40px' }}>
              <ButtonFilled handleSubmit={() => handleCreateSubOrgButton()}>
                Create now
              </ButtonFilled>
            </Box>
          </Box>
        </Box>
      </DialogBox>
      {/* Confirm YES/NO Dialog */}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        PaperProps={{
          style: {
            borderRadius: '10px',
          },
        }}
      >
        <div style={{ padding: '50px' }}>
          <div className="card-title" style={{ fontSize: '30px', textAlign: 'center' }}>
            Are you sure you want to delete?
          </div>
          <br />
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Chip
              icon={<CloseIcon style={{ fill: '#fff' }} />}
              label="Cancel"
              size="small"
              sx={{ cursor: 'pointer', backgroundColor: '#f55', color: '#fff' }}
              onClick={() => setShowConfirmDialog(false)}
            />
            <Chip
              icon={<DoneIcon style={{ fill: '#fff' }} />}
              label="Confirm"
              size="small"
              sx={{
                cursor: 'pointer',
                backgroundColor: '#61b9c3',
                color: '#fff',
              }}
              onClick={() => handleRemoveSubOrg()}
            />
          </Box>
        </div>
      </Dialog>
    </>
  );
}

export default Team;
