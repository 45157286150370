/* eslint-disable import/prefer-default-export */
import { LicenseType } from '../interfaces';

const emptyLicense: LicenseType = {
  id: '',
  name: '',
  description: '',
  currency: '',
  price: 0,
  region: '',
  minStaff: 0,
  maxStaff: 0,
  salesPoints: [],
};

export { emptyLicense };
