/* eslint-disable react/jsx-props-no-spreading */
import { Button } from '@mui/material';
import React from 'react';
import { CSVLink } from 'react-csv';
import DescriptionIcon from '@mui/icons-material/Description';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentOrg: any;
}

function ExportUsersCSV({ currentOrg }: Props) {
  const headersForCSV = [
    { label: 'ID', key: 'first_name' },
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Email', key: 'email' },
    { label: 'Role', key: 'role' },
  ];
  const csvReport = {
    filename: 'UserList.csv',
    headers: headersForCSV,
    data: currentOrg.member_list,
  };

  return (
    <CSVLink {...csvReport}>
      <Button
        sx={{ color: 'rgb(100, 100, 100)' }}
        size="large"
        startIcon={<DescriptionIcon className="start-icons" />}
      >
        Export
      </Button>
    </CSVLink>
  );
}

export default ExportUsersCSV;
