/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Divider } from '@mui/material';
import Cookies from 'js-cookie';
import { Box } from '@mui/system';
import { getServices } from '../../config/config';
import { ButtonFilled } from '../../components/Button/Button';
import LogoWhite from '../../images/Logo white.png';
import './Register.scss';
import FormField from '../../components/FormField/FormField';
import Checkbox from '../../components/Checkbox/Checkbox';
import {
  emailIsValid,
  getAuthTokensFromBrowser,
  getData,
  isNumeric,
  postData,
} from '../../utils/utils';
import Dropdown from '../../components/Dropdown/Dropdown';
import { DataContext } from '../../context/DataContext';
import PlacesAutocomplete from '../../components/AddressField/AddressField';
import useAxios from '../../hooks/useAxios';
import { Region, XeroConnectPayload } from '../../interfaces';

const defaultErrors = {
  firstnameError: '',
  lastnameError: '',
  phoneError: '',
  emailError: '',
  passwordError: '',
  rePasswordError: '',
  manualInviteKeyError: '',
  orgNameError: '',
  orgFloorSpaceError: '',
  orgTurnoverError: '',
  orgNoOfStaffError: '',
  orgAddressError: '',
  regionError: '',
  identifierTypeError: '',
  identifierError: '',
};
const setXeroData = async (
  code: string,
  setXeroResponse: React.Dispatch<React.SetStateAction<XeroConnectPayload | null | undefined>>,
) => {
  const body = { xero_code: code };
  const xeroData = await postData('/integrations/xero/signup', body, false);
  setXeroResponse(xeroData.data);
};

interface valuesType {
  id: number;
  title: string;
  shortForm: string;
}

function Register() {
  const [orgDetails, setOrgDetails] = useState({
    orgName: '',
    region: '',
    address: {},
    identifier: '',
    identifier_type: '',
    orgFloorSpace: '',
    orgTurnover: '',
    orgNoOfStaff: '',
  });
  const { setLoading, setMessagebar } = useContext(DataContext);
  const apiPrefix = getServices().serviceURL;
  const navigate = useNavigate();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const inviteKeyFromURL = searchParams.get('inviteKey');
  const xeroCode = searchParams.get('code');

  const [xeroResponse, setXeroResponse] = useState<XeroConnectPayload | null>();

  const [manualInviteKey, setManualInviteKey] = useState('');
  const [currentStep, setCurrentStep] = useState('1');
  const [agreement, setAgreement] = useState(false);
  const { data: regions } = useAxios('/regions');
  const [regionsLevel0, setRegionsLevel0] = useState<valuesType[]>([]);
  const [regionsLevel1, setRegionsLevel1] = useState<valuesType[]>([]);
  const [regionsLevel2, setRegionsLevel2] = useState<valuesType[]>([]);

  const [userDetails, setUserDetails] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    password: '',
    rePassword: '',
    twoFA: false,
  });
  const accessToken = Cookies.get('access_token');
  const accountId = Cookies.get('account_id');

  const [errors, setErrors] = useState(defaultErrors);

  useEffect(() => {
    const getRegions = async () => {
      // Come back to refactor
      // eslint-disable-next-line no-shadow
      const regions = await getData('/regions');
      const regions0 = regions.data.filter((region: Region) => region.level === 0);
      const removedUnderscoreInRegion = regions0.map((region: Region) => ({
        ...region,
        shortForm: region.short_form,
      }));
      // // console.log(removedUnderscoreInRegion);
      setRegionsLevel0(removedUnderscoreInRegion);
    };
    getRegions();

    const {
      organisation,
      // eslint-disable-next-line no-shadow
      account_id: accountId,
      error,
    } = getAuthTokensFromBrowser();

    if (!organisation && accountId !== '' && (!error || error.type === 'no_license')) {
      // console.log("moving to organisation fill form", organisation, accountId);
      setCurrentStep('1.5');
    }
  }, [apiPrefix, accessToken, accountId, setLoading]);

  // Use Effect for Xero Token (Pull Xero Data if Exists Token in Param)
  useEffect(() => {
    if (xeroCode) {
      setXeroData(xeroCode, setXeroResponse);
    }
  }, [xeroCode]);

  // If we get Xero data we need to populate account fields

  useEffect(() => {
    if (xeroResponse !== null && xeroResponse !== undefined) {
      // console.log(xeroResponse);
      const xeroUser = (xeroResponse as XeroConnectPayload).user;
      if (xeroUser && Object.keys(xeroUser).length > 0) {
        setUserDetails({
          firstname: xeroUser.given_name,
          lastname: xeroUser.family_name,
          phone: '',
          email: xeroUser.email,
          password: '',
          rePassword: '',
          twoFA: false,
        });
      } else {
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: 'Unable to retrieve data from Xero, please try again.',
          severity: 'error',
        }));
      }
    }
  }, [xeroResponse]);

  const validation = () => {
    setErrors(defaultErrors);
    if (currentStep === '1' && userDetails.firstname.length === 0) {
      setErrors((prev) => ({ ...prev, firstnameError: 'Invalid first name' }));
      return false;
    }
    if (currentStep === '1' && userDetails.lastname.length === 0) {
      setErrors((prev) => ({ ...prev, lastnameError: 'Invalid last name' }));
      return false;
    }
    if (currentStep === '1' && userDetails.phone.length === 0) {
      setErrors((prev) => ({ ...prev, phoneError: 'Invalid phone number' }));
      return false;
    }
    if (currentStep === '1' && !isNumeric(userDetails.phone)) {
      setErrors((prev) => ({
        ...prev,
        phoneError: 'Phone number must be numeric',
      }));
      return false;
    }
    if (currentStep === '1' && userDetails.email.length === 0) {
      setErrors((prev) => ({
        ...prev,
        emailError: 'Email field can not be empty',
      }));
      return false;
    }
    if (currentStep === '1' && !emailIsValid(userDetails.email)) {
      setErrors((prev) => ({ ...prev, emailError: 'Invalid email' }));
      return false;
    }
    if (currentStep === '1' && userDetails.password.length === 0) {
      setErrors((prev) => ({
        ...prev,
        passwordError: 'Password field can not be empty',
      }));
      return false;
    }
    if (
      currentStep === '1'
      && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        userDetails.password,
      )
    ) {
      setErrors((prev) => ({
        ...prev,
        passwordError:
          'Include minimum 8 characters, one uppercase letter, lowercase letter, number and a special character @$!%*?&',
      }));
      return false;
    }
    if (currentStep === '1' && userDetails.rePassword.length === 0) {
      // console.log(userDetails.password, ":: true");
      setErrors((prev) => ({
        ...prev,
        rePasswordError: 'Confirm Password field can not be empty',
      }));
      return false;
    }
    if (currentStep === '1' && userDetails.password !== userDetails.rePassword) {
      setErrors((prev) => ({
        ...prev,
        passwordError: 'Passwords do not match',
      }));
      setErrors((prev) => ({
        ...prev,
        rePasswordError: 'Passwords do not match',
      }));
      return false;
    }
    if (currentStep === '1.6' && manualInviteKey.length === 0) {
      setErrors((prev) => ({
        ...prev,
        manualInviteKeyError: 'Invite key is not valid',
      }));
      return false;
    }
    if (currentStep === '2' && orgDetails.orgName.length === 0) {
      setErrors((prev) => ({
        ...prev,
        orgNameError: 'Invalid organisation name',
      }));
      return false;
    }
    if (currentStep === '2' && orgDetails.orgFloorSpace.length === 0) {
      setErrors((prev) => ({
        ...prev,
        orgFloorSpaceError: 'Invalid floor space',
      }));
      return false;
    }
    if (currentStep === '2' && !isNumeric(orgDetails.orgFloorSpace)) {
      setErrors((prev) => ({
        ...prev,
        orgFloorSpaceError: 'Floor space must be numeric',
      }));
      return false;
    }
    if (currentStep === '2' && orgDetails.orgTurnover.length === 0) {
      setErrors((prev) => ({ ...prev, orgTurnoverError: 'Invalid turnover' }));
      return false;
    }
    if (currentStep === '2' && !isNumeric(orgDetails.orgTurnover)) {
      setErrors((prev) => ({
        ...prev,
        orgTurnoverError: 'Turnover must be numeric',
      }));
      return false;
    }
    if (currentStep === '2' && orgDetails.orgNoOfStaff.length === 0) {
      setErrors((prev) => ({
        ...prev,
        orgNoOfStaffError: 'Invalid number of staff',
      }));
      return false;
    }
    if (currentStep === '2' && !isNumeric(orgDetails.orgNoOfStaff)) {
      setErrors((prev) => ({
        ...prev,
        orgNoOfStaffError: 'Number of staff must be numeric',
      }));
      return false;
    }
    if (currentStep === '2' && Object.keys(orgDetails.address).length === 0) {
      setErrors((prev) => ({
        ...prev,
        orgAddressError: 'Please type address in correct format',
      }));
      return false;
    }
    if (currentStep === '2.1' && orgDetails.region.length === 0) {
      setErrors((prev) => ({
        ...prev,
        regionError: 'Region must be selected',
      }));
      return false;
    }
    if (currentStep === '2.1' && orgDetails.identifier_type.length === 0) {
      setErrors((prev) => ({
        ...prev,
        identifierTypeError: 'Identifier type must be selected',
      }));
      return false;
    }
    if (currentStep === '2.1' && orgDetails.identifier.length === 0) {
      setErrors((prev) => ({
        ...prev,
        identifierError: 'Identifier must be selected',
      }));
      return false;
    }

    return true;
  };

  const handleNextStep1 = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    const fetchData = async () => {
      if (validation()) {
        setLoading(true);
        // CREATE USER
        const userBody = {
          email: userDetails.email,
          password: userDetails.password,
          first_name: userDetails.firstname,
          last_name: userDetails.lastname,
          recovery_phone_no: userDetails.phone,
        };
        try {
          const createUserResponse = await axios.post(`${apiPrefix}/accounts`, userBody);
          Cookies.set('account_id', createUserResponse.data.account_id, {
            sameSite: 'strict',
          });
          Cookies.set('access_token', createUserResponse.data.access_token, {
            sameSite: 'strict',
          });
          Cookies.set('refresh_token', createUserResponse.data.refresh_token, {
            sameSite: 'strict',
          });

          setMessagebar((prev: any) => ({
            ...prev,
            show: true,
            text: 'User successfully created',
            severity: 'success',
          }));
          if (!inviteKeyFromURL) {
            setCurrentStep('1.5');
          } else {
            setCurrentStep('3');
          }
          setLoading(false);
        } catch (error: any) {
          setLoading(false);
          setMessagebar((prev: any) => ({
            ...prev,
            show: true,
            text: error.response.data,
            severity: 'error',
          }));
        }
      }
    };
    fetchData();
  };

  const handleNextStep1Dot6 = () => {
    if (validation()) {
      setCurrentStep('3');
    }
  };

  const handleNextStep2 = () => {
    if (validation()) {
      setCurrentStep('2.1');
    }
  };
  const handleNextStep2Dot1 = () => {
    if (validation()) {
      setCurrentStep('3');
    }
  };

  const handleDropdownLevel0 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regions1 = regions.filter(
      (region: any) => region.level === 1 && region.parent === e.target.value,
    );
    const removedUnderscoreInRegion = regions1.map((region: any) => ({
      ...region,
      shortForm: region.short_form,
    }));
    setRegionsLevel1(removedUnderscoreInRegion);
    setOrgDetails((prevState) => ({ ...prevState, region: e.target.value }));
  };
  const handleDropdownLevel1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regions2 = regions.filter(
      (region: any) => region.level === 2 && region.parent === e.target.value,
    );
    const removedUnderscoreInRegion = regions2.map((region: any) => ({
      ...region,
      shortForm: region.short_form,
    }));
    setRegionsLevel2(removedUnderscoreInRegion);
    setOrgDetails((prevState) => ({ ...prevState, region: e.target.value }));
  };
  const handleDropdownLevel2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrgDetails((prevState) => ({ ...prevState, region: e.target.value }));
  };

  const handleDropdownIdentifierType = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("I am here", e.target.value);
    if (e.target.value === '1') {
      // console.log("Business identifier", e.target.value);
      setOrgDetails((prevState) => ({
        ...prevState,
        identifier_type: 'Australian Business Number',
      }));
    } else if (e.target.value === '2') {
      setOrgDetails((prevState) => ({
        ...prevState,
        identifier_type: 'Australian Company Number',
      }));
    }
  };

  const handleNextStep3 = () => {
    if (!agreement) {
      setMessagebar((prev: any) => ({
        ...prev,
        show: true,
        text: 'Terms and conditions should be read and agreed',
        severity: 'error',
      }));
      return;
    }

    const req = async () => {
      setLoading(true);

      try {
        if (!inviteKeyFromURL && manualInviteKey.length === 0) {
          // CREATE ORGANISATION
          const orgBody = {
            name: orgDetails.orgName,
            region: orgDetails.region,
            address: orgDetails.address,
            identifier: orgDetails.identifier,
            identifier_type: orgDetails.identifier_type,
            floor_space: Number(orgDetails.orgFloorSpace),
            turnover: Number(orgDetails.orgTurnover),
            number_of_staff: Number(orgDetails.orgNoOfStaff),
          };
          await axios.post(`${apiPrefix}/organisations?account_id=${accountId}`, orgBody, {});
          setLoading(false);
          setCurrentStep('4');
        } else {
          // JOIN organisation using key -
          // Either using key from URL OR key which is entered manually
          const body = {
            invite_key: inviteKeyFromURL || manualInviteKey,
            account_id: accountId,
          };

          await axios.post(`${apiPrefix}/organisations/joinorganisationwithkey`, body, {});

          // //UPDATE USER WITH 2FA VALUE
          // const bodyToUpdate = {
          //   two_factor_enabled: userDetails.twoFA,
          // };
          // await axios.patch(`${api_prefix}/accounts/${account_id_from_cookies}`, bodyToUpdate, {

          // });

          setLoading(false);
          setCurrentStep('4');
        }
      } catch (error: any) {
        setLoading(false);
        setMessagebar((prev: any) => ({
          ...prev,
          show: true,
          text: 'Unknown error occured. Please start the process again.',
          severity: 'error',
        }));
      }
    };
    req();
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: '1',
          background: 'linear-gradient(360deg, #61b9c3 0%, rgba(67, 236, 208, 1) 100%)',
          minHeight: '90%',
          padding: '40px 0',
        }}
      >
        <div className="register-card">
          <div className="card-left">
            {currentStep === '1' && (
              <form onSubmit={(e: any) => handleNextStep1(e)}>
                <div className="title">Register</div>
                <FormField
                  error={errors.firstnameError}
                  label="First Name"
                  type="text"
                  handleField={(e) => setUserDetails((prevState) => ({
                    ...prevState,
                    firstname: e.target.value,
                  }))}
                  value={userDetails.firstname}
                />
                <FormField
                  error={errors.lastnameError}
                  label="Last Name"
                  type="text"
                  handleField={(e) => setUserDetails((prevState) => ({
                    ...prevState,
                    lastname: e.target.value,
                  }))}
                  value={userDetails.lastname}
                />
                <FormField
                  error={errors.phoneError}
                  label="Phone"
                  type="text"
                  handleField={(e) => setUserDetails((prevState) => ({
                    ...prevState,
                    phone: e.target.value,
                  }))}
                />
                <FormField
                  error={errors.emailError}
                  label="Email"
                  type="email"
                  handleField={(e) => setUserDetails((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))}
                  value={userDetails.email}
                />
                <FormField
                  error={errors.passwordError}
                  label="Password"
                  type="password"
                  handleField={(e) => setUserDetails((prevState) => ({
                    ...prevState,
                    password: e.target.value,
                  }))}
                />
                <FormField
                  error={errors.rePasswordError}
                  label="Confirm Password"
                  type="password"
                  handleField={(e) => setUserDetails((prevState) => ({
                    ...prevState,
                    rePassword: e.target.value,
                  }))}
                />
                <div className="nextBtn">
                  <ButtonFilled handleSubmit={(e: any) => handleNextStep1(e)}>Next</ButtonFilled>
                </div>
              </form>
            )}

            {currentStep === '1.5' && (
              <>
                <div className="title">Organisation details</div>

                <div className="nextBtn" style={{ display: 'flex', gap: 10 }}>
                  <ButtonFilled handleSubmit={() => setCurrentStep('1.6')}>
                    Join existing
                  </ButtonFilled>
                  <ButtonFilled handleSubmit={() => setCurrentStep('2')}>Create new</ButtonFilled>
                </div>
              </>
            )}

            {currentStep === '1.6' && (
              <>
                <div className="title">Organisation details</div>
                <FormField
                  error={errors.manualInviteKeyError}
                  label="Invite key"
                  type="text"
                  handleField={(e) => setManualInviteKey(e.target.value)}
                />
                <div className="nextBtn" style={{ display: 'flex', gap: 10 }}>
                  <ButtonFilled handleSubmit={() => setCurrentStep('1.5')}>Back</ButtonFilled>
                  <ButtonFilled handleSubmit={() => handleNextStep1Dot6()}>Next</ButtonFilled>
                </div>
              </>
            )}

            {currentStep === '2' && (
              <>
                <div className="title">Organisational details</div>
                <FormField
                  error={errors.orgNameError}
                  label="Organisation name"
                  type="text"
                  handleField={(e) => setOrgDetails((prevState) => ({
                    ...prevState,
                    orgName: e.target.value,
                  }))}
                />
                <FormField
                  error={errors.orgFloorSpaceError}
                  label="Floor space (m2)"
                  type="text"
                  handleField={(e) => setOrgDetails((prevState) => ({
                    ...prevState,
                    orgFloorSpace: e.target.value,
                  }))}
                />
                <FormField
                  error={errors.orgTurnoverError}
                  label="Turnover"
                  type="text"
                  handleField={(e) => setOrgDetails((prevState) => ({
                    ...prevState,
                    orgTurnover: e.target.value,
                  }))}
                />
                <FormField
                  error={errors.orgNoOfStaffError}
                  label="Number of staff"
                  type="text"
                  handleField={(e) => setOrgDetails((prevState) => ({
                    ...prevState,
                    orgNoOfStaff: e.target.value,
                  }))}
                />

                <PlacesAutocomplete
                  error={errors.orgAddressError}
                  handleField={(description: any) => setOrgDetails((prevState) => ({
                    ...prevState,
                    address: description,
                  }))}
                />

                <div className="nextBtn" style={{ display: 'flex', gap: 10 }}>
                  <ButtonFilled handleSubmit={() => setCurrentStep('1')}>Back</ButtonFilled>
                  <ButtonFilled handleSubmit={() => handleNextStep2()}>Next</ButtonFilled>
                </div>
              </>
            )}
            {currentStep === '2.1' && (
              <>
                <Dropdown
                  label="Region"
                  values={regionsLevel0}
                  handleField={(e: any) => handleDropdownLevel0(e)}
                />
                {regionsLevel1.length !== 0 && (
                  <Dropdown
                    label="Sub-Region"
                    values={regionsLevel1}
                    handleField={(e: any) => handleDropdownLevel1(e)}
                  />
                )}
                {regionsLevel2.length !== 0 && (
                  <Dropdown
                    label="Sub-sub-Region"
                    values={regionsLevel2}
                    handleField={(e: any) => handleDropdownLevel2(e)}
                  />
                )}
                <div style={{ textAlign: 'right' }}>
                  {errors.regionError && <div className="error">{errors.regionError}</div>}
                </div>

                <br />
                <Divider />

                <Dropdown
                  label="Identifier Type"
                  values={[
                    {
                      id: 1,
                      shortForm: 'ABN',
                      title: 'Australian Business Number',
                    },
                    {
                      id: 2,
                      shortForm: 'ACN',
                      title: 'Australian Company Number',
                    },
                  ]}
                  handleField={(e: any) => handleDropdownIdentifierType(e)}
                />
                <div style={{ textAlign: 'right' }}>
                  {errors.identifierTypeError && (
                    <div className="error">{errors.identifierTypeError}</div>
                  )}
                </div>

                <FormField
                  error={errors.identifierError}
                  label="Identifier"
                  type="text"
                  handleField={(e) => setOrgDetails((prevState) => ({
                    ...prevState,
                    identifier: e.target.value,
                  }))}
                />

                <div className="nextBtn" style={{ display: 'flex', gap: 10 }}>
                  <ButtonFilled handleSubmit={() => setCurrentStep('2')}>Back</ButtonFilled>
                  <ButtonFilled handleSubmit={() => handleNextStep2Dot1()}>Next</ButtonFilled>
                </div>
              </>
            )}

            {currentStep === '3' && (
              <>
                <div className="title">Terms and conditions</div>
                <div className="checkbox">
                  {/* <Checkbox
                    agree={userDetails.twoFA}
                    text="Activate 2-factor authentication (optional)"
                    handleField={(e) =>
                      setUserDetails((prevState) => ({
                        ...prevState,
                        twoFA: !userDetails.twoFA,
                      }))
                    }
                  /> */}
                </div>
                <div className="checkbox">
                  <Checkbox
                    agree={agreement}
                    text="I agree to the terms and conditions of Evalue8 Sustainability Pty. Ltd."
                    handleField={() => setAgreement((prevState) => !prevState)}
                  />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://evalue8.net/terms-and-conditions-privacy-policy/"
                  >
                    <div className="viewClickTerms">Click here to view T&C</div>
                  </a>
                </div>

                <div className="nextBtn" style={{ display: 'flex', gap: 10 }}>
                  <ButtonFilled handleSubmit={() => setCurrentStep('2')}>Back</ButtonFilled>
                  <ButtonFilled handleSubmit={() => handleNextStep3()}>Register</ButtonFilled>
                </div>
              </>
            )}

            {currentStep === '4' && (
              <>
                <div className="title">Thank you</div>
                <Box sx={{ color: '#61b9c3' }}>Welcome to Evalue8 Sustainability</Box>
                <br />
                <div className="nextBtn" style={{ display: 'flex', gap: 10 }}>
                  <ButtonFilled handleSubmit={() => navigate('/')}>Click to login</ButtonFilled>
                </div>
              </>
            )}
          </div>
          <div id="card-right" className="grid grid-cols-1 grid-rows-[200px_1fr_200px] gap-0">
            <div className="flex justify-center items-center">
              <img src={LogoWhite} alt="" className="w-65 h-50" />
            </div>

            <div id="texts" className="flex flex-col justify-between text-xl">
              <div id="title" className="text-lg">
                <div>Welcome to Evalue8! </div>
                <div>Create your organisation to get started.</div>
              </div>
              <div className="text-sm" id="description">
                Tell us about your organisation so that we can provide you with services that will
                suit your needs best.
                {' '}
              </div>
            </div>
            <div id="buttons" className="flex justify-center items-center flex-col text-slate-900">
              <Link to="/">
                <button
                  type="button"
                  className="call_btn"
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  Existing user? Login
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Box>
      <Box>{/* <Footer /> */}</Box>
    </Box>
  );
}

export default Register;
