import axios from 'axios';
import { useEffect, useState } from 'react';
import { getServices } from '../config/config';
import { generateBearerHeader } from '../utils/utils';

// eslint-disable-next-line no-shadow
export enum RESTMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
}
interface IServicePayload {
  // service endpoint
  endpoint: string;
  // REST request method type
  method: RESTMethod;
  // pass whether it is secure or not
  isSecure?: boolean;
  // endpoint params
  urlParameters?: Record<string, string>;
  // body parameters, converted to JSON when sent
  body?: any;
  // determined if we want to run on start
  skipFirstRun?: boolean;
}
export interface IUseService extends IServicePayload {
  // if these values change, we will make the request again
  dependencyArray?: any[];
}

const makeRequest = async <T>({
  endpoint,
  method,
  urlParameters,
  body,
  isSecure,
}: IServicePayload): Promise<T> => {
  const apiPrefix = getServices().serviceURL;
  const encodedParameters = urlParameters
    ? `?${new URLSearchParams(urlParameters).toString()}`
    : '';
  const completeURL = `${apiPrefix}${endpoint}${encodedParameters}`;
  const axiosParams = isSecure
    ? [completeURL, generateBearerHeader()]
    : [completeURL];

  if (method === RESTMethod.GET) {
    const getResponse = await axios.get.apply(this, axiosParams as any);
    return (getResponse as any).data as T;
  }
  if (method === RESTMethod.POST || method === RESTMethod.PATCH) {
    const axiosMethod = method === RESTMethod.POST ? axios.post : axios.patch;
    const postResponse = await axiosMethod.apply(this, [
      axiosParams[0],
      body,
      ...axiosParams.splice(1),
    ] as any);
    return (postResponse as any).data as T;
  }
  throw Error('Unable to make request');
};

export const useService = <T>({
  endpoint,
  method,
  urlParameters,
  body,
  dependencyArray = [],
  isSecure = true,
  skipFirstRun = false,
}: IUseService): T | null => {
  const [value, setValue] = useState<T | null>(null);
  const [shouldSkipFirstRun, setShouldSkipFirstRun] = useState(skipFirstRun);

  useEffect(() => {
    if (shouldSkipFirstRun) {
      setShouldSkipFirstRun(false);
    } else {
      makeRequest<T>({
        endpoint,
        method,
        urlParameters,
        body,
        isSecure,
      })
        .then((data) => {
          setValue(data);
        })
        .catch((e) => {
          if (Object.keys(e.response).includes('data')) {
            setValue(e.response.data);
          }
        });
    }
  }, [...dependencyArray]);

  return value;
};
