// Values for first tab links
export const firstTab = [
  {
    name: 'Benchmarking',
    value: 'benchmarks',
    active: false,
  },
  {
    name: 'Reports',
    value: 'emissions',
    active: true,
  },
];

// Values for second tab links
export const secondTab = [
  {
    name: 'Three years',
    value: 'threeYears',
    active: false,
  },
  {
    name: 'Yearly',
    value: 'yearly',
    active: false,
  },
  {
    name: 'Monthly',
    value: 'monthly',
    active: false,
  },
];

// Options FOR LINE CHART
export const lineChartOptions = {
  // datasetFill: true,
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Line Chart',
    },
    decimation: {
      enabled: true,
      threshold: 20,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {
        // maxTicksLimit: 20,
      },
    },
  },
};

// Options for Bar Chart
export const barChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
  maintainAspectRatio: false,
};
