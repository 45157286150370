export default {
  organisation: '60aa2f87-1bc9-48dc-ad1e-db0bbe64b3bb',
  report_types: ['emissions'],
  start_date: '2022-02-16T10:28:07+11:00',
  end_date: '2022-03-16T10:28:07+11:00',
  chart_data: {
    chart_data_time_series: {
      labels: [
        'Feb 15 2022',
        'Feb 16 2022',
        'Feb 17 2022',
        'Feb 18 2022',
        'Feb 19 2022',
        'Feb 20 2022',
        'Feb 21 2022',
        'Feb 22 2022',
        'Feb 23 2022',
        'Feb 24 2022',
        'Feb 25 2022',
        'Feb 26 2022',
        'Feb 27 2022',
        'Feb 28 2022',
        'Mar 01 2022',
        'Mar 02 2022',
        'Mar 03 2022',
        'Mar 04 2022',
        'Mar 05 2022',
        'Mar 06 2022',
        'Mar 07 2022',
        'Mar 08 2022',
        'Mar 09 2022',
        'Mar 10 2022',
        'Mar 11 2022',
        'Mar 12 2022',
        'Mar 13 2022',
        'Mar 14 2022',
        'Mar 15 2022',
        'Mar 16 2022',
      ],
      datasets: [
        {
          label: 'CO2 Emissions (Kg)',
          data: [
            94.59316016059874, 98.95032319500315, 103.48674336175719,
            99.33926202988317, 109.14211449316122, 95.51956362477085,
            86.14535351956808, 88.282327222221, 90.72687909094759,
            90.92748462636821, 95.34819545250838, 97.24244074451562,
            99.97523273932092, 97.52915275326296, 94.59316016059874,
            98.95032319500315, 100.48674336175719, 99.33926202988317,
            109.14211449316122, 95.51956362477085, 86.14535351956808,
            88.282327222221, 90.72687909094759, 90.92748462636821,
            95.34819545250838, 97.24244074451562, 99.97523273932092,
            90.52915275326296, 87.52915275326296, 55.52915275326296,
          ],
        },
      ],
    },
    chart_data_by_activity: {
      labels: ['Water', 'IT Equipment', 'Electricity', 'Ground Transport'],
      datasets: [
        {
          label: 'CO2 Emissions (Kg)',
          data: [
            294.90876136875795, 452.5803231471311, 394.1991364113152,
            130.03349779412872,
          ],
        },
      ],
    },
  },
};
