/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  useState, useEffect, Fragment, useContext,
} from 'react';
import { Transition, Menu } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { classNames, getData } from '../../../utils/utils';
import MarkdownView from './MarkdownView';

// eslint-disable-next-line import/no-unresolved
import { GuideType } from './Guide';
import { AuthContext } from '../../../context/AuthContext';

function Guide() {
  const [guides, setGuides] = useState<GuideType[]>([]);
  const [currentGuide, setCurrentGuide] = useState<GuideType>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { authenticationObject } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    getData('/guides').then(({ data }) => {
      // eslint-disable-next-line func-names
      const sortedData = data.sort((
        a: { dropboxFile: { name: string } },
        b: { dropboxFile: { name: string } },
      ) => {
        const textA = a.dropboxFile.name.toUpperCase();
        const textB = b.dropboxFile.name.toUpperCase();
        // eslint-disable-next-line no-nested-ternary
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setGuides(sortedData);
    });
  }, []);

  // when search params change
  useEffect(() => {
    // check if auth
    if (
      authenticationObject
      && authenticationObject.access_token
      && authenticationObject.access_token !== ''
    ) {
      if (guides.length !== 0) {
        const selectedGuideId = searchParams.get('id') ?? '';
        if (selectedGuideId) {
          const selectedGuides = guides.filter(
            ({ id }) => selectedGuideId === id,
          );
          if (selectedGuides.length > 0) {
            setCurrentGuide(selectedGuides[0]);
            return;
          }
        }
        setCurrentGuide(guides[0]);
      }
    } else if (searchParams.get('id')) {
      // handle no auth
      navigate(`/?redirect=guide?id=${searchParams.get('id')}`);
    } else {
      navigate('/');
    }
  }, [guides, searchParams]);

  const getGuideIndex = (id: string) => guides.filter((guide) => guide.id === id)[0];

  const formatMdName = (name: string) => {
    if (name.length === 0) return name;
    return name.substring(2, name.length - 3);
  };

  return (
    <div className="min-w-0 w-full h-full flex-1 border-tl border-gray-900 xl:flex">
      <section
        aria-labelledby="message-heading"
        className="flex h-full min-w-0 flex-1 flex-col overflow-hidden xl:order-last"
      >
        {/* Top section */}
        <div className="min-h-0 flex-1 overflow-y-auto">
          <div className="bg-white pt-4 pb-5 shadow sticky inset-x-0 top-0 left-0 bg-gray-400 py-5">
            <div className="px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-8">
              <div className="sm:w-0 sm:flex-1">
                <h1
                  id="message-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  {formatMdName(currentGuide?.dropboxFile.name ?? '')}
                </h1>
              </div>

              <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
                <span className="inline-flex items-center rounded-full bg-cyan-100 px-3 py-0.5 text-sm font-medium text-cyan-800">
                  User Guide
                </span>
                <Menu as="div" className="relative ml-3 inline-block text-left">
                  <div>
                    <Menu.Button className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-600">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <>
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'flex justify-between px-4 py-2 text-sm',
                                )}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    window.location.href,
                                  );
                                }}
                              >
                                <span>Copy link</span>
                              </a>
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'flex justify-between px-4 py-2 text-sm',
                                )}
                              >
                                <span>Need more help?</span>
                              </a>
                            </>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          {/* Thread section */}
          <div className="space-y-2 py-4 sm:space-y-4 sm:px-6 lg:px-8">
            <div
              key={currentGuide?.id ?? ''}
              className="bg-white px-4 py-6 min-w-0 w-full mb-[5px] flex-1 border-t border-gray-200 xl:flexshadow sm:rounded-lg sm:px-6"
            >
              <div className="mt-4 space-y-6 text-sm text-gray-800">
                <MarkdownView data={currentGuide?.fileData ?? ''} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Message list */}
      <aside className="hidden xl:order-first xl:block xl:flex-shrink-0">
        <div className="relative flex h-full w-96 flex-col border-r border-gray-200 bg-gray-100">
          <div className="flex-shrink-0">
            <div className="flex h-16 flex-col justify-center bg-white px-6">
              <div className="flex items-baseline space-x-3">
                <h2 className="text-lg font-medium text-gray-900">
                  User Guides
                </h2>
              </div>
            </div>
            <div className="border-t border-b border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500">
              Sorted by name
            </div>
          </div>
          <nav
            aria-label="Message list"
            className="min-h-0 flex-1 overflow-y-auto"
          >
            <ul className="divide-y divide-gray-200 border-b border-gray-200">
              {guides.map((guide) => (
                <li
                  key={guide.id}
                  className={classNames(
                    'relative py-5 px-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600',
                    guide.id === currentGuide?.id
                      ? ' bg-[#eaf5f3]'
                      : ' bg-white',
                  )}
                  onClick={() => {
                    setCurrentGuide(getGuideIndex(guide.id));
                    setSearchParams({ id: guide.id });
                  }}
                >
                  <div className="flex justify-between space-x-3">
                    <div className="min-w-0 flex-1">
                      <div className="block focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="truncate text-sm font-medium text-gray-900">
                          {formatMdName(guide.dropboxFile.name)}
                        </p>
                        <time
                          dateTime={guide.dropboxFile.client_modified}
                          className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
                        >
                          {guide.dropboxFile.client_modified}
                        </time>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}

export default Guide;
