import { useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert, Slide } from '@mui/material';
import { DataContext } from '../../context/DataContext';

function Messagebar() {
  const { messagebar, setMessagebar } = useContext(DataContext);
  return (
    <Snackbar
      TransitionComponent={Slide}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={messagebar.show}
      onClose={() => setMessagebar((prev: any) => ({ ...prev, show: false }))}
      key="top center"
    >
      <Alert severity={messagebar.severity} sx={{ width: '100%' }}>
        {messagebar.text}
      </Alert>
    </Snackbar>
  );
}

export default Messagebar;
