import React, { useState, useEffect } from 'react';
import './Footer.scss';

import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import {
  Avatar, ListItem, ListItemAvatar, ListItemText,
} from '@mui/material';
import { i18n } from '../../config/config';
import AusFlag from '../../images/flags/AustraliaFlag.jpg';
import languages from './data';
import DialogBox from '../DialogBox/DialogBox';

function Footer() {
  const { t } = useTranslation();
  const [country, setCountry] = useState('Australia');
  const [flag, setFlag] = useState(AusFlag);
  const [showLanguageOptions, setShowLanguageOptions] = useState(false);

  const changeLanguage = (lang: string) => {
    const filteredLang = languages.filter(
      (language) => language.langShort === lang,
    );

    setCountry(filteredLang[0].langLong);
    setFlag(filteredLang[0].src);

    // if (lang === 'en') {
    //   setCountry('Australia')
    //   setFlag(AusFlag)
    // }
    // if (lang === 'fr') {
    //   setCountry('France')
    //   setFlag(FranceFlag)
    // }
    // if (lang === 'es') {
    //   setCountry('Spain')
    //   setFlag(SpainFlag)
    // }
    // if (lang === 'de') {
    //   setCountry('Germany')
    //   setFlag(GermanyFlag)
    // }
    // if (lang === 'cn') {
    //   setCountry('China')
    //   setFlag(ChinaFlag)
    // }
  };
  const handleSetLanguage = (language: string) => {
    i18n.changeLanguage(language);
    changeLanguage(language);
    setShowLanguageOptions(false);
    Cookies.set('language', language, { sameSite: 'strict' });
  };

  useEffect(() => {
    const language = Cookies.get('language') || 'en';
    changeLanguage(language);
  }, []);

  return (
    <div className="flex justify-between p-2 custom-gradient text-white">
      <div />
      <div className="text-base">
        © Evalue8 Sustainability
        {' '}
        {new Date().getFullYear()}
        {' '}
        -
        {' '}
        {t('footer.footerText')}
      </div>
      <div
        className="flex space-x-1 items-center"
        onClick={() => setShowLanguageOptions(true)}
      >
        <span className="footer-text">{country}</span>
        <img
          className="cursor-pointer clip-circle w-12"
          alt={`${country} Flag`}
          src={flag}
          height="30px"
        />
      </div>

      <DialogBox show={showLanguageOptions} setShow={setShowLanguageOptions}>
        <div className="flex flex-col p-5">
          <div className="text-center text-xl text-gray-500 font-bold">
            Language / Langue / Sprache
          </div>

          <div className="flex flex-col items-center">
            <div>
              {languages.map((language) => (
                <ListItem
                  key={language.langShort}
                  onClick={() => handleSetLanguage(language.langShort)}
                  sx={{
                    cursor: 'pointer',
                    margin: '10px',
                    transition: 'transform .5s',
                    ':hover': {
                      transform: 'scale(1.2)',
                    },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt={language.altText} src={language.src} />
                  </ListItemAvatar>
                  <ListItemText
                    className="text-gray-500"
                    primary={language.langLong}
                  />
                </ListItem>
              ))}
            </div>
          </div>
        </div>
      </DialogBox>
    </div>
  );
}

export default Footer;
