import { useContext } from 'react';
import './Loading.scss';
import { Backdrop, CircularProgress } from '@mui/material';
import { DataContext } from '../../context/DataContext';

function Loading() {
  const { loading } = useContext(DataContext);
  return (
    <Backdrop
      sx={{ color: '#33FFF3', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      style={{ zIndex: 100 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loading;
