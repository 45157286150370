/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@mui/system';
import { FiUpload } from 'react-icons/fi';

export default function BasicUpload(props: any) {
  const onDrop = useCallback((acceptedFiles: any) => {
    // console.log("Accepted files", acceptedFiles);
    // console.log("Rejected files", rejectedFiles);
    props.handleUpload(acceptedFiles);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const files = acceptedFiles.map((file: any) => (
    <Box key={file.path} sx={{ marginTop: '30px', textAlign: 'center' }}>
      <p style={{ fontWeight: 'bold' }}>{file.path}</p>
    </Box>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Box
          sx={{
            border: '3px dashed #e0e0e0',
            padding: '50px 10px',
            textAlign: 'center',
            margin: '50px 0',
            '&:ondragover': { border: '3px dashed red' },
          }}
        >
          <FiUpload size={80} color="#bebebe" />
          <Box>
            <p>Drag 'n' drop your Excel file here</p>
            <p>(Only one file with .xlsx extension will be accepted)</p>
          </Box>
          <Box>{files}</Box>
        </Box>
      </div>
    </section>
  );
}
