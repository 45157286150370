import './TabLinks.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  data: {
    name: string;
    value: string;
    active: boolean;
  }[];
  func: (value: string) => void;
}

function TabLinks({ data, func }: Props) {
  const { t } = useTranslation();

  // For inner tabs
  const innerTabs = data.slice();
  innerTabs.shift();
  innerTabs.pop();

  // For last tab
  const lastTab = data.length - 1;

  return (
    <span className="tabs-wrapper">
      <span
        className={`first-tab ${data[0].active ? 'active-tab' : ''}`}
        onClick={() => (data[0].active ? '' : func(data[0].value))}
      >
        {/* {data[0].name} */}
        {t(data[0].name)}
      </span>
      {data.length > 2 && (
        <>
          {innerTabs.map((innerTab) => (
            <span
              key={innerTab.name}
              className={`mid-tab ${innerTab.active ? 'active-tab' : ''}`}
              onClick={() => (innerTab.active ? '' : func(innerTab.value))}
            >
              {innerTab.name}
            </span>
          ))}
        </>
      )}
      <span
        className={`last-tab ${data[lastTab].active ? 'active-tab' : ''}`}
        onClick={() => (data[lastTab].active ? '' : func(data[lastTab].value))}
      >
        {data[lastTab].name}
      </span>
    </span>
  );
}

export default TabLinks;
