/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box, Button, Grid, Slider,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
  format, addDays, subDays, getYear, getUnixTime,
} from 'date-fns';
import TabLinks from '../../../components/TabLinks/TabLinks';

interface PropsSecondLine {
  handleDownload: () => void;
  orgActivityStartDate: Date;
  calanderStartDate: Date;
  handleStartDate: (e: any) => void;
  calanderEndDate: Date;
  handleEndDate: (e: any) => void;
  sliderValue: number[];
  handleSliderChange: (event: Event, newValue: any) => void;
  handleSliderLabel: (value?: any) => string;
  tabValues: {
    name: string;
    value: string;
    active: boolean;
  }[];
  handleTab: (e: any) => void;
}
export default function SecondLine(props: PropsSecondLine) {
  const {
    handleDownload,
    orgActivityStartDate,
    calanderStartDate,
    handleStartDate,
    calanderEndDate,
    handleEndDate,
    sliderValue,
    handleSliderChange,
    handleSliderLabel,
    tabValues,
    handleTab,
  } = props;

  return (
    <Grid
      container
      sx={{
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <Grid item>
        <Box>
          <Button
            sx={{ color: 'rgb(100, 100, 100)' }}
            size="large"
            startIcon={<FileDownloadIcon className="start-icons" />}
            onClick={() => handleDownload()}
          >
            Download report
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              sx={{ color: 'rgb(100, 100, 100)', cursor: 'default !important' }}
              size="large"
              startIcon={<CalendarTodayIcon className="start-icons" />}
            >
              Start date
            </Button>
            <span>
              <form autoComplete="off">
                <DatePicker
                  autoComplete="off"
                  minDate={orgActivityStartDate}
                  maxDate={subDays(new Date(), 1)}
                  selected={calanderStartDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: any) => handleStartDate(date)}
                  className="w-100 outline-0 cursor-pointer bg-transparent font-medium text-customFontColor"
                />
              </form>
            </span>
          </Box>

          <Box
            sx={{ display: { xs: 'flex', xl: 'auto' }, alignItems: 'center' }}
          >
            <Button
              sx={{ color: 'rgb(100, 100, 100)', cursor: 'default !important' }}
              size="large"
              startIcon={<CalendarTodayIcon className="start-icons" />}
            >
              End date
            </Button>
            <span>
              <form autoComplete="off">
                <DatePicker
                  // autoComplete="off"
                  minDate={addDays(calanderStartDate, 1)}
                  maxDate={new Date()}
                  selected={calanderEndDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: any) => handleEndDate(date)}
                  className="w-100 outline-0 cursor-pointer bg-transparent font-medium text-customFontColor"
                />
              </form>
            </span>
          </Box>

          <Box
            sx={{ display: { xs: 'none', xl: 'flex' }, alignItems: 'center' }}
          >
            <span className="font-normal text-customFontColor">
              {getYear(orgActivityStartDate)}
            </span>
            <Slider
              size="small"
              getAriaLabel={() => 'Date range'}
              value={sliderValue as number[]}
              onChange={handleSliderChange}
              valueLabelFormat={handleSliderLabel}
              valueLabelDisplay="auto"
              step={86400}
              min={getUnixTime(orgActivityStartDate)}
              max={getUnixTime(new Date(format(new Date(), 'yyyy-MM-dd')))}
              sx={{ width: '200px', margin: '0 20px' }}
            />
            <Box
              sx={{ marginRight: '30px' }}
              className="font-normal text-customFontColor"
            >
              {getYear(new Date())}
            </Box>
            <TabLinks data={tabValues} func={handleTab} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
